import type { FC } from 'react';

import { NoResults } from '@zen/DesignSystem';
import { dashboardRoutes } from '@zen/Routes';

const AccessDenied: FC = () => {
  return (
    <NoResults
      button={{
        linkTo: dashboardRoutes.dashboard.getUrl(),
        label: 'Go to dashboard'
      }}
      headline="You don't have access to this page"
      tagline="It looks like you're trying to access a page you don't have permission to view. Reach out to an admin to grant you access or return to the dashboard."
    />
  );
};

export default AccessDenied;
