import type { FC, Ref } from 'react';
import { useRef, useState } from 'react';

import useAccount from '@zen/utils/hooks/useAccount';
import useClickAway from '@zen/utils/hooks/useClickAway';
import useTracking from '@zen/utils/hooks/useTracking';

import type { Undefinable } from '../../utils/typescript';
import { ActivityFeedTrackingAction, ActivityFeedTrackingCategory } from '../types';
import SubscriptionsMenu from './SubscriptionsMenu';
import SubscriptionsToggler from './SubscriptionsToggler';
import type { Stakeholder, Subscriber, SubscriptionStakeholder } from './types';

interface Props {
  onSubscribe: (stakeholder: SubscriptionStakeholder) => void;
  onUnsubscribe: (stakeholder: SubscriptionStakeholder) => void;
  stakeholders: Stakeholder[];
  subscribers: Subscriber[];
}

const Subscriptions: FC<Props> = ({ onSubscribe, onUnsubscribe, stakeholders, subscribers }) => {
  const { userProfile } = useAccount();
  const { trackEvent } = useTracking();

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const ref: Ref<HTMLDivElement> = useRef(null);

  useClickAway(ref, () => setIsMenuVisible(false));

  let currentUser: Undefinable<SubscriptionStakeholder> = subscribers.find(
    ({ accountMember, contactDetails }) => accountMember?.id === userProfile.uuid || contactDetails?.id === userProfile.uuid
  );
  const isCurrentUserSubscribed: boolean = !!currentUser;

  if (!currentUser) {
    currentUser = stakeholders.find(
      ({ accountMember, contactDetails }) => accountMember?.id === userProfile.uuid || contactDetails?.id === userProfile.uuid
    );
  }

  const handleToggle = (): void => {
    setIsMenuVisible(!isMenuVisible);

    trackEvent({
      category: ActivityFeedTrackingCategory,
      action: ActivityFeedTrackingAction.SUBSCRIPTIONS_TOGGLER_CLICKED,
      label: isMenuVisible ? 'Hide' : 'Show'
    });
  };

  return (
    <div ref={ref} className="relative">
      <SubscriptionsToggler isSubscriptionsMenuVisible={isMenuVisible} onClick={handleToggle} />
      {isMenuVisible && (
        <div className="absolute right-0 z-20 top-12">
          <SubscriptionsMenu
            currentUser={currentUser}
            isCurrentUserSubscribed={isCurrentUserSubscribed}
            onSubscribe={onSubscribe}
            onUnsubscribe={onUnsubscribe}
            stakeholders={stakeholders}
            subscribers={subscribers}
          />
        </div>
      )}
    </div>
  );
};

export default Subscriptions;
