import type { FC } from 'react';

import type { FormInstance } from '@zen/Components';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import FormField from '@zen/Components/Form/FormField';
import type { Option } from '@zen/DesignSystem';
import type { Nullable, Optional } from '@zen/utils/typescript';

import ChargeBasisSelect from '../ChargeBasisSelect';
import type { CargoModeEnum, ChargeBasis, ChargeBasisGroup, ModeOfTransport } from '../types';

interface Props extends FormFieldProps {
  cargoType: CargoModeEnum;
  chargeBasisGroup: Optional<ChargeBasisGroup>;
  isDisabled?: boolean;
  modeOfTransport: ModeOfTransport;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  prefillWithAll?: boolean;
}

const FormChargeBasisSelect: FC<Props> = (props) => {
  const { cargoType, chargeBasisGroup, isDisabled, modeOfTransport, prefillWithAll = true, ...rest } = props;

  return (
    <FormField {...rest}>
      {(
        field: { error: boolean; name: string; options: Option<string>[]; value: ChargeBasis[] },
        form: FormInstance<unknown>
      ) => {
        const handleBlur = (): void => {
          form.setFieldTouched(field.name, true);
        };

        const handleChange = (value: Nullable<ChargeBasis[]>): void => {
          form.setFieldValue(field.name, value || null);
        };

        const fieldValue: string[] = field.value?.map((value: ChargeBasis) => value.id) || [];

        return (
          <ChargeBasisSelect
            {...field}
            cargoType={cargoType}
            chargeBasisGroup={chargeBasisGroup}
            hasError={field.error}
            isDisabled={isDisabled}
            modeOfTransport={modeOfTransport}
            onBlur={handleBlur}
            onChange={handleChange}
            prefillWithAll={prefillWithAll}
            value={fieldValue}
          />
        );
      }}
    </FormField>
  );
};

export default FormChargeBasisSelect;
