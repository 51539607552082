import type { FC } from 'react';
import { useState } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import { Button, Modal } from '@zen/DesignSystem';
import { UpdateScheduleType } from '@zen/graphql/types.generated';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { journeyRefetchQueryList } from '../forms/helpers';
import PlannedScheduleForm from '../forms/PlannedScheduleForm';
import type { PlannedScheduleValues } from '../forms/PlannedScheduleForm/types';
import { AirScheduleCarrier } from '../forms/PlannedScheduleForm/types';
import { useUpdateJourneyScheduleMutation } from './graphql';
import { prepareInitialValues, prepareLegs } from './helpers';

interface Props {
  arrivalMilestoneId: string;
  departureMilestoneId: string;
}

const EditStopsButton: FC<Props> = ({ arrivalMilestoneId, departureMilestoneId }) => {
  const { addError, addSuccess } = useNotification();
  const { carriageLegs, modeOfTransport, zencargoReference } = useCargoOverviewContext();
  const [updateSchedule] = useUpdateJourneyScheduleMutation();
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  const handleClose = (): void => setIsModalVisible(false);

  const handleSubmit = ({ airScheduleCarrierType, carrier, stops, trackingNumber }: PlannedScheduleValues) => {
    const isCourier: boolean = airScheduleCarrierType === AirScheduleCarrier.COURIER;

    return performMutation({
      mutationFn: () =>
        updateSchedule({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              carrier,
              isCourier,
              scheduledLegs: prepareLegs(stops, modeOfTransport),
              trackingNumber: isCourier ? trackingNumber : null,
              updateType: UpdateScheduleType.UPDATE_PLANNED_SCHEDULE,
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Schedule has been updated.');
        setIsModalVisible(false);
      }
    });
  };

  return (
    <>
      <Button iconLeft="zicon-edit" onClick={() => setIsModalVisible(true)} size="compact" variant="secondary">
        Edit stops
      </Button>
      <Modal
        closeOnClickAway={false}
        isOpen={isModalVisible}
        modalOverflowY="scroll"
        onClose={handleClose}
        title="Enter booked schedule"
        width="wider"
      >
        <PlannedScheduleForm
          arrivalMilestoneId={arrivalMilestoneId}
          departureMilestoneId={departureMilestoneId}
          initialValues={prepareInitialValues(carriageLegs, modeOfTransport)}
          isAirScheduleDisabled={true}
          modeOfTransport={modeOfTransport}
          onCancel={handleClose}
          onSubmit={handleSubmit}
        />
      </Modal>
    </>
  );
};

export default EditStopsButton;
