import type { FC } from 'react';

import FilterComponent from '@zen/Components/Filters';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import { buildFilters } from './helpers';
import type { AccountMembersFilterItem, AccountMembersFilters } from './types';

const Filters: FC = () => {
  const { filters, setFilters } = useUrlFilters<AccountMembersFilters>();

  const filterItems: AccountMembersFilterItem[] = buildFilters();

  return <FilterComponent items={filterItems} onChange={setFilters} value={filters} />;
};

export default Filters;
