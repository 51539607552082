import type { FC } from 'react';

import Search from '@zen/Components/Search';
import { Table } from '@zen/DesignSystem';
import { usePagination } from '@zen/utils/hooks/pagination';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import { useUserGroupsOptions } from '../components/FormAccountUserGroupsSelect/useUserGroupsOptions';
import {
  type GetAccountMembersQueryResult,
  type GetAccountMembersQueryVariables,
  useGetAccountBusinessUnitsQuery,
  useGetAccountMembersQuery
} from '../graphql';
import type { AccountMember } from '../types';
import Filters from './AccountMembersFilters';
import getColumnsConfiguration from './accountMembersTableConfiguration';
import AddMemberModalButton from './AddMemberModalButton';

const initialFilters = {
  includeArchived: false,
  search: ''
};

interface Props {
  accountId: string;
}

const AccountMembers: FC<Props> = ({ accountId }) => {
  const { filters, search, setSearch } = useUrlFilters(initialFilters);
  const { data } = useGetAccountBusinessUnitsQuery({ variables: { accountId, first: 20 } });
  const accountUserGroups = useUserGroupsOptions(accountId);

  const { loading, nodes, paginationInfo, refetch, totalCount } = usePagination<
    GetAccountMembersQueryResult,
    GetAccountMembersQueryVariables,
    AccountMember
  >(
    useGetAccountMembersQuery,
    'accountMembers',
    { accountId, accountMembersFiltersInput: { ...filters, searchQuery: search } },
    20,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first'
    }
  );

  const accountHasBusinessUnits: boolean = (data?.accountBusinessUnits?.nodes || []).length > 0;

  const handleSuccess = (): void => {
    setTimeout(() => {
      refetch();
    }, 1500);
  };

  return (
    <Table<AccountMember>
      actions={
        <AddMemberModalButton accountHasBusinessUnits={accountHasBusinessUnits} accountId={accountId} onSuccess={handleSuccess} />
      }
      additionalActions={
        <div className="flex space-x-2">
          <Search
            collapsible={true}
            onClear={() => setSearch('')}
            onSubmit={setSearch}
            placeholder="Search account members..."
            size="compact"
            widthClassName="w-48"
          />
          <Filters />
        </div>
      }
      columns={getColumnsConfiguration({ accountHasBusinessUnits, accountId, accountUserGroups, onDelete: handleSuccess })}
      data={nodes || []}
      emptyText="No members"
      loading={loading}
      paginationInfo={paginationInfo}
      tableId="accountMembers"
      title="Members"
      totalCountConfig={{
        totalCount,
        entityName: 'members'
      }}
    />
  );
};

export default AccountMembers;
