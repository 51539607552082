import type { FC } from 'react';

import { SkeletonLoading } from '@zen/DesignSystem';

import ActivityFeedLoadingSkeleton from '../ActivityFeedLoadingSkeleton';
import BookingOverviewLoadingSkeleton from '../BookingOverviewLoadingSkeleton';
import CargoOverviewLoadingSkeleton from '../CargoOverviewLoadingSkeleton';
import NotesLoadingSkeleton from '../NotesLoadingSkeleton';

const BookingDetailsLoadingSkeleton: FC = () => {
  return (
    <div className="px-10 pt-6" data-testid="booking-details-loading-skeleton">
      <div className="flex justify-between mb-10 border-b border-solid border-grey-lighter pt-7 pb-9">
        <div className="flex -mx-2">
          <SkeletonLoading height={56} width="w-14" />
          <div>
            <SkeletonLoading className="mb-1" height={24} width="w-52" />
            <SkeletonLoading height={24} width="w-36" />
          </div>
        </div>
        <div className="flex -mx-2 shrink-0">
          <SkeletonLoading height={40} width="w-10" />
          <SkeletonLoading height={40} width="w-10" />
          <SkeletonLoading height={40} width="w-10" />
          <SkeletonLoading height={40} width="w-10" />
        </div>
      </div>
      <div className="grid grid-flow-col grid-cols-12 gap-8">
        <div className="flex flex-col col-span-8 mb-10 space-y-6 2xl:col-span-9">
          <BookingOverviewLoadingSkeleton />
          <CargoOverviewLoadingSkeleton />
        </div>
        <div className="flex flex-col col-span-4 space-y-6 2xl:col-span-3">
          <ActivityFeedLoadingSkeleton />
          <NotesLoadingSkeleton />
        </div>
      </div>
    </div>
  );
};

export default BookingDetailsLoadingSkeleton;
