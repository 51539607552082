import { upperFirst } from 'lodash';
import type { FC, ReactNode } from 'react';

import type { TableColumn } from '@zen/DesignSystem';
import { Table } from '@zen/DesignSystem';
import { useRateCardContext } from '@zen/RateCards/RateCardContext';
import ChargeTableActions from '@zen/RateCards/RateCardForm/components/ChargeTableActions';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import type { ChargeTableProps } from '../../components/types';
import { getPortChargeTableColumns } from './portChargeTableConfig';

interface Props extends ChargeTableProps {
  type: 'origin' | 'destination';
}

const PortChargeTable: FC<Props> = ({ charges, onAdd, onDelete, onUpdate, onVisibilityUpdate, type }) => {
  const { cargoType, hasFreightCharges, hasPublishError, isEditable, modeOfTransport } = useRateCardContext();

  const columns: TableColumn<RateCardCharge>[] = getPortChargeTableColumns({
    actions: { onDelete, onUpdate, onVisibilityUpdate },
    cargoType,
    hasPublishError,
    isEditable,
    modeOfTransport,
    type
  });
  const title: string = `${upperFirst(type)} port charges`;
  const tableId: string = `${type}-port-charges`;
  const renderActions: ReactNode = isEditable && (
    <ChargeTableActions actionConfig={{ title: 'Add charge', type: 'secondary' }} isDisabled={!hasFreightCharges} onAdd={onAdd} />
  );

  return (
    <Table
      actions={renderActions}
      columns={columns}
      data={charges}
      rowKey="id"
      tableId={tableId}
      title={title}
      totalCountConfig={{ totalCount: charges.length, entityName: 'item' }}
    />
  );
};

export type { Props as PortChargeTableProps };

export default PortChargeTable;
