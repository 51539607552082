import type { FC } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import InlineEditableField from '@zen/Components/InlineEditableField';
import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault/ValueWithDefault';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateJourneyTrackingNumberMutation } from './graphql';

interface Props {
  canUpdateTrackingNumber: boolean;
  trackingNumber: string;
}

const TrackingNumberInlineField: FC<Props> = ({ canUpdateTrackingNumber, trackingNumber }) => {
  const { addSuccess, addError } = useNotification();

  const { zencargoReference } = useCargoOverviewContext();

  const [updateTrackingNumber] = useUpdateJourneyTrackingNumberMutation();

  const label: string = 'Tracking number';

  const handleTrackingNumberUpdate = (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateTrackingNumber({
          variables: {
            input: {
              trackingNumber: value,
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Tracking number updated.');
      }
    });
  };

  if (!canUpdateTrackingNumber) {
    return (
      <LabelledValue label={label} labelPosition="left" variant="default">
        <ValueWithDefault value={trackingNumber} />
      </LabelledValue>
    );
  }

  return (
    <InlineEditableField
      label={label}
      labelPlacement="left"
      name="tracking-number"
      onUpdate={handleTrackingNumberUpdate}
      placeholder="Enter tracking number"
      value={trackingNumber}
    />
  );
};

export default TrackingNumberInlineField;
