import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { InformationIcon } from '@zen/DesignSystem';

interface Props {
  children: ReactNode;
  className?: string;
  label: string;
  labelPosition?: 'left' | 'top';
  tooltip?: string;
  variant?: 'default' | 'compact';
}

const LabelledValue: FC<Props> = (props) => {
  const { className, children, label, labelPosition = 'top', variant = 'compact', tooltip, ...rest } = props;

  const classNames: string = cx(
    {
      'flex space-x-3 items-center': labelPosition === 'left'
    },
    className
  );

  const labelClassNames: string = cx(
    {
      'w-36': labelPosition === 'left'
    },
    'flex items-center space-x-1 text-xs leading-normal text-grey-base whitespace-nowrap'
  );

  const valueClassNames: string = cx(
    {
      'leading-10': variant === 'default',
      'pl-px': labelPosition === 'left'
    },
    'text-sm text-grey-dark'
  );

  return (
    <div className={classNames} data-testid="labelled-value" {...rest}>
      <div className={labelClassNames}>
        <span>{label}</span>
        {tooltip && <InformationIcon className="flex text-sm" content={tooltip} />}
      </div>
      <div className={valueClassNames}>{children}</div>
    </div>
  );
};

export default LabelledValue;
