import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import QueryHandler from '@zen/Components/QueryHandler';
import { SkeletonLoading } from '@zen/DesignSystem';
import Issues from '@zen/Issues/Issues';
import AddIssueButton from '@zen/Issues/Issues/AddIssueButton';
import { IssueAssociatedObject } from '@zen/Issues/types';

import { useGetIssuesQuery } from './graphql';
import type { IssuesResult } from './types';

interface Props {
  isAddButtonVisible?: boolean;
  zencargoReference: string;
}

const IssuesContainer: FC<Props> = ({ isAddButtonVisible = false, zencargoReference }) => {
  const { data, loading, error, refetch } = useGetIssuesQuery({
    variables: { zencargoReference },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first'
  });

  return (
    <QueryHandler
      data={data?.bookings?.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonLoading className="my-5" height={50} />}
    >
      {(issuesResult: IssuesResult) => {
        const canEditIssueVisibility: boolean = checkPermission(issuesResult, 'canModifyIssueVisibility');
        const canOpenIssue: boolean = checkPermission(issuesResult, 'canOpenIssue');
        const { issues } = issuesResult;

        return (
          <>
            {isAddButtonVisible && canOpenIssue && (
              <div className="flex justify-end">
                <AddIssueButton
                  associatedObjectReference={zencargoReference}
                  associatedType={IssueAssociatedObject.BOOKING}
                  canEditIssueVisibility={canEditIssueVisibility}
                  onIssueChange={refetch}
                />
              </div>
            )}
            <Issues associatedObjectReference={zencargoReference} issues={issues || []} onIssueChange={refetch} />
          </>
        );
      }}
    </QueryHandler>
  );
};

export default IssuesContainer;
