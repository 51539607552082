import type { FC } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import useRole from '@zen/Auth/hooks/useRole';
import PageTitle from '@zen/Components/PageTitle';
import { Role } from '@zen/Networks';
import { formatDate } from '@zen/utils/dateTime';

import type { OrderDetailsType } from '../types';
import OrderHeaderActions from './OrderHeaderActions';

type PickedOrder = Pick<
  OrderDetailsType,
  | 'id'
  | 'canClose'
  | 'canRemove'
  | 'canUpdate'
  | 'canViewCustomer'
  | 'customer'
  | 'orderDate'
  | 'orderReferenceNumber'
  | 'permissions'
>;

interface Props {
  onClose: () => void;
  onCloseOrder: (orderId: string) => void;
  onDeleteOrder: (orderId: string) => void;
  order: PickedOrder;
}

export const OrderDetailsHeader: FC<Props> = (props) => {
  const { order, onClose, onCloseOrder, onDeleteOrder } = props;

  const { orderDate, orderReferenceNumber } = order;
  const canViewCustomer = checkPermission<PickedOrder>(order, 'canViewCustomer');
  const customerName = order.customer?.name;
  const formattedOrderDate = orderDate ? formatDate(orderDate) : '-';
  const role = useRole();

  return (
    <header className="relative flex pt-6 mb-4">
      <div className="pl-6">
        {canViewCustomer && <p className="text-grey-dark">{customerName}</p>}
        <PageTitle
          tagline={
            <p>
              Raised on: <span className="font-bold">{formattedOrderDate}</span>
            </p>
          }
          title={orderReferenceNumber}
        />
      </div>
      {(order.permissions?.canManage?.value || role === Role.AGENT_FORWARDER) && (
        <OrderHeaderActions onClose={onClose} onCloseOrder={onCloseOrder} onDeleteOrder={onDeleteOrder} order={order} />
      )}
    </header>
  );
};

export default OrderDetailsHeader;
