import type { FC, ReactNode } from 'react';
import { useState } from 'react';

import ActivityFeedItemWrapper from '@zen/ActivityFeed/components/ActivityFeedItemWrapper';
import ChipSelect from '@zen/Components/ChipSelect';
import SubmittableInput from '@zen/Components/Form/SubmittableInput';
import { Label } from '@zen/DesignSystem';
import type { QuoteOption } from '@zen/Quotes';
import { QuoteOptionRejectionReason } from '@zen/Quotes';

import { getOptions, isOtherReason, reasons } from './quoteOptionRejectReasonItem.helpers';

interface Props {
  onSubmit: (reason: QuoteOptionRejectionReason, reasonDescription?: string) => void;
  quoteOption: QuoteOption;
}

const QuoteOptionRejectReasonItem: FC<Props> = (props) => {
  const { onSubmit } = props;
  const [quoteOption, setQuoteOption] = useState<QuoteOption>(props.quoteOption);
  const { rejectionReason, reasonDescription, quoteReference } = quoteOption;
  const [description, setDescription] = useState<string>('');
  const isReasonProvided = (): boolean => {
    // no selected reason, don't block fields and keep them clickable
    if (!rejectionReason || rejectionReason === QuoteOptionRejectionReason.UNKNOWN) {
      return false;
    }

    if (isOtherReason(rejectionReason)) {
      return !!reasonDescription;
    }

    // block if any other reasons has been selected
    return reasons.includes(rejectionReason);
  };

  const handleSelect = (reason: QuoteOptionRejectionReason): void => {
    setQuoteOption({
      ...quoteOption,
      rejectionReason: reason
    });

    if (!isOtherReason(reason)) {
      onSubmit(reason);
    }
  };

  const renderDescription = (): ReactNode => {
    if (reasonDescription) {
      return <div className="mt-4 text-sm italic text-grey-base">{reasonDescription}</div>;
    }

    const handleSubmit = (input: string): void => {
      setQuoteOption({
        ...quoteOption,
        reasonDescription: input
      });

      onSubmit(QuoteOptionRejectionReason.OTHER, input);
    };

    return (
      <div className="mt-4">
        <SubmittableInput
          onChange={setDescription}
          onSubmit={handleSubmit}
          placeholder="Please elaborate..."
          value={description}
        />
      </div>
    );
  };

  const labelContent: ReactNode = (
    <div className="flex justify-between w-full">
      <span>Can I ask for the reason you rejected this quote option?</span>
      {quoteReference && <span>Reference: {quoteReference}</span>}
    </div>
  );

  return (
    <ActivityFeedItemWrapper>
      <div className="pl-6">
        <Label content={labelContent}>
          <ChipSelect onChange={handleSelect} options={getOptions(isReasonProvided())} value={rejectionReason} />
          {isOtherReason(rejectionReason) && renderDescription()}
        </Label>
      </div>
    </ActivityFeedItemWrapper>
  );
};

export default QuoteOptionRejectReasonItem;
