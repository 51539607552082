import { uniqueId } from 'lodash';

import { getEmptyChargeItem } from '@zen/RateCards/RateCardForm/components/RateCardChargeFormItems';
import type { ChargeItemFormValues } from '@zen/RateCards/RateCardForm/types';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import type { CostTrackingLocation, OtherChargeFormInitialValues, OtherChargeFormValues } from './types';
import { Applicability } from './types';

export const initialValues: OtherChargeFormInitialValues = {
  chargeType: null,
  chargeItems: [getEmptyChargeItem()],
  toLocationId: null
};

export const findPort = (ports: CostTrackingLocation[], portId: string) => {
  return ports.find((port: CostTrackingLocation) => port.id === portId);
};

export const preparePayload = (values: OtherChargeFormValues, ports: CostTrackingLocation[]): RateCardCharge[] => {
  const { chargeType, toLocationId } = values;

  return values.chargeItems.map((chargeItem: ChargeItemFormValues) => {
    const { chargeBasis, cargoOptions, chargeName, unitPrice } = chargeItem;

    return {
      applicability: [Applicability.DESTINATION],
      chargeBasis,
      cargoOptions,
      chargeType,
      chargeTypeId: chargeType?.id,
      currency: unitPrice.currency,
      id: uniqueId(),
      itemName: chargeName,
      toLocation: findPort(ports, toLocationId),
      unitPrice: unitPrice.value
    };
  });
};
