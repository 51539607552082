import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ApiKeysQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
}>;

export type ApiKeysQueryResult = { __typename?: 'Query' } & {
  account?: GraphQLTypes.Maybe<
    { __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'id'> & {
        ssoDetails: { __typename?: 'SsoDetails' } & {
          apiKeys: Array<{ __typename?: 'ScimApiKey' } & Pick<GraphQLTypes.ScimApiKey, 'expiryDate' | 'id' | 'status'>>;
        };
      }
  >;
};

export const ApiKeysDocument = /* #__PURE__ */ gql`
  query apiKeys($accountId: String!) {
    account(accountId: $accountId) {
      id
      ssoDetails {
        apiKeys {
          expiryDate
          id
          status
        }
      }
    }
  }
`;

/**
 * __useApiKeysQuery__
 *
 * To run a query within a React component, call `useApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiKeysQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *   },
 * });
 */
export function useApiKeysQuery(baseOptions: Apollo.QueryHookOptions<ApiKeysQueryResult, ApiKeysQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<ApiKeysQueryResult, ApiKeysQueryVariables>(ApiKeysDocument, options);
}
export function useApiKeysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiKeysQueryResult, ApiKeysQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<ApiKeysQueryResult, ApiKeysQueryVariables>(ApiKeysDocument, options);
}
export type ApiKeysQueryHookResult = ReturnType<typeof useApiKeysQuery>;
export type ApiKeysLazyQueryHookResult = ReturnType<typeof useApiKeysLazyQuery>;
