import cx from 'classnames';
import { isArray } from 'lodash';

import CurrencySelect from '@zen/Components/CurrencySelect';
import InlineEditableField from '@zen/Components/InlineEditableField';
import InlineNumberInput from '@zen/Components/InlineNumberInput';
import { applicabilityLabelMapping } from '@zen/CostTracking';
import type { TableColumn } from '@zen/DesignSystem';
import type { RateCardCharge } from '@zen/RateCards/reducer';
import { formatNumber } from '@zen/utils/formatting';
import type { Nullable } from '@zen/utils/typescript';

import { formatCost } from '../RateCardForm/utils/tableDataFormatting';
import ActionHandleContainer from './ActionHandleContainer';
import CargoOptionsSelect from './CargoOptionsSelect';
import ChargeBasisSelect from './ChargeBasisSelect';
import type {
  Applicability,
  CargoOptionEnum,
  ChargeBasis,
  ChargeLocationType,
  ChargeTableAction,
  MultiSelectColumnProps,
  UnitPriceColumnProps
} from './types';
import { ChargeBasisGroup, Currency } from './types';

const priceColumnWidth: number = 110;
const dropdownColumnWidth: number = 160;

const hasValue = <T extends {} | undefined | null>(value: T): boolean => {
  return isArray(value) ? !!value.length : !!value;
};

const renderCell = <T extends {} | undefined | null>(value: T) => {
  if (!hasValue(value)) {
    return '-';
  }

  return value;
};

export const renderApplicabilities = (applicability: Applicability[]): string => {
  return applicability.map((applicabilityItem: Applicability) => applicabilityLabelMapping[applicabilityItem]).join(', ') || '-';
};

export const getActionsColumn = (actions: ChargeTableAction): TableColumn<RateCardCharge> => {
  const { onDelete, onVisibilityUpdate } = actions;

  const handleDelete = (index: number) => {
    onDelete?.(index);
  };

  return {
    key: 'actions',
    fixed: 'right',
    sortable: false,
    render: (_, charge: RateCardCharge, index: number) => {
      const checkVisibility = (): boolean | undefined => {
        if (!charge.defaultCharge?.id) {
          return undefined;
        }

        return !charge.defaultChargeHidden;
      };

      return (
        <ActionHandleContainer
          isDefaultChargeVisible={checkVisibility()}
          onDelete={() => handleDelete(index)}
          onUpdate={() => onVisibilityUpdate?.(charge.defaultCharge?.id || '', !charge.defaultChargeHidden)}
        />
      );
    }
  };
};

export const getApplicabilityColumn = (title: string): TableColumn<RateCardCharge> => {
  return {
    key: 'applicability',
    sortable: false,
    title,
    render: (_, { applicability }: RateCardCharge) => {
      return renderApplicabilities(applicability);
    }
  };
};

export const getCargoOptionsColumn = ({
  cargoType,
  modeOfTransport,
  isEditable,
  onUpdate
}: MultiSelectColumnProps): TableColumn<RateCardCharge> => {
  return {
    key: 'cargoOptions',
    sortable: false,
    title: 'Cargo options',
    width: dropdownColumnWidth,
    render: (_, { chargeType, cargoOptions, defaultChargeHidden }: RateCardCharge, index: number) => {
      const handleChange = (values: Nullable<CargoOptionEnum[]>) => {
        onUpdate?.(index, { cargoOptions: values || [] });
      };

      const isDisabled: boolean = !isEditable || chargeType.basisGroup !== ChargeBasisGroup.CARGO || !!defaultChargeHidden;
      const classNames: string = cx(
        {
          '-ml-3': isEditable
        },
        'w-full'
      );

      return (
        <div className={classNames}>
          <CargoOptionsSelect
            cargoType={cargoType}
            isDisabled={isDisabled}
            isEditable={isEditable}
            modeOfTransport={modeOfTransport}
            onChange={handleChange}
            value={cargoOptions}
            variant="inline"
          />
        </div>
      );
    }
  };
};

export const getChargeBasisColumn = ({
  cargoType,
  modeOfTransport,
  isEditable,
  onUpdate
}: MultiSelectColumnProps): TableColumn<RateCardCharge> => {
  return {
    key: 'chargeBasis',
    sortable: false,
    title: 'Basis',
    width: dropdownColumnWidth,
    render: (_, { chargeBasis, chargeType, defaultChargeHidden }: RateCardCharge, index: number) => {
      const handleChange = (values: Nullable<ChargeBasis[]>) => {
        onUpdate?.(index, { chargeBasis: values || [] });
      };

      const values: string[] = chargeBasis.map((item: ChargeBasis) => item.id);
      const isDisabled: boolean = !isEditable || !!defaultChargeHidden;

      const classNames: string = cx(
        {
          '-ml-3': isEditable
        },
        'w-full'
      );

      return (
        <div className={classNames}>
          <ChargeBasisSelect
            cargoType={cargoType}
            chargeBasisGroup={chargeType.basisGroup}
            isDisabled={isDisabled}
            isEditable={isEditable}
            modeOfTransport={modeOfTransport}
            onChange={handleChange}
            value={values}
            variant="inline"
          />
        </div>
      );
    }
  };
};

export const getChargeNameColumn = (
  isEditable: boolean = true,
  onItemNameUpdate: ChargeTableAction['onUpdate']
): TableColumn<RateCardCharge> => {
  return {
    key: 'itemName',
    ellipsis: true,
    sortable: false,
    title: 'Charge name',
    render: (_, { defaultChargeHidden, itemName }: RateCardCharge, index: number) => {
      if (!isEditable || !!defaultChargeHidden) {
        return itemName;
      }

      const handleChange = (value: string) => {
        onItemNameUpdate?.(index, { itemName: value || '' });
      };

      return (
        <div className="-ml-3">
          <InlineEditableField copyable={false} name="itemName" onUpdate={handleChange} value={itemName} />
        </div>
      );
    }
  };
};

export const chargeTypeColumn: TableColumn<RateCardCharge> = {
  key: 'chargeType',
  ellipsis: true,
  sortable: false,
  title: 'Charge type',
  render: (_, { chargeType }: RateCardCharge) => {
    return chargeType?.name;
  }
};

export const getCurrencyColumn = (
  isEditable: boolean = true,
  onCurrencyUpdate: ChargeTableAction['onUpdate'],
  title: string = 'Currency'
): TableColumn<RateCardCharge> => {
  return {
    key: 'currency',
    fixed: 'right',
    sortable: false,
    title,
    width: priceColumnWidth,
    render: (_, { currency, defaultChargeHidden }: RateCardCharge, index: number) => {
      if (!isEditable || !!defaultChargeHidden) {
        return renderCell(currency);
      }

      const handleChange = (value: Nullable<Currency>) => {
        onCurrencyUpdate?.(index, { currency: value || Currency.GBP });
      };

      return (
        <div className="-ml-3">
          <CurrencySelect onChange={handleChange} renderMenuInPortal={true} size="compact" value={currency} variant="inline" />
        </div>
      );
    }
  };
};

export const getLocationColumn = (
  locationKey: ChargeLocationType,
  title: string,
  additionalOptions: Partial<TableColumn<RateCardCharge>> = {}
): TableColumn<RateCardCharge> => {
  return {
    key: locationKey,
    fixed: 'left',
    mergeBy: (charge: RateCardCharge) => charge[locationKey]?.id || '',
    sortable: false,
    title,
    render: (_, charge: RateCardCharge) => {
      return <span className="text-wrap">{charge[locationKey]?.label?.long}</span>;
    },
    ...additionalOptions
  };
};

export const getUnitPriceColumn = ({
  isEditable = true,
  hasPublishError,
  onPriceUpdate,
  title = 'Unit price'
}: UnitPriceColumnProps): TableColumn<RateCardCharge> => {
  return {
    key: 'unitPrice',
    alignment: 'right',
    fixed: 'right',
    sortable: false,
    title,
    width: priceColumnWidth,
    render: (_, { defaultChargeHidden, defaultCharge, unitPrice }: RateCardCharge, index: number) => {
      const nonEditableValue = unitPrice || 0;
      const formattedPriceValue = formatNumber(nonEditableValue, 2, 2);

      if (!isEditable || !!defaultChargeHidden) {
        return renderCell(formattedPriceValue);
      }

      const handleUpdate = (updatedUnitPrice: Nullable<number>): void => {
        onPriceUpdate?.(index, { unitPrice: updatedUnitPrice });
      };
      const hasError: boolean = hasPublishError && !defaultCharge?.unitPrice && !unitPrice;

      return (
        <div className="absolute top-[0.5rem] right-[0.25rem]">
          <InlineNumberInput hasError={hasError} onUpdate={handleUpdate} size="compact" value={unitPrice} />
        </div>
      );
    }
  };
};

export const defaultChargeDetailsColumn: TableColumn<RateCardCharge> = {
  key: 'defaultChargeDetails',
  sortable: false,
  title: 'Default price',
  width: priceColumnWidth,
  render: (_, { defaultCharge, unitPrice }: RateCardCharge) => {
    const value: Nullable<string> = defaultCharge?.id ? formatCost(defaultCharge.unitPrice, defaultCharge.currency) : null;
    const hasCustomPrice: boolean = !!unitPrice && unitPrice !== defaultCharge?.unitPrice;
    const className: string = cx({
      'line-through': hasCustomPrice
    });

    return <span className={className}>{renderCell(value)}</span>;
  }
};
