import type { FC, ReactElement } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';
import QueryHandler from '@zen/Components/QueryHandler';
import { IssueAssociatedObject } from '@zen/Issues';
import IssuesContainer from '@zen/Issues/IssuesContainer';

import { useOrderIssuesQuery } from './graphql';

interface Props {
  canManagePO?: boolean;
  orderId: string;
}

const OrderIssues: FC<Props> = ({ canManagePO = true, orderId }) => {
  const { loading, error, data, refetch } = useOrderIssuesQuery({ variables: { orderId }, fetchPolicy: 'cache-and-network' });

  const handleIssueChange = (): void => {
    refetch();
  };

  return (
    <QueryHandler data={data?.purchaseOrders?.nodes?.[0]} error={!!error} isLoading={loading}>
      {(order): ReactElement => {
        const canOpenIssue: boolean = checkPermission(order, 'canOpenIssue') && canManagePO;
        const canModifyIssueVisibility: boolean = checkPermission(order, 'canModifyIssueVisibility');

        return (
          <IssuesContainer
            associatedObjectId={orderId}
            associatedObjectReference={order.orderReferenceNumber || ''}
            associatedType={IssueAssociatedObject.PURCHASE_ORDER}
            canEditIssueVisibility={canModifyIssueVisibility}
            canOpenIssue={canOpenIssue}
            issues={order.issues || []}
            onIssueChange={handleIssueChange}
          />
        );
      }}
    </QueryHandler>
  );
};

export default OrderIssues;
