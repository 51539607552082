import { max } from 'lodash';

import type { ModeOfTransport } from '@zen/Booking';
import { isAirBooking, isOceanBooking } from '@zen/Booking';
import type { JourneyMilestoneWithMetadata } from '@zen/Journey/types';
import type { Terminal } from '@zen/Networks';
import type { Optional } from '@zen/utils/typescript';

import DisabledDateTooltip from '../../DisabledDateTooltip';
import {
  getLatestGateOutEmptyMilestone,
  getMilestoneMinAndMaxDatesAcrossBooking,
  getMinDateOutsideJourney
} from '../../JourneyDetails/helpers';
import type { DateEndpoint, MilestoneDisabledDateProps } from '../../JourneyDetails/types';
import { AirScheduleCarrier, type PlannedScheduleValues, type Stop } from './types';

const disabledDatesGenericMessage: string = 'Dates must be in chronological order';

export const getCarrierLabel = (modeOfTransport: Optional<ModeOfTransport>): string => {
  if (isOceanBooking(modeOfTransport)) {
    return 'Carrier (SCAC)';
  }

  return isAirBooking(modeOfTransport) ? 'Airline (IATA)' : 'Railway company';
};

export const getFirstStopDepartureDateLabel = (modeOfTransport: Optional<ModeOfTransport>): string => {
  if (isOceanBooking(modeOfTransport)) {
    return 'Departure from port of loading';
  }

  return isAirBooking(modeOfTransport) ? 'Departure from origin airport' : 'Departure from origin rail station';
};

export const getFirstStopLocationName = (modeOfTransport: Optional<ModeOfTransport>): string => {
  if (isOceanBooking(modeOfTransport)) {
    return 'Port of loading';
  }

  return isAirBooking(modeOfTransport) ? 'Origin airport' : 'Origin rail station';
};

export const getMiddleStopLocationName = (modeOfTransport: Optional<ModeOfTransport>): string => {
  if (isOceanBooking(modeOfTransport)) {
    return 'Transshipment port';
  }

  return isAirBooking(modeOfTransport) ? 'Connecting airport' : 'Connecting rail station';
};

export const getMiddleStopArrivalDateLabel = (modeOfTransport: Optional<ModeOfTransport>): string => {
  if (isOceanBooking(modeOfTransport)) {
    return 'Arrival at transshipment port';
  }

  return isAirBooking(modeOfTransport) ? 'Arrival at connecting airport' : 'Arrival at connecting rail station';
};

export const getMiddleStopDepartureDateLabel = (modeOfTransport: Optional<ModeOfTransport>): string => {
  if (isOceanBooking(modeOfTransport)) {
    return 'Departure from transshipment port';
  }

  return isAirBooking(modeOfTransport) ? 'Departure from connecting airport' : 'Departure from connecting rail station';
};

export const getLastStopArrivalDateLabel = (modeOfTransport: Optional<ModeOfTransport>): string => {
  if (isOceanBooking(modeOfTransport)) {
    return 'Arrival at port of destination';
  }

  return isAirBooking(modeOfTransport) ? 'Arrival at destination airport' : 'Arrival at destination rail station';
};

export const getLastStopLocationName = (modeOfTransport: Optional<ModeOfTransport>): string => {
  if (isOceanBooking(modeOfTransport)) {
    return 'Port of destination';
  }

  return isAirBooking(modeOfTransport) ? 'Destination airport' : 'Destination rail station';
};

export const getLocationLabel = (
  isFirstStop: boolean,
  isLastStop: boolean,
  modeOfTransport: Optional<ModeOfTransport>
): string => {
  if (isFirstStop) {
    return getFirstStopLocationName(modeOfTransport);
  }

  return isLastStop ? getLastStopLocationName(modeOfTransport) : getMiddleStopLocationName(modeOfTransport);
};

const lowercaseFirstCharacter = (str: string): string => str.charAt(0).toLowerCase() + str.slice(1);

export const getArrivalDateLabel = (
  isLastStop: boolean,
  modeOfTransport: Optional<ModeOfTransport>,
  isCompleted: Optional<boolean>
): string => {
  const label: string = isLastStop
    ? getLastStopArrivalDateLabel(modeOfTransport)
    : getMiddleStopArrivalDateLabel(modeOfTransport);

  return isCompleted ? label : `Estimated ${lowercaseFirstCharacter(label)}`;
};

export const getDepartureDateLabel = (
  isFirstStop: boolean,
  modeOfTransport: Optional<ModeOfTransport>,
  isCompleted: Optional<boolean>
): string => {
  const label: string = isFirstStop
    ? getFirstStopDepartureDateLabel(modeOfTransport)
    : getMiddleStopDepartureDateLabel(modeOfTransport);

  return isCompleted ? label : `Estimated ${lowercaseFirstCharacter(label)}`;
};

export const createEmptyStop = (terminal: Optional<Terminal> = null): Stop => ({
  arrivalDate: '',
  cargoLoadedDate: '',
  cargoUnloadedDate: '',
  departureDate: '',
  location: terminal,
  vesselName: '',
  voyageNumber: ''
});

export const createInitialValues = (
  departureTerminal: Optional<Terminal>,
  arrivalTerminal: Optional<Terminal>
): PlannedScheduleValues => ({
  airScheduleCarrierType: AirScheduleCarrier.AIRLINE,
  carrier: '',
  trackingNumber: null,
  stops: [createEmptyStop(departureTerminal), createEmptyStop(arrivalTerminal)]
});

export const getStopDates = (stops: Stop[]): string[] => {
  return stops
    .reduce((prev: string[], { arrivalDate, departureDate }) => {
      return prev.concat([arrivalDate, departureDate]);
    }, [])
    .filter((date) => date);
};

export const getPreviousAndNextStop = (stops: Stop[], index: number) => {
  const stopWithDate = ({ arrivalDate, departureDate }: Stop) => {
    return arrivalDate || departureDate;
  };
  const previousStop: Stop | undefined = stops.slice(0, index).reverse().find(stopWithDate);
  const nextStop: Stop | undefined = stops.slice(index + 1).find(stopWithDate);

  return {
    previousStop,
    nextStop
  };
};

const getDisabledDates = (
  minDateWithinSchedule: Optional<string>,
  maxDateWithinSchedule: Optional<string>,
  disabledDatesOutsideSchedule: MilestoneDisabledDateProps
): MilestoneDisabledDateProps => {
  const minDate: Optional<string> = minDateWithinSchedule || disabledDatesOutsideSchedule.disabledDates?.minDate;
  const maxDate: Optional<string> = maxDateWithinSchedule || disabledDatesOutsideSchedule.disabledDates?.maxDate;

  return {
    disabledDates: {
      minDate,
      maxDate
    },
    tooltip: [
      ...(minDateWithinSchedule ? [{ matcher: { minDate: minDateWithinSchedule }, message: disabledDatesGenericMessage }] : []),
      ...(maxDateWithinSchedule ? [{ matcher: { maxDate: maxDateWithinSchedule }, message: disabledDatesGenericMessage }] : []),
      ...(disabledDatesOutsideSchedule.tooltip ? disabledDatesOutsideSchedule.tooltip : [])
    ]
  };
};

export const getDisabledDatesForDepartureDate = (
  stops: Stop[],
  index: number,
  disabledDatesOutsideSchedule: MilestoneDisabledDateProps
): MilestoneDisabledDateProps => {
  const { previousStop, nextStop } = getPreviousAndNextStop(stops, index);

  const currentStop = stops[index];
  const maxDateFromCurrentStop = max([currentStop.arrivalDate, currentStop.cargoLoadedDate, currentStop.cargoUnloadedDate]);
  const minDateWithinSchedule: string | undefined =
    maxDateFromCurrentStop || (previousStop ? previousStop.departureDate || previousStop.arrivalDate : undefined);
  const maxDateWithinSchedule: string | undefined = nextStop ? nextStop.arrivalDate || nextStop.departureDate : undefined;

  return getDisabledDates(minDateWithinSchedule, maxDateWithinSchedule, disabledDatesOutsideSchedule);
};

export const getDisabledDatesForArrivalDate = (
  stops: Stop[],
  index: number,
  disabledDatesOutsideSchedule: MilestoneDisabledDateProps
): MilestoneDisabledDateProps => {
  const { previousStop, nextStop } = getPreviousAndNextStop(stops, index);

  const departureDateFromCurrentStop: Optional<string> = stops[index].departureDate;
  const minDateWithinSchedule: Optional<string> = previousStop
    ? previousStop.departureDate || previousStop.arrivalDate
    : undefined;
  const maxDateWithinSchedule: Optional<string> =
    departureDateFromCurrentStop || (nextStop ? nextStop.arrivalDate || nextStop.departureDate : undefined);

  return getDisabledDates(minDateWithinSchedule, maxDateWithinSchedule, disabledDatesOutsideSchedule);
};

export interface DisabledDatesParams {
  arrivalMilestoneId: string;
  cargoMilestones: Record<string, JourneyMilestoneWithMetadata[]>;
  cargoPreLegsMilestones: Record<string, JourneyMilestoneWithMetadata[]>;
  cargoReadyDate: Optional<string>;
  departureMilestoneId: string;
  modeOfTransport: Optional<ModeOfTransport>;
}

export const getDisabledDatesAcrossBooking = (params: DisabledDatesParams): MilestoneDisabledDateProps => {
  const { arrivalMilestoneId, cargoReadyDate, cargoMilestones, cargoPreLegsMilestones, departureMilestoneId, modeOfTransport } =
    params;

  const minDateOutsideJourney: Optional<DateEndpoint> = getMinDateOutsideJourney({
    cargoReadyDate,
    gateOutEmptyMilestone: getLatestGateOutEmptyMilestone(cargoPreLegsMilestones),
    modeOfTransport
  });

  const { minDate } = getMilestoneMinAndMaxDatesAcrossBooking(cargoMilestones, modeOfTransport, departureMilestoneId);
  const { maxDate } = getMilestoneMinAndMaxDatesAcrossBooking(cargoMilestones, modeOfTransport, arrivalMilestoneId);

  return {
    disabledDates: {
      minDate: minDate?.date || minDateOutsideJourney?.date,
      maxDate: maxDate?.date
    },
    tooltip: [
      ...(minDate?.date
        ? [
            {
              matcher: { minDate: minDate.date },
              message: (
                <DisabledDateTooltip dateConstraint="min" dateType={minDate.dateType} milestoneName={minDate.milestoneName} />
              )
            }
          ]
        : [
            {
              matcher: { minDate: minDateOutsideJourney?.date },
              message: (
                <DisabledDateTooltip
                  dateConstraint="min"
                  dateType={minDateOutsideJourney?.dateType}
                  milestoneName={minDateOutsideJourney?.milestoneName || ''}
                />
              )
            }
          ]),
      ...(maxDate?.date
        ? [
            {
              matcher: { maxDate: maxDate.date },
              message: (
                <DisabledDateTooltip dateConstraint="max" dateType={maxDate.dateType} milestoneName={maxDate.milestoneName} />
              )
            }
          ]
        : [])
    ]
  };
};
