const DOCUMENT_RELATIVE_INDEX_PATH = 'document-processor';
const COMMERCIAL_INVOICE_RELATIVE_INDEX_PATH = 'commercial-invoice';

export const DOCUMENT_ROUTE_PREFIX = `/dashboard/${DOCUMENT_RELATIVE_INDEX_PATH}`;

interface DocumentProcessorRoutes {
  commercialInvoice: {
    getUrl: (id: string) => string;
    path: string;
    relativePath: string;
  };
  index: {
    getUrl: () => string;
    path: string;
    relativePath: string;
  };
}

const documentProcessorRoutes: DocumentProcessorRoutes = {
  index: {
    getUrl: () => DOCUMENT_ROUTE_PREFIX,
    path: DOCUMENT_ROUTE_PREFIX,
    relativePath: `${DOCUMENT_RELATIVE_INDEX_PATH}/*`
  },
  commercialInvoice: {
    getUrl: (documentId: string) => `${DOCUMENT_ROUTE_PREFIX}/${COMMERCIAL_INVOICE_RELATIVE_INDEX_PATH}/${documentId}`,
    path: `${DOCUMENT_ROUTE_PREFIX}/${COMMERCIAL_INVOICE_RELATIVE_INDEX_PATH}/:id`,
    relativePath: `${COMMERCIAL_INVOICE_RELATIVE_INDEX_PATH}/:id`
  }
};

export default documentProcessorRoutes;
