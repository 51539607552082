import type { RouteTabConfig } from '@zen/Components/RouteTabs';
import { accountSettingsRoutes } from '@zen/Routes';

import AccountConnections from '../AccountDetails/AccountConnections';
import AccountLocations from '../AccountDetails/AccountLocations';
import AccountMembers from '../AccountDetails/AccountMembers';
import BusinessUnits from '../AccountDetails/BusinessUnits';
import GeneralAccountDetails from '../AccountDetails/GeneralAccountDetails';
import type { Account } from '../AccountDetails/types';
import Webhooks from '../AccountDetails/Webhooks';
import Authentication from '../Authentication';

interface AccountSettingsPermissions {
  canViewAccountGeneral: boolean;
  canViewAuthentication: boolean;
  canViewBusinessUnits: boolean;
  canViewConnections: boolean;
  canViewLocations: boolean;
  canViewMembers: boolean;
  canViewWebhooks: boolean;
}

const getTabsConfig = (
  accountDetails: Account,
  {
    canViewAccountGeneral,
    canViewAuthentication,
    canViewBusinessUnits,
    canViewConnections,
    canViewLocations,
    canViewMembers,
    canViewWebhooks
  }: AccountSettingsPermissions
): RouteTabConfig[] => {
  const { id: accountId, ssoDetails, tradingName } = accountDetails;

  const businessUnits: RouteTabConfig = {
    path: accountSettingsRoutes.businessUnits.relativePath,
    label: 'Business units',
    component: <BusinessUnits accountId={accountId} accountReferencePrefix={accountDetails.referencePrefix} />,
    url: accountSettingsRoutes.businessUnits.getUrl()
  };

  const general: RouteTabConfig = {
    path: accountSettingsRoutes.generalInformation.relativePath,
    label: 'General',
    component: <GeneralAccountDetails accountDetails={accountDetails} />,
    url: accountSettingsRoutes.generalInformation.getUrl()
  };

  const members: RouteTabConfig = {
    path: accountSettingsRoutes.members.relativePath,
    label: 'Members',
    component: <AccountMembers accountId={accountId} />,
    url: accountSettingsRoutes.members.getUrl()
  };

  const locations: RouteTabConfig = {
    path: accountSettingsRoutes.locations.relativePath,
    label: 'Locations',
    component: <AccountLocations accountId={accountId} accountName={tradingName} />,
    url: accountSettingsRoutes.locations.getUrl()
  };

  const connections: RouteTabConfig = {
    path: accountSettingsRoutes.connections.relativePath,
    label: 'Connections',
    component: <AccountConnections accountId={accountId} />,
    url: accountSettingsRoutes.connections.getUrl()
  };

  const authentication: RouteTabConfig = {
    path: accountSettingsRoutes.authentication.relativePath,
    label: 'Authentication',
    component: <Authentication accountId={accountId} accountName={tradingName} ssoDetails={ssoDetails} />,
    url: accountSettingsRoutes.authentication.getUrl()
  };

  const webhooks: RouteTabConfig = {
    path: accountSettingsRoutes.webhooks.relativePath,
    label: 'Webhooks',
    component: <Webhooks accountId={accountId} />,
    url: accountSettingsRoutes.webhooks.getUrl()
  };

  const tabs = [];

  if (canViewAccountGeneral) {
    tabs.push(general);
  }

  if (canViewMembers) {
    tabs.push(members);
  }

  if (canViewLocations) {
    tabs.push(locations);
  }

  if (canViewBusinessUnits) {
    tabs.push(businessUnits);
  }

  if (canViewConnections) {
    tabs.push(connections);
  }

  if (canViewWebhooks) {
    tabs.push(webhooks);
  }

  if (canViewAuthentication) {
    tabs.push(authentication);
  }

  return tabs;
};

export { getTabsConfig };
