import { camelCase, upperCase } from 'lodash';

import type { Nullable, Optional, Undefinable } from '@zen/utils/typescript';

import { legacyNewTypesMapping } from './cargoDictionaryMapping.helper';
import type {
  CargoItem,
  CargoTransition,
  CargoWeight,
  CoreCargo,
  CoreCargoExtended,
  CoreCargoInitial,
  CoreCargoType,
  Dimensions,
  LegacyCargo,
  PalletTypeEnum,
  ValueOfGoods,
  Volume
} from './types';
import { CargoItemTypeEnum, LooseCargoTypeEnum } from './types';

export const getCoreCargoType = (cargoType: Optional<string>): Nullable<CoreCargoType> => {
  return cargoType ? (camelCase(cargoType) as CoreCargoType) : null;
};

const transitionCargo = (
  legacyCargo: Optional<LegacyCargo>,
  coreCargos?: Optional<CoreCargoInitial[]>
): Optional<CargoTransition> => {
  if (!legacyCargo && !coreCargos) {
    return null;
  }

  const prepareCoreCargo = (coreCargoItem: CoreCargoInitial): CoreCargo => {
    const { cargoRelations, ...rest } = coreCargoItem;
    const inputCargo: Optional<CoreCargoInitial> = coreCargoItem?.cargoRelations?.[0]?.inputCargo;

    return {
      ...rest,
      cargoType: getCoreCargoType(coreCargoItem.cargoType),
      ...(coreCargoItem?.cargoRelations?.length && {
        looseCargo: {
          ...inputCargo,
          id: inputCargo?.id || '',
          cargoType: getCoreCargoType(inputCargo?.cargoType)
        }
      }),
      // temporary mapping as BE returns string in lowerCase, will be changed but first we need to decide on enums
      ...(coreCargoItem?.chargeableWeight && {
        chargeableWeight: {
          ...coreCargoItem?.chargeableWeight,
          unit: upperCase(coreCargoItem?.chargeableWeight.unit)
        }
      }),
      ...(coreCargoItem?.dimensions && {
        dimensions: {
          ...coreCargoItem?.dimensions,
          unit: upperCase(coreCargoItem?.dimensions.unit)
        }
      }),
      ...(coreCargoItem?.grossWeight && {
        grossWeight: {
          ...coreCargoItem?.grossWeight,
          unit: upperCase(coreCargoItem?.grossWeight.unit)
        }
      }),
      ...(coreCargoItem?.volume && {
        volume: {
          ...coreCargoItem?.volume,
          unit: upperCase(coreCargoItem?.volume.unit)
        }
      })
    };
  };

  const prepareCoreCargos = (cargos: CoreCargoInitial[]): CoreCargo[] => {
    return cargos.map(prepareCoreCargo);
  };

  if (!legacyCargo && coreCargos?.length) {
    // need to cast for UI to rely only on CoreCargo type, later will be removed only to CoreCargo
    return {
      cargoList: prepareCoreCargos(coreCargos)
    };
  }

  const cargoToTransition: CoreCargo[] = legacyCargo?.cargoItems?.length
    ? legacyCargo?.cargoItems?.map((cargoItem: CargoItem) => {
        const coreCargoMatch: Undefinable<CoreCargoInitial> = coreCargos?.find(
          (coreCargoItem: CoreCargoInitial) => coreCargoItem.id === cargoItem?.id
        );

        const {
          canManageAssignedLots,
          canManageCollectionLocation,
          canManageDeliveryLocation,
          canUpdateVehicleTrailerId,
          canViewCollectionDetails,
          canViewDeliveryDetails,
          collection,
          delivery
        } = cargoItem;
        const mappedLegacyCargo: CoreCargo = mapCargo(cargoItem);

        return {
          ...(coreCargoMatch ? prepareCoreCargo(coreCargoMatch) : mappedLegacyCargo),
          ...(!coreCargoMatch && { cargoType: mappedLegacyCargo.cargoType, legacyCargo: true }),
          // rest of cargoItems fields which aren't currently supported in cargo micro-service
          ...(canManageAssignedLots && { canManageAssignedLots }),
          ...(canManageCollectionLocation && { canManageCollectionLocation }),
          ...(canManageDeliveryLocation && { canManageDeliveryLocation }),
          ...(canUpdateVehicleTrailerId && { canUpdateVehicleTrailerId }),
          ...(canViewCollectionDetails && { canViewCollectionDetails }),
          ...(canViewDeliveryDetails && { canViewDeliveryDetails }),
          ...(collection && { collection }),
          ...(delivery && { delivery })
        };
      })
    : [];

  return {
    cargoList: cargoToTransition,
    // legacy cargo fields that will be tackled before we fully switch to core cargo
    ...(legacyCargo?.mode && { mode: legacyCargo.mode })
  };
};

const mapCargo = (cargoItem: CargoItem): CoreCargo => {
  const {
    cbm,
    chargeableWeight,
    containerNumber,
    containerSealNumber,
    dimensions,
    dryStandard,
    flatRack,
    garmentsOnHangers,
    grossWeight,
    hazardous,
    healthCertificate,
    id,
    looseCargoType,
    openTop,
    overweight,
    palletType,
    quantity,
    reefer,
    riskLevel,
    stackable,
    tailLift,
    trailerId,
    type,
    valueOfGoods
  } = cargoItem;

  const looseCargo: Optional<LooseCargoTypeEnum | PalletTypeEnum> =
    looseCargoType === LooseCargoTypeEnum.PALLETS && !!palletType ? cargoItem.palletType : cargoItem.looseCargoType;
  const cargoTypeMapping = {
    [CargoItemTypeEnum.CONTAINER]: cargoItem.containerType,
    [CargoItemTypeEnum.LOOSE_CARGO]: looseCargo,
    [CargoItemTypeEnum.VEHICLE]: cargoItem.vehicleType
  };

  const coreCargoGrossWeight: Nullable<CargoWeight> =
    grossWeight?.value && grossWeight?.metric
      ? {
          value: grossWeight?.value,
          unit: grossWeight?.metric
        }
      : null;

  const coreCargoDimensions: Nullable<Dimensions> =
    (dimensions?.width || dimensions?.height || dimensions?.length) && dimensions?.metric
      ? {
          width: dimensions?.width,
          height: dimensions?.height,
          length: dimensions?.length,
          unit: dimensions?.metric
        }
      : null;

  const coreCargoChargeableWeight: Nullable<CargoWeight> =
    chargeableWeight?.value && chargeableWeight?.metric
      ? {
          value: chargeableWeight?.value,
          unit: chargeableWeight?.metric
        }
      : null;

  const coreCargoValueOfGoods: Nullable<ValueOfGoods> =
    valueOfGoods?.value && valueOfGoods?.currency
      ? {
          value: valueOfGoods?.value,
          currency: valueOfGoods?.currency
        }
      : null;

  const coreCargoVolume: Nullable<Volume> = cbm
    ? {
        value: cbm,
        unit: 'CBM'
      }
    : null;

  const inputCargo: CoreCargoExtended = {
    cargoType: looseCargo ? legacyNewTypesMapping[looseCargo] : null,
    // in core cargo parent and child cargo will have different ids
    id,
    quantity
  };

  const isContainerOrVehicle: boolean = type === CargoItemTypeEnum.CONTAINER || type === CargoItemTypeEnum.VEHICLE;
  const canHaveChildCargo: boolean = isContainerOrVehicle && !!looseCargoType;

  return {
    cargoType: type && cargoTypeMapping[type] ? legacyNewTypesMapping[cargoTypeMapping[type]!] : null,
    chargeableWeight: coreCargoChargeableWeight,
    containerNumber,
    containerSealNumber,
    dimensions: coreCargoDimensions,
    dryStandard,
    flatRack,
    garmentsOnHangers,
    grossWeight: coreCargoGrossWeight,
    hazardous,
    healthCertificate,
    id,
    ...(canHaveChildCargo && { looseCargo: inputCargo }),
    openTop,
    overweight,
    quantity,
    refrigerated: reefer,
    riskLevel,
    stackable,
    tailLift,
    trailerId,
    valueOfGoods: coreCargoValueOfGoods,
    volume: coreCargoVolume
  };
};

export default transitionCargo;
