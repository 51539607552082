import type { AccountMembersFilterItem } from './types';

const buildFilters = (): AccountMembersFilterItem[] => {
  return [
    {
      title: 'Include archived',
      key: 'includeArchived',
      props: {
        options: [
          { value: true, label: 'Yes' },
          { value: false, label: 'No' }
        ]
      },
      componentType: 'select'
    }
  ];
};

export { buildFilters };
