import type { TableColumn } from '@zen/DesignSystem';
import {
  chargeTypeColumn,
  defaultChargeDetailsColumn,
  getActionsColumn,
  getCargoOptionsColumn,
  getChargeBasisColumn,
  getChargeNameColumn,
  getCurrencyColumn,
  getLocationColumn,
  getUnitPriceColumn
} from '@zen/RateCards/components/helpers';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import type { ChargeLocationType, ChargeTableColumnConfigWithTypeProps } from '../../components/types';

export const getPortChargeTableColumns = ({
  cargoType,
  modeOfTransport,
  actions,
  hasPublishError,
  isEditable = false,
  type
}: ChargeTableColumnConfigWithTypeProps): TableColumn<RateCardCharge>[] => {
  const { onUpdate } = actions;
  const locationKey: ChargeLocationType = type === 'origin' ? 'fromLocation' : 'toLocation';
  const unitPriceColumnTitle: string = isEditable ? 'Custom price' : 'Unit price';
  const currencyColumnTitle: string = isEditable ? 'Custom currency' : 'Currency';

  return [
    getLocationColumn(locationKey, `Port of ${type}`, { width: 600 }),
    ...(isEditable ? [chargeTypeColumn] : []),
    getChargeNameColumn(isEditable, onUpdate),
    getChargeBasisColumn({ cargoType, isEditable, modeOfTransport, onUpdate }),
    getCargoOptionsColumn({ cargoType, isEditable, modeOfTransport, onUpdate }),
    ...(isEditable ? [defaultChargeDetailsColumn] : []),
    getUnitPriceColumn({ isEditable, hasPublishError, onPriceUpdate: onUpdate, title: unitPriceColumnTitle }),
    getCurrencyColumn(isEditable, onUpdate, currencyColumnTitle),
    ...(isEditable ? [getActionsColumn(actions)] : [])
  ];
};
