import type { FC } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import InlineEditableField from '@zen/Components/InlineEditableField';
import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault/ValueWithDefault';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateJourneyMasterBillOfLadingMutation } from './graphql';

interface Props {
  canUpdateMasterBillOfLading: boolean;
  masterBillOfLading: string;
}

const MasterBillOfLadingInlineField: FC<Props> = ({ canUpdateMasterBillOfLading, masterBillOfLading }) => {
  const { addSuccess, addError } = useNotification();

  const { zencargoReference } = useCargoOverviewContext();

  const [updateMasterBillOfLading] = useUpdateJourneyMasterBillOfLadingMutation();

  const label: string = 'Master bill of lading';

  const handleChange = (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateMasterBillOfLading({
          variables: {
            input: {
              masterBillOfLading: value,
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess(`${label} updated.`);
      }
    });
  };

  if (!canUpdateMasterBillOfLading) {
    return (
      <LabelledValue label={label} labelPosition="left" variant="default">
        <ValueWithDefault value={masterBillOfLading} />
      </LabelledValue>
    );
  }

  return (
    <InlineEditableField
      label={label}
      labelPlacement="left"
      name="master-bill-of-lading"
      onUpdate={handleChange}
      placeholder="Enter master bill of lading"
      value={masterBillOfLading}
    />
  );
};

export default MasterBillOfLadingInlineField;
