import type { FC } from 'react';

import useAccount from '@zen/utils/hooks/useAccount';

import { isSubscriberType } from '../subscriptions.helpers';
import type { SubscriptionStakeholder } from '../types';

interface Props {
  showCompanyName?: boolean;
  stakeholder: SubscriptionStakeholder;
}

const StakeholderDetails: FC<Props> = ({ showCompanyName = true, stakeholder }) => {
  const { userProfile } = useAccount();

  const { id, firstName, lastName, organisation } = stakeholder.accountMember
    ? {
        id: stakeholder.accountMember.id,
        firstName: stakeholder.accountMember.firstName,
        lastName: stakeholder.accountMember.lastName,
        organisation: { name: stakeholder.accountMember.account.tradingName }
      }
    : {
        id: stakeholder.contactDetails?.id ?? 'ID',
        firstName: stakeholder.contactDetails?.firstName ?? 'Default First Name',
        lastName: stakeholder.contactDetails?.lastName ?? 'Default Last Name',
        organisation: { name: stakeholder.contactDetails?.organisation?.name ?? 'Default Organisation' }
      };
  const isCurrentUser: boolean = userProfile.uuid === id;

  let canSeeOriginalCompanyName: boolean = true;

  if (isSubscriberType(stakeholder) && !isCurrentUser) {
    canSeeOriginalCompanyName = stakeholder.canSeeOriginalCompanyName.value;
  }

  const companyName: string = canSeeOriginalCompanyName ? organisation?.name : 'Zencargo';
  const displayName: string = isCurrentUser ? 'Me' : `${firstName} ${lastName.charAt(0)}.`;

  return (
    <div className="leading-normal truncate" data-testid="stakeholder-details">
      <b>{displayName}</b>
      {showCompanyName && (
        <>
          {' '}
          from <span className="text-azure-base">{companyName}</span>
        </>
      )}
    </div>
  );
};

export default StakeholderDetails;
