import type { FC } from 'react';
import { useEffect } from 'react';

import type { Option } from '@zen/DesignSystem';
import type { Nullable, Optional } from '@zen/utils/typescript';

import RateCardMultiSelect, { type RateCardMultiSelectProps } from '../RateCardMultiSelect';
import { useGetCargoOptionsQuery } from './graphql';
import { getOptions } from './helpers';
import type { CargoModeEnum, CargoOption, CargoOptionEnum, ModeOfTransport } from './types';
import { ChargeBasisGroup } from './types';

interface Props extends Omit<RateCardMultiSelectProps<CargoOptionEnum>, 'children' | 'onChange' | 'options'> {
  cargoType: CargoModeEnum;
  chargeBasisGroup?: Optional<ChargeBasisGroup>;
  modeOfTransport: ModeOfTransport;
  onChange: (values: Nullable<CargoOptionEnum[]>) => void;
}

const CargoOptionsSelect: FC<Props> = (props) => {
  const { cargoType, chargeBasisGroup, isDisabled, isEditable, modeOfTransport, onChange, value, variant } = props;

  const { data, loading } = useGetCargoOptionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      cargoType,
      modeOfTransport
    }
  });

  const cargoOptionsList = (data?.getCargoOptions || []) as CargoOption[];
  const optionsList: Option<CargoOptionEnum>[] = getOptions(cargoOptionsList);

  useEffect(() => {
    const shouldClearCargoOptions: boolean =
      chargeBasisGroup === ChargeBasisGroup.BILL || chargeBasisGroup === ChargeBasisGroup.BOOKING;
    const shouldPrefillWithAllValues: boolean = !isDisabled && variant !== 'inline';

    if (shouldClearCargoOptions) {
      onChange([]);
    }

    if (shouldPrefillWithAllValues) {
      onChange(optionsList.map((optionList: Option<CargoOptionEnum>) => optionList.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chargeBasisGroup]);

  return (
    <RateCardMultiSelect
      isDisabled={isDisabled}
      isEditable={isEditable}
      loading={loading}
      onChange={onChange}
      options={optionsList}
      selectAllLabel="Any option"
      value={value || null}
      variant={variant}
    />
  );
};

export default CargoOptionsSelect;
