import type { ReactNode } from 'react';

import ValueWithDefault from '@zen/Components/ValueWithDefault';
import { IconButton, type TableColumn } from '@zen/DesignSystem';

import type { OriginAgentAssignmentRule } from '../types';

export const getColumnsConfiguration = (
  onDelete: (agentAssignmentId: string) => void,
  onEdit: (agentAssignmentId: string) => void
): TableColumn<OriginAgentAssignmentRule>[] => {
  return [
    {
      key: 'account',
      fixed: 'left',
      title: 'Account',
      render: (_, { account }): ReactNode => {
        return <span>{account.name}</span>;
      },
      sortable: true
    },
    {
      key: 'modeOfTransport',
      title: 'Mode of transport',
      render: (_, { modeOfTransport }): ReactNode => <span>{modeOfTransport}</span>,
      sortable: true
    },
    {
      key: 'portOfLoad',
      title: 'Port of loading',
      render: (_, { portOfLoad }): ReactNode => {
        return <ValueWithDefault value={portOfLoad?.label} />;
      },
      sortable: false
    },
    {
      key: 'portOfDestination',
      title: 'Port of destination',
      render: (_, { portOfDestination }): ReactNode => {
        return <ValueWithDefault value={portOfDestination?.label} />;
      },
      sortable: false
    },
    {
      key: 'collectionWarehouse',
      title: 'Collection warehouse',
      render: (_, { collectionWarehouse }): ReactNode => {
        return <ValueWithDefault value={collectionWarehouse?.label?.long} />;
      },
      sortable: false
    },
    {
      key: 'deliveryWarehouse',
      title: 'Delivery warehouse',
      render: (_, { deliveryWarehouse }): ReactNode => {
        return <ValueWithDefault value={deliveryWarehouse?.label?.long} />;
      },
      sortable: false
    },
    {
      key: 'originAgent',
      title: 'Origin agent',
      render: (_, { originAgent }): ReactNode => {
        return <ValueWithDefault value={originAgent?.label?.long} />;
      },
      sortable: false
    },
    {
      key: 'actions',
      fixed: 'right',
      render: (_, { id }): ReactNode => {
        return (
          <div className="flex space-x-2">
            <IconButton icon="zicon-edit" onClick={() => onEdit(id)} size="medium" title="Edit" variant="ghost" />
            <IconButton icon="zicon-trash" onClick={() => onDelete(id)} size="medium" title="Delete" variant="ghost" />
          </div>
        );
      },
      sortable: false
    }
  ];
};
