import type { FC } from 'react';

import { SkeletonLoading } from '@zen/DesignSystem';

const ActivityFeedLoadingSkeleton: FC = () => {
  return (
    <div className="border border-solid rounded border-grey-lighter" data-testid="activity-feed-loading-skeleton">
      <div className="flex justify-between p-3 border-b border-solid border-grey-lighter">
        <SkeletonLoading height={24} width="w-32" />
        <div className="flex">
          <SkeletonLoading height={24} width="w-12" />
          <SkeletonLoading height={24} width="w-6" />
        </div>
      </div>
      <div className="flex flex-col p-3">
        {[...Array(4)].map((_, i) => (
          <div key={i}>
            <div className="flex flex-col mb-3">
              <SkeletonLoading className="mb-1" height={16} width="w-40" />
              <SkeletonLoading height={16} width="w-24" />
            </div>
            <div className="flex flex-col items-end mb-3">
              <SkeletonLoading className="mb-1" height={16} width="w-40" />
              <SkeletonLoading height={16} width="w-24" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ActivityFeedLoadingSkeleton;
