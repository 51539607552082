import type { FC } from 'react';

import { TableConfigurationProvider } from '@zen/DesignSystem';

import AllShipments, { CUSTOMER_ADMIN_SHIPMENTS_TABLE_ID } from './AllShipments';

const AllShipmentsContainer: FC = () => {
  return (
    <TableConfigurationProvider tableId={CUSTOMER_ADMIN_SHIPMENTS_TABLE_ID}>
      <AllShipments />
    </TableConfigurationProvider>
  );
};

export default AllShipmentsContainer;
