import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type BookingDetailsQueryVariables = GraphQLTypes.Exact<{
  zencargoReference: GraphQLTypes.Scalars['String'];
}>;

export type BookingDetailsQueryResult = { __typename?: 'Query' } & {
  bookings: { __typename?: 'BookingConnection' } & {
    nodes?: GraphQLTypes.Maybe<
      Array<
        GraphQLTypes.Maybe<
          { __typename?: 'Booking' } & Pick<
            GraphQLTypes.Booking,
            | 'accountUuid'
            | 'clientReference'
            | 'customsOnly'
            | 'modeOfTransport'
            | 'pinned'
            | 'quoteRequired'
            | 'zencargoReference'
          > & {
              archivedAt?: GraphQLTypes.Maybe<{ __typename?: 'RichDateTime' } & Pick<GraphQLTypes.RichDateTime, 'date'>>;
              bookingRequest?: GraphQLTypes.Maybe<
                { __typename?: 'BookingRequest' } & Pick<
                  GraphQLTypes.BookingRequest,
                  | 'customerApproved'
                  | 'forwarderApproved'
                  | 'forwarderApprovalRequired'
                  | 'id'
                  | 'rejected'
                  | 'rejectedAdditionalInfo'
                  | 'rejectedReason'
                > & {
                    canReject: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                    canUpdateCustomerApproved: { __typename?: 'AuthorizationResult' } & Pick<
                      GraphQLTypes.AuthorizationResult,
                      'value'
                    >;
                    canUpdateForwarderApproved: { __typename?: 'AuthorizationResult' } & Pick<
                      GraphQLTypes.AuthorizationResult,
                      'value'
                    >;
                    canRevertRejection: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
                    canRevertRejectionAsManufacturer: { __typename?: 'AuthorizationResult' } & Pick<
                      GraphQLTypes.AuthorizationResult,
                      'value'
                    >;
                  }
              >;
              bookingStage?: GraphQLTypes.Maybe<{ __typename?: 'BookingStage' } & Pick<GraphQLTypes.BookingStage, 'name'>>;
              canArchiveShipment: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canClone: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canPin: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              canViewNotes: { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>;
              consolidatedShipment?: GraphQLTypes.Maybe<
                { __typename?: 'ConsolidatedShipment' } & Pick<GraphQLTypes.ConsolidatedShipment, 'id' | 'reference'>
              >;
              oceanTracking?: GraphQLTypes.Maybe<
                { __typename?: 'OceanTracking' } & Pick<GraphQLTypes.OceanTracking, 'canToggleTracking' | 'status'>
              >;
              permissions?: GraphQLTypes.Maybe<
                { __typename?: 'BookingPermissions' } & {
                  canApproveOrReject?: GraphQLTypes.Maybe<
                    { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                  >;
                  canUpdateClientReference?: GraphQLTypes.Maybe<
                    { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                  >;
                  canViewQuote?: GraphQLTypes.Maybe<
                    { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                  >;
                  isCustomerOnBooking?: GraphQLTypes.Maybe<
                    { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                  >;
                  isSupplierOnBooking?: GraphQLTypes.Maybe<
                    { __typename?: 'AuthorizationResult' } & Pick<GraphQLTypes.AuthorizationResult, 'value'>
                  >;
                }
              >;
              roadTracking?: GraphQLTypes.Maybe<
                { __typename?: 'RoadTrackingDetails' } & {
                  trackingInput?: GraphQLTypes.Maybe<
                    { __typename?: 'RoadTrackingInput' } & {
                      trackingSubscription?: GraphQLTypes.Maybe<
                        { __typename?: 'RoadTrackingSubscription' } & Pick<GraphQLTypes.RoadTrackingSubscription, 'status'>
                      >;
                    }
                  >;
                }
              >;
            }
        >
      >
    >;
  };
};

export const BookingDetailsDocument = /* #__PURE__ */ gql`
  query bookingDetails($zencargoReference: String!) {
    bookings(zencargoReferences: [$zencargoReference]) {
      nodes {
        accountUuid
        archivedAt {
          date
        }
        bookingRequest {
          canReject {
            value
          }
          canUpdateCustomerApproved {
            value
          }
          canUpdateForwarderApproved {
            value
          }
          canRevertRejection {
            value
          }
          canRevertRejectionAsManufacturer {
            value
          }
          customerApproved
          forwarderApproved
          forwarderApprovalRequired
          id
          rejected
          rejectedAdditionalInfo
          rejectedReason
        }
        bookingStage {
          name
        }
        canArchiveShipment {
          value
        }
        canClone {
          value
        }
        canPin {
          value
        }
        canViewNotes {
          value
        }
        clientReference
        consolidatedShipment {
          id
          reference
        }
        customsOnly
        modeOfTransport
        oceanTracking {
          canToggleTracking
          status
        }
        permissions {
          canApproveOrReject {
            value
          }
          canUpdateClientReference {
            value
          }
          canViewQuote {
            value
          }
          isCustomerOnBooking {
            value
          }
          isSupplierOnBooking {
            value
          }
        }
        pinned
        roadTracking {
          trackingInput {
            trackingSubscription {
              status
            }
          }
        }
        quoteRequired
        zencargoReference
      }
    }
  }
`;

/**
 * __useBookingDetailsQuery__
 *
 * To run a query within a React component, call `useBookingDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingDetailsQuery({
 *   variables: {
 *      zencargoReference: // value for 'zencargoReference'
 *   },
 * });
 */
export function useBookingDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<BookingDetailsQueryResult, BookingDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<BookingDetailsQueryResult, BookingDetailsQueryVariables>(BookingDetailsDocument, options);
}
export function useBookingDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<BookingDetailsQueryResult, BookingDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<BookingDetailsQueryResult, BookingDetailsQueryVariables>(BookingDetailsDocument, options);
}
export type BookingDetailsQueryHookResult = ReturnType<typeof useBookingDetailsQuery>;
export type BookingDetailsLazyQueryHookResult = ReturnType<typeof useBookingDetailsLazyQuery>;
