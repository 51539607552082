import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type RateCardDetailsQueryVariables = GraphQLTypes.Exact<{
  id: GraphQLTypes.Scalars['String'];
  locationsFilter?: GraphQLTypes.InputMaybe<GraphQLTypes.RateCardLocationsFilterInput>;
}>;

export type RateCardDetailsQueryResult = { __typename?: 'Query' } & {
  getRateCard?: GraphQLTypes.Maybe<
    { __typename?: 'RateCardPayload' } & {
      rateCard: { __typename?: 'RateCard' } & Pick<
        GraphQLTypes.RateCard,
        'cargoType' | 'customerId' | 'endDate' | 'issuedAt' | 'modeOfTransport' | 'name' | 'note' | 'rateCardId' | 'startDate'
      > & {
          charges: Array<
            { __typename?: 'RateCardCharge' } & Pick<
              GraphQLTypes.RateCardCharge,
              | 'applicability'
              | 'cargoOptions'
              | 'chargeClassification'
              | 'currency'
              | 'defaultChargeHidden'
              | 'id'
              | 'itemName'
              | 'unitPrice'
            > & {
                chargeBasis: Array<{ __typename?: 'ChargeBasis' } & Pick<GraphQLTypes.ChargeBasis, 'id' | 'name'>>;
                chargeType: { __typename?: 'ChargesPayload' } & Pick<
                  GraphQLTypes.ChargesPayload,
                  'applicability' | 'basisGroup' | 'id' | 'name'
                > & { group: { __typename?: 'ChargeGroup' } & Pick<GraphQLTypes.ChargeGroup, 'id' | 'name'> };
                defaultCharge?: GraphQLTypes.Maybe<
                  { __typename?: 'DefaultChargesPayload' } & Pick<
                    GraphQLTypes.DefaultChargesPayload,
                    'currency' | 'id' | 'itemName' | 'unitPrice'
                  > & {
                      chargeType: { __typename?: 'ChargesPayload' } & Pick<
                        GraphQLTypes.ChargesPayload,
                        'applicability' | 'basisGroup' | 'id' | 'name'
                      > & { group: { __typename?: 'ChargeGroup' } & Pick<GraphQLTypes.ChargeGroup, 'id' | 'name'> };
                      location:
                        | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                              >;
                            })
                        | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                              label?: GraphQLTypes.Maybe<
                                { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                              >;
                            });
                    }
                >;
                fromLocation?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                >;
                toLocation?: GraphQLTypes.Maybe<
                  | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                  | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'> & {
                        label?: GraphQLTypes.Maybe<
                          { __typename?: 'NetworksLabelType' } & Pick<GraphQLTypes.NetworksLabelType, 'long' | 'short'>
                        >;
                      })
                >;
              }
          >;
          issuedBy?: GraphQLTypes.Maybe<
            { __typename?: 'NetworksContact' } & Pick<GraphQLTypes.NetworksContact, 'firstName' | 'id' | 'lastName'>
          >;
        };
      locationsAssigned: { __typename?: 'LocationsAssignedToRateCard' } & {
        originLocations: Array<
          | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'>)
          | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'>)
        >;
        originPorts: Array<
          | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'>)
          | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'>)
        >;
        destinationLocations: Array<
          | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'>)
          | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'>)
        >;
        destinationPorts: Array<
          | ({ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'id'>)
          | ({ __typename?: 'NetworksTerminal' } & Pick<GraphQLTypes.NetworksTerminal, 'id'>)
        >;
      };
    }
  >;
};

export const RateCardDetailsDocument = /* #__PURE__ */ gql`
  query rateCardDetails($id: String!, $locationsFilter: RateCardLocationsFilterInput) {
    getRateCard(id: $id, locationsFilter: $locationsFilter) {
      rateCard {
        cargoType
        charges {
          applicability
          cargoOptions
          chargeClassification
          chargeBasis {
            id
            name
          }
          chargeType {
            applicability
            basisGroup
            group {
              id
              name
            }
            id
            name
          }
          currency
          defaultCharge {
            chargeType {
              applicability
              basisGroup
              group {
                id
                name
              }
              id
              name
            }
            currency
            id
            itemName
            location {
              id
              label {
                long
                short
              }
            }
            unitPrice
          }
          defaultChargeHidden
          fromLocation {
            id
            label {
              long
              short
            }
          }
          id
          toLocation {
            id
            label {
              long
              short
            }
          }
          itemName
          unitPrice
        }
        customerId
        endDate
        issuedAt
        issuedBy {
          firstName
          id
          lastName
        }
        modeOfTransport
        name
        note
        rateCardId
        startDate
      }
      locationsAssigned {
        originLocations {
          id
        }
        originPorts {
          id
        }
        destinationLocations {
          id
        }
        destinationPorts {
          id
        }
      }
    }
  }
`;

/**
 * __useRateCardDetailsQuery__
 *
 * To run a query within a React component, call `useRateCardDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRateCardDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRateCardDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locationsFilter: // value for 'locationsFilter'
 *   },
 * });
 */
export function useRateCardDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<RateCardDetailsQueryResult, RateCardDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<RateCardDetailsQueryResult, RateCardDetailsQueryVariables>(RateCardDetailsDocument, options);
}
export function useRateCardDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RateCardDetailsQueryResult, RateCardDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<RateCardDetailsQueryResult, RateCardDetailsQueryVariables>(RateCardDetailsDocument, options);
}
export type RateCardDetailsQueryHookResult = ReturnType<typeof useRateCardDetailsQuery>;
export type RateCardDetailsLazyQueryHookResult = ReturnType<typeof useRateCardDetailsLazyQuery>;
