import cx from 'classnames';
import type { FC } from 'react';

import { Role } from '@zen/Auth';
import useRole from '@zen/Auth/hooks/useRole';
import InlineEditableField from '@zen/Components/InlineEditableField';
import type { BookingPermissions } from '@zen/graphql/types.generated';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Optional } from '@zen/utils/typescript';

import { useUpdateClientReferenceMutation } from './graphql';

interface Props {
  className?: string;
  clientReference: Optional<string>;
  permissions?: Nullable<BookingPermissions>;
  zencargoReference: string;
}

const ClientReferenceContainer: FC<Props> = ({ className, clientReference, permissions, zencargoReference }) => {
  const { addError, addSuccess } = useNotification();
  const currentUserRole = useRole();
  const isManufacturer: boolean = currentUserRole === Role.MANUFACTURER || !!permissions?.isSupplierOnBooking?.value;

  const classNames: string = cx('flex flex-col', className);

  const canUpdateClientReference = !!permissions?.canUpdateClientReference?.value || currentUserRole === Role.AGENT_FORWARDER;

  const [updateClientReference] = useUpdateClientReferenceMutation();

  const handleUpdateClientReference = (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateClientReference({
          variables: {
            input: {
              zencargoReference,
              clientReference: value
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => addSuccess('Client reference updated.')
    });
  };

  return (
    <div className={classNames}>
      <InlineEditableField
        disabled={isManufacturer || !canUpdateClientReference}
        name="client-reference-container"
        onUpdate={handleUpdateClientReference}
        placeholder="Enter client reference"
        value={clientReference || ''}
      />
    </div>
  );
};

export default ClientReferenceContainer;
