import type { NoResultsButtonConfig } from '@zen/DesignSystem';
import { taskListRoutes } from '@zen/Routes';
import type { TaskStatus } from '@zen/Tasks/types';

export type NoTasksDisplayMessages = {
  button?: NoResultsButtonConfig;
  headline: string;
  tagline: string;
};

export const getNoResultsMessage = (taskStatus?: TaskStatus): NoTasksDisplayMessages => {
  const noActiveTasks: NoTasksDisplayMessages = {
    button: { label: 'Your tasks', linkTo: taskListRoutes.taskListYourTasks.getUrl() },
    headline: 'There are no open tasks for this assignee',
    tagline: 'Check back later or go to your other tasks.'
  };

  const noCompletedTasks: NoTasksDisplayMessages = {
    headline: 'There are no completed tasks by this assignee',
    tagline: 'Completed tasks will appear here.'
  };

  if (taskStatus === 'active') {
    return noActiveTasks;
  }

  return noCompletedTasks;
};
