import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useMeasure, useWindowSize } from 'react-use';

import { getTabLabel } from '@zen/Booking/BookingDetails/CargoOverview/helpers';
import type { CoreCargo } from '@zen/Cargo';
import { coreCargoTypeIconMapping } from '@zen/Cargo';
import { Button, CollapsibleElement } from '@zen/DesignSystem';
import JourneyContentSwitcher from '@zen/Journey/components/JourneyContentSwitcher';
import type { IconName } from '@zen/Styleguide';
import { Breakpoint } from '@zen/types';
import type { Optional } from '@zen/utils/typescript';

interface Props {
  cargos: Optional<CoreCargo[]>;
  onCargoButtonClick: (cargoId: string) => void;
}

const SummaryMilestoneCargos: FC<Props> = ({ cargos, onCargoButtonClick }) => {
  const { width } = useWindowSize();
  const [wrapperRef, { height }] = useMeasure<HTMLDivElement>();
  const [showMoreCargoItems, setShowMoreCargoItems] = useState<boolean>(false);

  const renderCargoItems = (): ReactNode => {
    const oneLineHeight: number = width >= Breakpoint.XL ? 36 : 28;

    const cargoList: ReactNode = (
      <div ref={wrapperRef} className="flex flex-wrap gap-2">
        {cargos?.map((cargo: CoreCargo): ReactNode => {
          const { cargoType, id } = cargo;
          const cargoIcon: Optional<IconName> = (cargoType && coreCargoTypeIconMapping[cargoType]) || undefined;

          return (
            <Button key={id} iconLeft={cargoIcon} onClick={() => onCargoButtonClick(id)} size="compact" variant="secondary">
              {getTabLabel(cargo)}
            </Button>
          );
        })}
      </div>
    );
    const cargoItemsTwoRowsHeight: number = oneLineHeight * 2;

    if (height > cargoItemsTwoRowsHeight) {
      return (
        <>
          <CollapsibleElement collapsedHeight={cargoItemsTwoRowsHeight} isOpened={showMoreCargoItems}>
            {cargoList}
          </CollapsibleElement>
          <JourneyContentSwitcher
            className="mt-2"
            expandedContentPosition="above"
            iconPosition="right"
            isVisible={showMoreCargoItems}
            label={showMoreCargoItems ? 'Hide cargo items' : 'Show more cargo items'}
            onChange={() => setShowMoreCargoItems(!showMoreCargoItems)}
            renderedInJourney={false}
          />
        </>
      );
    }

    return cargoList;
  };

  return <div>{renderCargoItems()}</div>;
};

export default SummaryMilestoneCargos;
