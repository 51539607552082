import type { RateCardCharge, RateCardReducerState } from './reducer';

const hasRateCardChargeUnitPrice = (charge: RateCardCharge): boolean => {
  const { defaultCharge, unitPrice } = charge;

  return !defaultCharge && unitPrice === null;
};

export const hasPublishRateCardError = (values: RateCardReducerState): boolean => {
  return (
    values.freightCharges.some(hasRateCardChargeUnitPrice) ||
    values.originHaulageCharges.some(hasRateCardChargeUnitPrice) ||
    values.originCharges.some(hasRateCardChargeUnitPrice) ||
    values.destinationCharges.some(hasRateCardChargeUnitPrice) ||
    values.destinationHaulageCharges.some(hasRateCardChargeUnitPrice) ||
    values.otherCharges.some(hasRateCardChargeUnitPrice)
  );
};
