import { createContext } from 'react';

import useContextOrThrowError from '@zen/utils/hooks/useContextOrThrowError';

import { CargoModeEnum, type ContextType, ModeOfTransport } from './types';

const initialContext: ContextType = {
  cargoType: CargoModeEnum.FCL,
  hasFreightCharges: false,
  hasPublishError: false,
  isEditable: false,
  modeOfTransport: ModeOfTransport.OCEAN
};

const RateCardContext = createContext<ContextType>(initialContext);

const useRateCardContext = (): ContextType => {
  return useContextOrThrowError<ContextType>(RateCardContext, 'useRateCardContext must be used within RateCardContext');
};

export { RateCardContext, useRateCardContext };
