import type { FC } from 'react';
import { Route, Routes } from 'react-router-dom';

import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import { ModeOfTransport } from '@zen/Booking';
import { CargoModeEnum } from '@zen/Cargo';
import AccessDenied from '@zen/Components/AccessDenied';
import { useFeatureIsOn } from '@zen/FeatureFlag';
import { rateCardRoutes } from '@zen/Routes';
import { FiltersContextProvider } from '@zen/utils/Filtering/contexts/FiltersContext';

import type { RateCardsFilters } from './LegacyRateCards/types';
import { getRateCardsComponentsConfig } from './routes.helper';
import type { RateCardsConfig } from './types';

export const initialFilters: RateCardsFilters = {
  modeOfTransport: ModeOfTransport.OCEAN,
  cargoType: CargoModeEnum.FCL,
  date: undefined
};

const RateCardsContainer: FC = () => {
  const { check } = useGlobalPermissions();
  const canViewRateCards: boolean = check('accounts.canViewRateCards');
  const rateCardsFlagIsOn = useFeatureIsOn('rate-cards');
  const legacyRateCardsFLagIsOn = useFeatureIsOn('legacy-rate-cards');
  const shouldShowNewRateCards: boolean = rateCardsFlagIsOn && canViewRateCards;
  const shouldRenderRateCards: boolean = legacyRateCardsFLagIsOn || shouldShowNewRateCards;

  if (!shouldRenderRateCards) {
    return <AccessDenied />;
  }
  const { create, details, edit, index }: RateCardsConfig = getRateCardsComponentsConfig(shouldShowNewRateCards);

  return (
    <FiltersContextProvider filterName="rateCardsFilters" initialFilters={initialFilters}>
      <Routes>
        <Route element={details} path={rateCardRoutes.details.relativePath} />
        <Route element={edit} path={rateCardRoutes.edit.relativePath} />
        <Route element={create} path={rateCardRoutes.new.relativePath} />
        <Route element={index} index={true} />
      </Routes>
    </FiltersContextProvider>
  );
};

export default RateCardsContainer;
