import type { Dispatch } from 'react';

import type { DateRange } from '@zen/DesignSystem';
import { getToday } from '@zen/utils/date';
import { add, compareDate } from '@zen/utils/dateTime';
import type { Nullable, Undefinable } from '@zen/utils/typescript';

import { formatRateCardData } from '../RateCardDetails/helpers';
import type { FormattedRateCardDetails } from '../RateCardDetails/types';
import type { Action, RateCardCharge, RateCardInputUpdatePayload, RateCardReducerState } from '../reducer';
import { ActionType } from '../reducer';
import { CargoModeEnum, ModeOfTransport, type RateCardDetails } from '../types';
import type {
  ChargeBasis,
  CreateRateCardChargeInput,
  DefaultChargeFilter,
  DefaultChargeFilters,
  UpdateRateCardInput
} from './types';
import { Applicability } from './types';

export const checkRelevantCharges = (freightCharges: RateCardCharge[], applicability: Applicability): boolean => {
  return freightCharges.some((freightCharge: RateCardCharge) => freightCharge.applicability.includes(applicability));
};

export const getInitialValues = (
  customerId: string,
  issuedBy: string,
  rateCard?: Nullable<RateCardDetails>
): RateCardReducerState => {
  const initialValues: Undefinable<FormattedRateCardDetails> = rateCard ? formatRateCardData(rateCard, true) : undefined;

  return {
    cargoType: initialValues?.cargoType || CargoModeEnum.FCL,
    createdBy: '',
    customerId,
    destinationCharges: initialValues?.destinationCharges || [],
    destinationHaulageCharges: initialValues?.destinationHaulageCharges || [],
    endDate: initialValues?.endDate || '',
    freightCharges: initialValues?.freightCharges || [],
    issuedBy,
    modeOfTransport: initialValues?.modeOfTransport || ModeOfTransport.OCEAN,
    name: initialValues?.name || '',
    note: initialValues?.note || '',
    originCharges: initialValues?.originCharges || [],
    originHaulageCharges: initialValues?.originHaulageCharges || [],
    otherCharges: initialValues?.otherCharges || [],
    rateCardId: initialValues?.rateCardId || '',
    startDate: initialValues?.startDate || ''
  };
};

const createRateCardName = (
  { modeOfTransport, cargoType, endDate, startDate }: RateCardReducerState,
  accountName: string
): string => {
  return `${accountName}-${modeOfTransport}-${cargoType}-${startDate}-${endDate}`;
};

const prepareRateCardChargeInput = (rateCardCharge: RateCardCharge): CreateRateCardChargeInput => {
  const {
    applicability,
    cargoOptions,
    chargeBasis,
    chargeType,
    currency,
    defaultCharge,
    defaultChargeHidden,
    fromLocation,
    itemName,
    toLocation,
    unitPrice
  } = rateCardCharge;
  const hasDefaultCharge: boolean = !!defaultCharge;

  return {
    applicability,
    basis: chargeBasis?.map((chargeBasisItem: ChargeBasis) => chargeBasisItem.id),
    cargoOptions: cargoOptions || [],
    chargeTypeId: chargeType?.id || '',
    ...(hasDefaultCharge ? { defaultChargeId: defaultCharge?.id || '' } : {}),
    ...(hasDefaultCharge ? { defaultChargeHidden: !!defaultChargeHidden } : {}),
    itemName,
    fromLocationId: fromLocation?.id,
    currency: currency || defaultCharge?.currency,
    toLocationId: toLocation?.id,
    unitPrice: unitPrice || defaultCharge?.unitPrice || 0
  };
};

export const prepareRateCardInput = (
  state: RateCardReducerState,
  accountName: string
): Omit<UpdateRateCardInput, 'rateCardId' | 'updatedBy'> => {
  const {
    customerId,
    destinationCharges,
    destinationHaulageCharges,
    endDate,
    freightCharges,
    issuedBy,
    note,
    otherCharges,
    originCharges,
    originHaulageCharges,
    startDate
  } = state;
  const charges: CreateRateCardChargeInput[] = [];

  [destinationCharges, destinationHaulageCharges, freightCharges, otherCharges, originCharges, originHaulageCharges].forEach(
    (rateCardCharges: RateCardCharge[]) => {
      charges.push(...rateCardCharges.map(prepareRateCardChargeInput));
    }
  );

  const rateCardName: string = createRateCardName(state, accountName);

  return {
    charges,
    customerId,
    endDate,
    issuedBy,
    name: rateCardName,
    note,
    startDate
  };
};

export const getRateCardsActions = (dispatch: Dispatch<Action>) => {
  const handleAddCustomDestinationCharge = (values: RateCardCharge[]): void => {
    dispatch({ type: ActionType.ADD_CUSTOM_DESTINATION_CHARGE, payload: values });
  };

  const handleAddCustomOriginCharge = (values: RateCardCharge[]): void => {
    dispatch({ type: ActionType.ADD_CUSTOM_ORIGIN_CHARGE, payload: values });
  };

  const handleAddDestinationCharges = (values: RateCardCharge[]): void => {
    dispatch({ type: ActionType.ADD_DESTINATION_CHARGES, payload: values });
  };

  const handleAddDestinationHaulageCharge = (values: RateCardCharge[]): void => {
    dispatch({ type: ActionType.ADD_DESTINATION_HAULAGE_CHARGE, payload: values });
  };

  const handleAddFreightCharge = (values: RateCardCharge[]): void => {
    dispatch({ type: ActionType.ADD_FREIGHT_CHARGE, payload: values });
  };

  const handleAddOriginCharges = (values: RateCardCharge[]): void => {
    dispatch({ type: ActionType.ADD_ORIGIN_CHARGES, payload: values });
  };

  const handleAddOriginHaulageCharge = (values: RateCardCharge[]): void => {
    dispatch({ type: ActionType.ADD_ORIGIN_HAULAGE_CHARGE, payload: values });
  };

  const handleAddOtherCharge = (values: RateCardCharge[]): void => {
    dispatch({ type: ActionType.ADD_OTHER_CHARGE, payload: values });
  };

  const handleDeleteCustomDestinationCharge = (index: number) => {
    dispatch({ type: ActionType.DELETE_CUSTOM_DESTINATION_CHARGE, payload: { atIndex: index } });
  };

  const handleDeleteCustomOriginCharge = (index: number): void => {
    dispatch({ type: ActionType.DELETE_CUSTOM_ORIGIN_CHARGE, payload: { atIndex: index } });
  };

  const handleDeleteDestinationHaulageCharge = (index: number): void => {
    dispatch({ type: ActionType.DELETE_DESTINATION_HAULAGE_CHARGE, payload: { atIndex: index } });
  };

  const handleDeleteFreightCharge = (index: number): void => {
    dispatch({ type: ActionType.DELETE_FREIGHT_CHARGE, payload: { atIndex: index } });
  };

  const handleDeleteOriginHaulageCharge = (index: number): void => {
    dispatch({ type: ActionType.DELETE_ORIGIN_HAULAGE_CHARGE, payload: { atIndex: index } });
  };

  const handleDeleteOtherCharge = (index: number): void => {
    dispatch({ type: ActionType.DELETE_OTHER_CHARGE, payload: { atIndex: index } });
  };

  const disableOriginCharge = (id: string): void => {
    dispatch({ type: ActionType.DISABLE_ORIGIN_CHARGE, payload: { id } });
  };

  const enableOriginCharge = (id: string) => {
    dispatch({ type: ActionType.ENABLE_ORIGIN_CHARGE, payload: { id } });
  };

  const handleVisibilityChangeOriginCharge = (id: string, disable: boolean) => {
    return disable ? disableOriginCharge(id) : enableOriginCharge(id);
  };

  const disableDestinationCharge = (id: string): void => {
    dispatch({ type: ActionType.DISABLE_DESTINATION_CHARGE, payload: { id } });
  };

  const enableDestinationCharge = (id: string): void => {
    dispatch({ type: ActionType.ENABLE_DESTINATION_CHARGE, payload: { id } });
  };

  const handleVisibilityChangeDestinationCharge = (id: string, disable: boolean) => {
    return disable ? disableDestinationCharge(id) : enableDestinationCharge(id);
  };

  const handleOverviewChange = (payload: RateCardInputUpdatePayload): void => {
    dispatch({ type: ActionType.UPDATE_RATE_CARD_INPUT, payload });
  };

  const handleUpdateDestinationCharge = (index: number, value: Partial<RateCardCharge>): void => {
    dispatch({ type: ActionType.UPDATE_DESTINATION_CHARGE, payload: { atIndex: index, value } });
  };

  const handleUpdateDestinationHaulageCharge = (index: number, value: Partial<RateCardCharge>): void => {
    dispatch({ type: ActionType.UPDATE_DESTINATION_HAULAGE_CHARGE, payload: { atIndex: index, value } });
  };

  const handleUpdateFreightCharge = (index: number, value: Partial<RateCardCharge>): void => {
    dispatch({ type: ActionType.UPDATE_FREIGHT_CHARGE, payload: { atIndex: index, value } });
  };

  const handleUpdateOriginCharge = (index: number, value: Partial<RateCardCharge>): void => {
    dispatch({ type: ActionType.UPDATE_ORIGIN_CHARGE, payload: { atIndex: index, value } });
  };

  const handleUpdateOriginHaulageCharge = (index: number, value: Partial<RateCardCharge>): void => {
    dispatch({ type: ActionType.UPDATE_ORIGIN_HAULAGE_CHARGE, payload: { atIndex: index, value } });
  };

  const handleUpdateOtherCharge = (index: number, value: Partial<RateCardCharge>): void => {
    dispatch({ type: ActionType.UPDATE_OTHER_CHARGE, payload: { atIndex: index, value } });
  };

  const handleNoteChange = (value: string): void => {
    dispatch({ type: ActionType.UPDATE_RATE_CARD_INPUT, payload: { note: value } });
  };

  return {
    handleAddCustomDestinationCharge,
    handleAddCustomOriginCharge,
    handleAddDestinationCharges,
    handleAddDestinationHaulageCharge,
    handleAddFreightCharge,
    handleAddOriginCharges,
    handleAddOriginHaulageCharge,
    handleAddOtherCharge,
    handleDeleteCustomDestinationCharge,
    handleDeleteCustomOriginCharge,
    handleDeleteDestinationHaulageCharge,
    handleDeleteFreightCharge,
    handleDeleteOriginHaulageCharge,
    handleDeleteOtherCharge,
    handleNoteChange,
    handleOverviewChange,
    handleUpdateDestinationCharge,
    handleUpdateDestinationHaulageCharge,
    handleUpdateFreightCharge,
    handleUpdateOriginCharge,
    handleUpdateOriginHaulageCharge,
    handleUpdateOtherCharge,
    handleVisibilityChangeDestinationCharge,
    handleVisibilityChangeOriginCharge
  };
};

const getInitialDateRange = (): DateRange => ({
  startDate: getToday(),
  endDate: add(getToday(), { days: 13 })
});

const sortDateRanges = (dateRanges: DateRange[]): DateRange[] => {
  return [...dateRanges].sort((previous: DateRange, next: DateRange) => {
    return compareDate(previous.startDate).isBefore(next.startDate) ? -1 : 1;
  });
};

export const getNextAvailableDateRange = (dateRanges: DateRange[]): DateRange => {
  const lastAvailableDateRange: Undefinable<DateRange> = [...sortDateRanges(dateRanges)].pop();

  if (!lastAvailableDateRange) {
    return getInitialDateRange();
  }

  const startDate: string = add(lastAvailableDateRange.endDate, { days: 1 });
  const endDate: string = add(startDate, { days: 13 });

  return { startDate, endDate };
};

export const prepareChargeFilters = (freightCharges: RateCardCharge[]): DefaultChargeFilters => {
  const originPortChargeFilters: DefaultChargeFilter[] = [];
  const destinationPortChargeFilters: DefaultChargeFilter[] = [];

  freightCharges.forEach(({ applicability, fromLocation, toLocation }: RateCardCharge) => {
    if (applicability.includes(Applicability.ORIGIN)) {
      originPortChargeFilters.push({ applicability: Applicability.ORIGIN, referenceId: fromLocation?.id });
    }

    if (applicability.includes(Applicability.DESTINATION)) {
      destinationPortChargeFilters.push({ applicability: Applicability.DESTINATION, referenceId: toLocation?.id });
    }
  });

  return {
    originPortChargeFilters,
    destinationPortChargeFilters
  };
};
