import type { FC } from 'react';

import type { FormInstance } from '@zen/Components';
import type { FormFieldProps } from '@zen/Components/Form/FormField';
import FormField from '@zen/Components/Form/FormField';
import type { Option } from '@zen/DesignSystem';
import type { Nullable, Optional } from '@zen/utils/typescript';

import CargoOptionsSelect from '../CargoOptionsSelect';
import type { CargoOptionEnum, ChargeBasisGroup } from '../types';
import type { CargoModeEnum, ModeOfTransport } from './types.ts';

interface Props extends FormFieldProps {
  cargoType: CargoModeEnum;
  chargeBasisGroup?: Optional<ChargeBasisGroup>;
  isDisabled?: boolean;
  modeOfTransport: ModeOfTransport;
}

const FormCargoOptionsSelect: FC<Props> = (props) => {
  const { cargoType, chargeBasisGroup, isDisabled, modeOfTransport } = props;

  return (
    <FormField {...props}>
      {(
        field: { error: boolean; name: string; options: Option<CargoOptionEnum>[]; value: CargoOptionEnum[] },
        form: FormInstance<unknown>
      ) => (
        <CargoOptionsSelect
          {...field}
          cargoType={cargoType}
          chargeBasisGroup={chargeBasisGroup}
          hasError={field.error}
          isDisabled={isDisabled}
          modeOfTransport={modeOfTransport}
          onChange={(value: Nullable<string[]>) => {
            form.setFieldValue(field.name, value || null);
          }}
          value={field.value}
        />
      )}
    </FormField>
  );
};

export default FormCargoOptionsSelect;
