import { cargoModeLabelMapping } from '@zen/Cargo';
import { bookingModeOfTransportLabelMapping, cargoModeLabel } from '@zen/types';
import { formatDate } from '@zen/utils/dateTime';

import type { FormattedRateCardDetails, RateCard } from '../types';
import type { OverviewSection } from './types';

export const formatModeOfTransport = (modeOfTransport: RateCard['modeOfTransport']): string =>
  bookingModeOfTransportLabelMapping[modeOfTransport];

const formatIssuedBy = (issuedBy: RateCard['issuedBy']): string => {
  return issuedBy ? `${issuedBy.firstName} ${issuedBy.lastName}` : '-';
};

export const getOverviewSections = (rateCard: FormattedRateCardDetails): OverviewSection[] => {
  const { cargoType, endDate, issuedAt, issuedBy, modeOfTransport, startDate } = rateCard;

  return [
    {
      title: 'Mode of transport',
      detail: formatModeOfTransport(modeOfTransport)
    },
    {
      title: cargoModeLabel,
      detail: cargoModeLabelMapping[cargoType]
    },
    {
      title: 'Start date',
      detail: formatDate(startDate)
    },
    {
      title: 'End date',
      detail: formatDate(endDate)
    },
    {
      title: 'Issued by',
      detail: formatIssuedBy(issuedBy)
    },
    {
      title: 'Issued date',
      detail: formatDate(issuedAt || '')
    }
  ];
};
