import type { FC, ReactElement } from 'react';

import QueryHandler from '@zen/Components/QueryHandler';
import { Table } from '@zen/DesignSystem';

import type { ScimApiKey } from '../graphql';
import { useApiKeysQuery } from '../graphql/apiKeys.generated';
import { getColumns } from './tableConfiguration';

interface Props {
  accountId: string;
}

const ApiKeyTable: FC<Props> = ({ accountId }) => {
  const { data, error, loading } = useApiKeysQuery({
    variables: { accountId }
  });

  const apiKeys: ScimApiKey[] = data?.account?.ssoDetails?.apiKeys || [];

  return (
    <QueryHandler data={apiKeys} error={!!error} isLoading={loading} noResults={null}>
      {(keys: ScimApiKey[]): ReactElement => <Table<ScimApiKey> columns={getColumns()} data={keys} rowKey="id" tableId="keys" />}
    </QueryHandler>
  );
};

export default ApiKeyTable;
