import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import { PageInfoFragmentDoc } from '../../../graphql/fragments/PageInfo.generated';
import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type GetAccountMembersQueryVariables = GraphQLTypes.Exact<{
  accountId: GraphQLTypes.Scalars['String'];
  accountMembersFiltersInput?: GraphQLTypes.InputMaybe<GraphQLTypes.AccountMembersFiltersInput>;
  after?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  before?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['String']>;
  first?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
  last?: GraphQLTypes.InputMaybe<GraphQLTypes.Scalars['Int']>;
}>;

export type GetAccountMembersQueryResult = { __typename?: 'Query' } & {
  accountMembers?: GraphQLTypes.Maybe<
    { __typename?: 'AccountMemberConnection' } & Pick<GraphQLTypes.AccountMemberConnection, 'totalCount'> & {
        nodes: Array<
          GraphQLTypes.Maybe<
            { __typename?: 'AccountMember' } & Pick<
              GraphQLTypes.AccountMember,
              'canSwitchAccounts' | 'email' | 'firstName' | 'fullName' | 'id' | 'isProfileCompleted' | 'lastName' | 'phoneNumber'
            > & {
                assignedRoles: Array<
                  { __typename?: 'AccountUserGroup' } & Pick<GraphQLTypes.AccountUserGroup, 'name' | 'userGroupId'>
                >;
                businessUnits: Array<{ __typename?: 'Account' } & Pick<GraphQLTypes.Account, 'tradingName' | 'id'>>;
                locations: Array<{ __typename?: 'AccountLocation' } & Pick<GraphQLTypes.AccountLocation, 'name' | 'id'>>;
              }
          >
        >;
        pageInfo: { __typename: 'PageInfo' } & Pick<
          GraphQLTypes.PageInfo,
          'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'
        >;
      }
  >;
};

export const GetAccountMembersDocument = /* #__PURE__ */ gql`
  query getAccountMembers(
    $accountId: String!
    $accountMembersFiltersInput: AccountMembersFiltersInput
    $after: String
    $before: String
    $first: Int
    $last: Int
  ) {
    accountMembers(
      accountId: $accountId
      accountMembersFiltersInput: $accountMembersFiltersInput
      after: $after
      before: $before
      first: $first
      last: $last
    ) {
      nodes {
        assignedRoles {
          name
          userGroupId
        }
        businessUnits {
          tradingName
          id
        }
        canSwitchAccounts
        email
        firstName
        fullName
        id
        isProfileCompleted
        lastName
        locations {
          name
          id
        }
        phoneNumber
      }
      pageInfo {
        ...PageInfo
      }
      totalCount
    }
  }
  ${PageInfoFragmentDoc}
`;

/**
 * __useGetAccountMembersQuery__
 *
 * To run a query within a React component, call `useGetAccountMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountMembersQuery({
 *   variables: {
 *      accountId: // value for 'accountId'
 *      accountMembersFiltersInput: // value for 'accountMembersFiltersInput'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useGetAccountMembersQuery(
  baseOptions: Apollo.QueryHookOptions<GetAccountMembersQueryResult, GetAccountMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<GetAccountMembersQueryResult, GetAccountMembersQueryVariables>(GetAccountMembersDocument, options);
}
export function useGetAccountMembersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAccountMembersQueryResult, GetAccountMembersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<GetAccountMembersQueryResult, GetAccountMembersQueryVariables>(GetAccountMembersDocument, options);
}
export type GetAccountMembersQueryHookResult = ReturnType<typeof useGetAccountMembersQuery>;
export type GetAccountMembersLazyQueryHookResult = ReturnType<typeof useGetAccountMembersLazyQuery>;
