import type { FC, ReactNode } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import type { TabConfig } from '@zen/Components/PageWithTabs';
import PageWithTabs from '@zen/Components/PageWithTabs';
import { getActiveIssues } from '@zen/Issues/issueHelpers';
import { Role } from '@zen/Networks';
import { orderRoutes } from '@zen/Routes';

import Details from '../Details';
import LineItems from '../LineItems';
import OrderActivityFeed from '../OrderActivityFeed';
import OrderIssues from '../OrderIssues';
import type { OrderDetailsType } from '../types';

interface Props {
  header: ReactNode;
  orderDetails: OrderDetailsType;
}

const OrderDetailsTabs: FC<Props> = ({ header, orderDetails }) => {
  const { id: orderId, issues, orderDate, permissions } = orderDetails;
  const numberOfActiveIssues: number = issues ? getActiveIssues(issues).length : 0;
  const role = useRole();

  const canManagePO: boolean = !!permissions?.canManage?.value || role === Role.AGENT_FORWARDER;
  const isCustomerOnPO: boolean = !!permissions?.isCustomerOnPO?.value;
  const isSupplierOnPO: boolean = !!permissions?.isSupplierOnPO?.value;

  const config: TabConfig[] = [
    {
      label: 'Activity',
      path: orderRoutes.orderActivity.relativePath,
      component: (
        <OrderActivityFeed
          orderId={orderId}
          targetPermissions={{ isCustomerOnTarget: isCustomerOnPO, isSupplierOnTarget: isSupplierOnPO }}
        />
      ),
      url: orderRoutes.orderActivity.getRelativeUrl()
    },
    {
      label: 'Line items',
      path: orderRoutes.orderItems.relativePath,
      component: <LineItems canManagePO={canManagePO} orderDate={orderDate} orderId={orderId} />,
      url: orderRoutes.orderItems.getRelativeUrl()
    },
    {
      label: 'Other details',
      path: orderRoutes.orderOtherDetails.relativePath,
      component: <Details orderId={orderId} />,
      url: orderRoutes.orderOtherDetails.getRelativeUrl()
    },
    {
      label: 'Issues',
      totalCount: numberOfActiveIssues,
      path: orderRoutes.orderIssues.relativePath,
      component: <OrderIssues canManagePO={canManagePO} orderId={orderId} />,
      url: orderRoutes.orderIssues.getRelativeUrl()
    }
  ];

  return <PageWithTabs contentClassName="p-6 flex-1 overflow-auto" header={header} headerMargin="" tabsConfig={config} />;
};

export default OrderDetailsTabs;
