import type { FC } from 'react';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import CustomiseTableButton from '@zen/Components/CustomiseTableButton';
import Page from '@zen/Components/Page';
import type { TableColumn } from '@zen/DesignSystem';
import { Table, useTableConfiguration } from '@zen/DesignSystem';
import type { FlatShipment } from '@zen/Shipments';
import { prepareFlatShipments } from '@zen/Shipments/helpers';
import type { SortInput } from '@zen/types';
import { SortingOrder } from '@zen/types';
import useAccount from '@zen/utils/hooks/useAccount';

import { useAllShipments } from './hooks/useAllShipments';
import { getColumns } from './tableConfiguration';
import Toolbar from './Toolbar';

export const CUSTOMER_ADMIN_SHIPMENTS_TABLE_ID = 'customerAdminAllShipments';

const AllShipments: FC = () => {
  const [order, setOrder] = useState<SortInput>({ field: 'createdAt', direction: SortingOrder.DESC });

  const { userProfile } = useAccount();
  const { pathname } = useLocation();
  const { hiddenColumns, setHiddenColumns } = useTableConfiguration();
  const { loading, error, nodes, paginationInfo, totalCount } = useAllShipments({ order });

  const columns: TableColumn<FlatShipment>[] = getColumns(pathname);

  return (
    <Page tagline={userProfile.registeredCompanyName} title="All shipments">
      <Table<FlatShipment>
        actions={
          <CustomiseTableButton columns={columns} hiddenColumns={hiddenColumns} onChange={setHiddenColumns} size="compact" />
        }
        additionalActions={<Toolbar />}
        columns={columns}
        data={prepareFlatShipments(nodes || [])}
        error={!!error}
        hiddenColumns={hiddenColumns}
        loading={loading}
        onOrderChange={setOrder}
        order={order}
        paginationInfo={paginationInfo}
        tableId={CUSTOMER_ADMIN_SHIPMENTS_TABLE_ID}
        totalCountConfig={{
          totalCount,
          entityName: 'shipment'
        }}
      />
    </Page>
  );
};

export default AllShipments;
