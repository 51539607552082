import type { FC } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault/ValueWithDefault';
import type { RailwayCompany } from '@zen/Journey/types';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';
import type { Nullable, Undefinable } from '@zen/utils/typescript';

import RailwayCompanySelect from '../RailwayCompanySelect';
import { useRailwayCompanyQuery, useUpdateJourneyRailwayCompanyMutation } from './graphql';

interface Props {
  canUpdateRailwayCompany: boolean;
  railwayCompanyId: string;
}

const RailwayCompanyInlineField: FC<Props> = ({ canUpdateRailwayCompany, railwayCompanyId }) => {
  const { addSuccess, addError } = useNotification();

  const { zencargoReference } = useCargoOverviewContext();

  const { data } = useRailwayCompanyQuery({
    variables: { id: railwayCompanyId },
    fetchPolicy: 'no-cache',
    skip: !railwayCompanyId
  });
  const [updateJourneyRailwayCompany, { loading }] = useUpdateJourneyRailwayCompanyMutation();

  const railwayCompany: Undefinable<RailwayCompany> = data?.railwayCompany || undefined;

  const label: string = 'Railway company';

  const handleChange = async (value: Nullable<string>): Promise<void> => {
    await performMutation({
      mutationFn: () =>
        updateJourneyRailwayCompany({
          variables: {
            input: {
              railwayCompany: value,
              zencargoReference
            }
          },
          refetchQueries: ['cargoOverview']
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess(`${label} updated.`);
      }
    });
  };

  if (!canUpdateRailwayCompany) {
    return (
      <LabelledValue label={label} labelPosition="left" variant="default">
        <ValueWithDefault value={railwayCompany?.railwayCompany} />
      </LabelledValue>
    );
  }

  return (
    <RailwayCompanySelect
      isLoading={loading}
      label={label}
      name="railway-company"
      onChange={handleChange}
      value={railwayCompanyId}
      variant="inline"
    />
  );
};

export default RailwayCompanyInlineField;
