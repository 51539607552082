import type { FC } from 'react';

import useRole from '@zen/Auth/hooks/useRole';
import useAccountPermissions from '@zen/Auth/useAccountPermissions';
import useGlobalPermissions from '@zen/Auth/useGlobalPermissions';
import type { NoResultsButtonConfig } from '@zen/DesignSystem';
import { NoResults as NoResultsComponent } from '@zen/DesignSystem';
import { Role } from '@zen/Networks';
import { orderRoutes } from '@zen/Routes';

interface Props {
  hasFilters: boolean;
}

const NoResults: FC<Props> = ({ hasFilters }) => {
  const role = useRole();
  const { check } = useGlobalPermissions();
  const { check: accountCheck } = useAccountPermissions();

  const canCreateOrders: boolean =
    role !== Role.AGENT_FORWARDER ? accountCheck('purchaseOrders.canCreate') : check('purchaseOrders.canCreate');

  if (hasFilters) {
    return (
      <NoResultsComponent
        headline="We can't find any results."
        isCentered={false}
        tagline="There are no results for your query, try adjusting your search or filter criteria."
      />
    );
  }

  if (canCreateOrders) {
    const button: NoResultsButtonConfig = {
      linkTo: orderRoutes.orderNew.getUrl(),
      label: 'Create a new PO'
    };

    return (
      <NoResultsComponent
        button={button}
        headline="You don't have any orders"
        isCentered={false}
        tagline="You can create a new order by clicking on the create PO button below."
      />
    );
  }

  return <NoResultsComponent headline="You have no orders on Zencargo yet" isCentered={false} />;
};

export default NoResults;
