import type { ModeOfTransport } from '@zen/Booking';
import { isAirBooking, isOceanBooking, isRailBooking } from '@zen/Booking';
import type {
  JourneyLeg,
  JourneyScheduledLegInput,
  JourneyShippingMilestone,
  JourneyStop,
  JourneyVehicleProperty,
  VehiclePropertyInput
} from '@zen/Journey/types';
import { JourneyShippingMilestoneCategoryEnum, VehiclePropertyNameEnum } from '@zen/Journey/types';
import type { Optional } from '@zen/utils/typescript';

import type { PlannedScheduleValues, Stop } from '../forms/PlannedScheduleForm/types';
import { AirScheduleCarrier } from '../forms/PlannedScheduleForm/types';

const getActualDate = (milestone: Optional<JourneyShippingMilestone>): string => milestone?.actual?.date || '';
const getPlannedDate = (milestone: Optional<JourneyShippingMilestone>): string => milestone?.planned?.startDateTime.date || '';

const getStop = (stop: JourneyStop, properties: JourneyVehicleProperty[]): Stop => {
  const arrivalMilestone = stop.shippingMilestones.find(
    ({ category }) => category === JourneyShippingMilestoneCategoryEnum.ARRIVED_AT_STOP
  );
  const isArrivalDateCompleted: boolean = !!arrivalMilestone?.completed;
  const departureMilestone = stop.shippingMilestones.find(
    ({ category }) => category === JourneyShippingMilestoneCategoryEnum.DEPARTED_FROM_STOP
  );
  const isDepartureDateCompleted: boolean = !!departureMilestone?.completed;
  const cargoLoadedMilestone = stop.shippingMilestones.find(
    ({ category }) => category === JourneyShippingMilestoneCategoryEnum.LOADED
  );
  const cargoUnloadedMilestone = stop.shippingMilestones.find(
    ({ category }) => category === JourneyShippingMilestoneCategoryEnum.UNLOADED
  );

  return {
    arrivalDate: isArrivalDateCompleted ? getActualDate(arrivalMilestone) : getPlannedDate(arrivalMilestone),
    arrivalDateDisabled: isArrivalDateCompleted,
    cargoLoadedDate: cargoLoadedMilestone?.actual?.date || '',
    cargoUnloadedDate: cargoUnloadedMilestone?.actual?.date || '',
    departureDate: isDepartureDateCompleted ? getActualDate(departureMilestone) : getPlannedDate(departureMilestone),
    departureDateDisabled: departureMilestone?.completed,
    flightNumber: properties.find(({ name }) => name === VehiclePropertyNameEnum.FLIGHT_NUMBER)?.value || '',
    location: stop.terminal,
    vesselName: properties.find(({ name }) => name === VehiclePropertyNameEnum.VESSEL_NAME)?.value || '',
    voyageNumber: properties.find(({ name }) => name === VehiclePropertyNameEnum.VOYAGE_NUMBER)?.value || ''
  };
};

export const getStops = (carriageLegs: JourneyLeg[]): Stop[] => {
  return carriageLegs.reduce((journeyStops: Stop[], currentLeg: JourneyLeg, currentIndex: number, array: JourneyLeg[]) => {
    const { from, to, vehicle } = currentLeg;

    const isFirstLeg: boolean = currentIndex === 0;
    const nextLeg: Optional<JourneyLeg> = array[currentIndex + 1];
    const currentLegProperties = vehicle?.properties || [];
    const nextLegProperties = nextLeg?.vehicle?.properties || [];

    const toStop = nextLeg
      ? {
          ...to,
          shippingMilestones: [...to.shippingMilestones, ...nextLeg.from.shippingMilestones]
        }
      : to;

    if (isFirstLeg) {
      return [getStop(from, currentLegProperties), getStop(toStop, nextLegProperties)];
    }

    return [...journeyStops, getStop(toStop, nextLegProperties)];
  }, []);
};

const getCarrier = (properties: JourneyVehicleProperty[], modeOfTransport: Optional<ModeOfTransport>): string => {
  if (isOceanBooking(modeOfTransport)) {
    return properties.find(({ name }) => name === VehiclePropertyNameEnum.SCAC)?.value || '';
  }

  if (isRailBooking(modeOfTransport)) {
    return properties.find(({ name }) => name === VehiclePropertyNameEnum.RAILWAY_COMPANY)?.value || '';
  }

  if (isAirBooking(modeOfTransport)) {
    const airlineProperty = properties.find(({ name }) => name === VehiclePropertyNameEnum.AIRLINE)?.value || '';

    return airlineProperty || properties.find(({ name }) => name === VehiclePropertyNameEnum.COURIER)?.value || '';
  }

  return '';
};

export const prepareInitialValues = (
  carriageLegs: JourneyLeg[],
  modeOfTransport: Optional<ModeOfTransport>
): PlannedScheduleValues => {
  const mainVehicleProperties: JourneyVehicleProperty[] = carriageLegs?.[0]?.vehicle?.properties || [];
  const isCourierShipment: boolean = !!mainVehicleProperties.find(({ name }) => name === VehiclePropertyNameEnum.COURIER)?.value;

  const trackingNumber: string =
    mainVehicleProperties.find(({ name }) => name === VehiclePropertyNameEnum.TRACKING_NUMBER)?.value || '';

  return {
    airScheduleCarrierType: isCourierShipment ? AirScheduleCarrier.COURIER : AirScheduleCarrier.AIRLINE,
    carrier: getCarrier(mainVehicleProperties, modeOfTransport),
    stops: getStops(carriageLegs),
    trackingNumber
  };
};

export const getVehicleProperties = (stop: Stop, modeOfTransport: Optional<ModeOfTransport>): VehiclePropertyInput[] => {
  if (isOceanBooking(modeOfTransport)) {
    return [
      {
        name: VehiclePropertyNameEnum.VESSEL_NAME,
        value: stop.vesselName
      },
      {
        name: VehiclePropertyNameEnum.VOYAGE_NUMBER,
        value: stop.voyageNumber
      }
    ];
  }

  if (isAirBooking(modeOfTransport)) {
    return [
      {
        name: VehiclePropertyNameEnum.FLIGHT_NUMBER,
        value: stop.flightNumber
      }
    ];
  }

  return [];
};

export const prepareLegs = (stops: Stop[], modeOfTransport: Optional<ModeOfTransport>): JourneyScheduledLegInput[] => {
  return stops.reduce((prev: JourneyScheduledLegInput[], stop: Stop, index: number): JourneyScheduledLegInput[] => {
    const isLastStop: boolean = stops.length - 1 === index;

    const nextStop = stops[index + 1];

    const { departureDate, location } = stop;

    if (isLastStop) return prev;

    return [
      ...prev,
      {
        fromEstimatedDate: {
          startDateTime: {
            date: departureDate
          }
        },
        fromUnlocode: location?.unlocode || '',
        toEstimatedDate: {
          startDateTime: {
            date: nextStop.arrivalDate
          }
        },
        toUnlocode: nextStop.location?.unlocode || '',
        vehicleProperties: getVehicleProperties(stop, modeOfTransport)
      }
    ];
  }, []);
};
