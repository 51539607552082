import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type CommercialInvoiceResultQueryVariables = GraphQLTypes.Exact<{
  documentId: GraphQLTypes.Scalars['ID'];
}>;

export type CommercialInvoiceResultQueryResult = { __typename?: 'Query' } & {
  getParsedCommercialInvoice?: GraphQLTypes.Maybe<
    { __typename?: 'ParsedCommercialInvoiceResult' } & Pick<
      GraphQLTypes.ParsedCommercialInvoiceResult,
      'commodityCode' | 'consignor' | 'originCountry' | 'destinationCountry' | 'consignee' | 'invoiceNumber'
    > & {
        lineItems?: GraphQLTypes.Maybe<
          Array<
            GraphQLTypes.Maybe<
              { __typename?: 'ParsedLineItemResult' } & Pick<
                GraphQLTypes.ParsedLineItemResult,
                | 'containerNumber'
                | 'skuCode'
                | 'currency'
                | 'purchaseOrderNumber'
                | 'hsCode'
                | 'looseCargoType'
                | 'grossWeight'
                | 'price'
                | 'weightUnit'
                | 'netWeight'
                | 'productName'
                | 'quantity'
                | 'looseCargoQuantity'
              >
            >
          >
        >;
      }
  >;
};

export const CommercialInvoiceResultDocument = /* #__PURE__ */ gql`
  query commercialInvoiceResult($documentId: ID!) {
    getParsedCommercialInvoice(documentId: $documentId) {
      commodityCode
      consignor
      originCountry
      destinationCountry
      consignee
      invoiceNumber
      lineItems {
        containerNumber
        skuCode
        currency
        purchaseOrderNumber
        hsCode
        looseCargoType
        grossWeight
        price
        weightUnit
        netWeight
        productName
        quantity
        looseCargoQuantity
      }
    }
  }
`;

/**
 * __useCommercialInvoiceResultQuery__
 *
 * To run a query within a React component, call `useCommercialInvoiceResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommercialInvoiceResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommercialInvoiceResultQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useCommercialInvoiceResultQuery(
  baseOptions: Apollo.QueryHookOptions<CommercialInvoiceResultQueryResult, CommercialInvoiceResultQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useQuery<CommercialInvoiceResultQueryResult, CommercialInvoiceResultQueryVariables>(
    CommercialInvoiceResultDocument,
    options
  );
}
export function useCommercialInvoiceResultLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CommercialInvoiceResultQueryResult, CommercialInvoiceResultQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useLazyQuery<CommercialInvoiceResultQueryResult, CommercialInvoiceResultQueryVariables>(
    CommercialInvoiceResultDocument,
    options
  );
}
export type CommercialInvoiceResultQueryHookResult = ReturnType<typeof useCommercialInvoiceResultQuery>;
export type CommercialInvoiceResultLazyQueryHookResult = ReturnType<typeof useCommercialInvoiceResultLazyQuery>;
