import type { FC } from 'react';

import { UsageContext } from '@zen/Accounts/AccountDetails/AccountLocation/types';
import { FormRadioGroup } from '@zen/Components';
import type { RadioGroupOption } from '@zen/Components/RadioGroup';
import { Label } from '@zen/DesignSystem';

const options: RadioGroupOption[] = [
  {
    label: 'Receive shipments - This location can be assigned as a delivery address',
    value: UsageContext.SHIP_FROM
  },
  {
    label: 'Send shipments - This location can be assigned as a pickup address',
    value: UsageContext.SHIP_TO
  },
  {
    label: 'Both - This location can be can be assigned as a both pickup and delivery address',
    value: UsageContext.BOTH
  }
];

interface Props {
  name: string;
  noMargin?: boolean;
}

const LocationUsageRadioGroup: FC<Props> = ({ name, noMargin }) => {
  return (
    <>
      <Label content="Location usage" />
      <div className="my-2 text-sm text-grey-base">Determine how your connections can use this location.</div>
      <FormRadioGroup hideLabel={true} name={name} noMargin={noMargin} options={options} radioAlignment="column" />
    </>
  );
};

export default LocationUsageRadioGroup;
