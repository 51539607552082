import cx from 'classnames';
import type { FC, ReactNode, SyntheticEvent } from 'react';

import type { IconType } from '@zen/types';
import useTracking from '@zen/utils/hooks/useTracking';
import { createTrackingEvent, createTrackingLabelAttribute, createTrackingParentAttribute } from '@zen/utils/tracking';

import Icon from '../Icon';
import InformationIcon from '../InformationIcon';
import { activePillClasses, defaultPillClasses } from './helpers';
import type { PillType } from './types';

interface Props {
  children: ReactNode;
  className?: string;
  iconLeft?: IconType;
  iconRight?: IconType;
  onClick?: () => void;
  tooltip?: ReactNode;
  type?: PillType;
}

const renderIcon = (icon: IconType): ReactNode => {
  if (typeof icon !== 'string') {
    return icon;
  }

  return <Icon className="text-base" icon={icon} />;
};

export const displayName = 'Design System/Pill';

const Pill: FC<Props> = (props) => {
  const { children, className, iconLeft, iconRight, type = 'default', onClick, tooltip } = props;

  const { trackEvent } = useTracking();

  const defineRightIcon: IconType | undefined = tooltip ? (
    <span className="-mb-0.5">
      <InformationIcon content={tooltip} />
    </span>
  ) : (
    iconRight
  );

  const classNames: string = cx(
    defaultPillClasses[type],
    {
      [activePillClasses[type]]: onClick,
      'cursor-pointer': onClick
    },
    'border-transparent border border-solid h-6 text-sm font-normal inline-block px-4 py-1 whitespace-nowrap justify-center rounded-full inline-flex',
    className
  );

  const handleClick = (event: SyntheticEvent): void => {
    const trackingEvent = createTrackingEvent(displayName, 'click', event);

    trackEvent(trackingEvent);

    onClick?.();
  };

  return (
    <span
      className={classNames}
      data-component="pill"
      data-testid={`pill-${type}`}
      onClick={handleClick}
      {...createTrackingParentAttribute('pill')}
    >
      <span className="flex items-center space-x-2">
        {iconLeft && renderIcon(iconLeft)}
        <span {...createTrackingLabelAttribute('pill')}>{children}</span>
        {defineRightIcon && renderIcon(defineRightIcon)}
      </span>
    </span>
  );
};

export type { Props as PillProps };
export default Pill;
