import type { FC } from 'react';
import { useEffect } from 'react';

import useAccount from '@zen/utils/hooks/useAccount';
import { useNotification } from '@zen/utils/hooks/useNotification';

import type { SubscribeActionInput } from './graphql';
import { useGetSubscribersQuery, useNotificationsSubscribeMutation } from './graphql';
import { useNotificationsUnsubscribeMutation } from './graphql/notificationsUnsubscribe.generated';
import Subscriptions from './Subscriptions';
import type {
  ActivityFeedTargetTypeEnum,
  NotificationsTargetTypeEnum,
  Stakeholder,
  Subscriber,
  SubscriptionStakeholder
} from './types';

interface Props {
  accountId: string;
  targetId: string;
  targetType: NotificationsTargetTypeEnum | ActivityFeedTargetTypeEnum;
}

const SubscriptionsContainer: FC<Props> = ({ targetId, targetType, accountId }) => {
  const { addSuccess, addError } = useNotification();
  const { userProfile } = useAccount();
  const [subscribe] = useNotificationsSubscribeMutation();
  const [unsubscribe] = useNotificationsUnsubscribeMutation();

  const {
    data: subscribersData,
    refetch,
    loading
  } = useGetSubscribersQuery({
    variables: {
      targetId,
      targetType: targetType as NotificationsTargetTypeEnum,
      accountId
    }
  });

  useEffect(() => {
    refetch();
  }, [refetch]);

  const subscribers: Subscriber[] = (subscribersData?.notifications?.subscribers as Subscriber[]) || [];
  const stakeholders: Stakeholder[] = (subscribersData?.notifications?.stakeholders as Stakeholder[]) || [];

  const createPayload = (subscriberId: string): SubscribeActionInput => ({
    input: {
      subscriberId,
      targetId,
      targetType: targetType as NotificationsTargetTypeEnum
    }
  });

  if (loading) {
    return null;
  }

  const isCurrentUser = (stakeholder: SubscriptionStakeholder): boolean => {
    return stakeholder.accountMember?.id === userProfile.uuid || stakeholder.contactDetails?.id === userProfile.uuid;
  };

  const getSuccessMessage = (stakeholder: SubscriptionStakeholder, action: 'subscribed' | 'unsubscribed'): string => {
    if (isCurrentUser(stakeholder)) {
      return `You have been ${action}`;
    }

    return `${stakeholder.accountMember?.firstName || stakeholder.contactDetails?.firstName} has been ${action}`;
  };

  const handleSubscribe = async (stakeholder: SubscriptionStakeholder): Promise<void> => {
    const variables = createPayload(stakeholder.accountMember?.id || stakeholder.contactDetails?.id || '');

    try {
      await subscribe({ variables });

      const successMessage = getSuccessMessage(stakeholder, 'subscribed');

      addSuccess(successMessage);
    } catch {
      addError();
    }

    refetch();
  };

  const handleUnsubscribe = async (stakeholder: SubscriptionStakeholder): Promise<void> => {
    const variables = createPayload(stakeholder.accountMember?.id || stakeholder.contactDetails?.id || '');

    try {
      await unsubscribe({ variables });

      const successMessage = getSuccessMessage(stakeholder, 'unsubscribed');

      addSuccess(successMessage);
    } catch {
      addError();
    }

    refetch();
  };

  return (
    <Subscriptions
      onSubscribe={handleSubscribe}
      onUnsubscribe={handleUnsubscribe}
      stakeholders={stakeholders}
      subscribers={subscribers}
    />
  );
};

export default SubscriptionsContainer;
