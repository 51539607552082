import { get, uniqueId } from 'lodash';
import { type FC, type ReactNode, useState } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import InlineEditableField from '@zen/Components/InlineEditableField';
import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault/ValueWithDefault';
import { Tooltip } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import { defaultErrorMessage } from '@zen/utils/validation';

import { useUpdateJourneyCarrierBookingReferenceMutation } from './graphql';

interface Props {
  canUpdateCarrierBookingReference: boolean;
  carrierBookingReference: string;
  isBookingRequested: boolean;
}

const CarrierBookingReferenceInlineField: FC<Props> = (props) => {
  const { canUpdateCarrierBookingReference, carrierBookingReference, isBookingRequested } = props;
  const { addSuccess, addError } = useNotification();
  const { zencargoReference } = useCargoOverviewContext();
  const [updateCarrierBookingReference] = useUpdateJourneyCarrierBookingReferenceMutation();
  const [inputKey, setInputKey] = useState(uniqueId());
  const placeholder: string = 'Enter carrier booking reference';

  const label: string = 'Carrier booking reference';

  const handleChange = (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateCarrierBookingReference({
          awaitRefetchQueries: true,
          refetchQueries: ['cargoOverview'],
          variables: {
            input: {
              carrierBookingReference: value,
              zencargoReference
            }
          }
        }),
      onError: (errors) => {
        setInputKey(uniqueId());

        addError(get(errors, '[0].message', defaultErrorMessage));
      },
      onSuccess: () => {
        addSuccess(`${label} updated.`);
      }
    });
  };

  const renderTooltip = (): ReactNode => {
    return (
      <Tooltip tooltipContent="Ensure this booking is approved before entering it">
        <div className="cursor-not-allowed text-grey-light">{placeholder}</div>
      </Tooltip>
    );
  };

  const renderValue = (): ReactNode => {
    return <ValueWithDefault value={carrierBookingReference} />;
  };

  if (!canUpdateCarrierBookingReference || isBookingRequested) {
    return (
      <LabelledValue label={label} labelPosition="left" variant="default">
        {isBookingRequested ? renderTooltip() : renderValue()}
      </LabelledValue>
    );
  }

  return (
    <InlineEditableField
      key={inputKey}
      label={label}
      labelPlacement="left"
      name="carrier-booking-reference"
      onUpdate={handleChange}
      placeholder={placeholder}
      value={carrierBookingReference}
    />
  );
};

export default CarrierBookingReferenceInlineField;
