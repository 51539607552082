import type { FC, ReactElement } from 'react';

import { Role } from '@zen/Auth';
import useRole from '@zen/Auth/hooks/useRole';
import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import { Button, Tooltip } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { journeyRefetchQueryList } from '../forms/helpers';
import { useConfirmJourneyScheduleMutation } from './graphql';

interface Props {
  isBookingRequested: boolean;
  isCarrierBookingReferenceMissing: boolean;
}

const ConfirmScheduleButton: FC<Props> = ({ isBookingRequested, isCarrierBookingReferenceMissing }) => {
  const { addSuccess, addError } = useNotification();
  const role = useRole();

  const { zencargoReference } = useCargoOverviewContext();

  const [confirmJourneySchedule, { loading }] = useConfirmJourneyScheduleMutation();

  const isDisabled: boolean = isCarrierBookingReferenceMissing || isBookingRequested;
  const tooltip: string = isBookingRequested
    ? 'Ensure this booking is approved before confirming it.'
    : 'First you need to enter carrier booking reference.';

  const handleConfirm = (): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        confirmJourneySchedule({
          awaitRefetchQueries: true,
          refetchQueries: journeyRefetchQueryList,
          variables: {
            input: {
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess('Booking has been confirmed.');
      }
    });
  };

  const renderButton = (): ReactElement => {
    const buttonLabel: string = role === Role.AGENT_FORWARDER ? 'Space released' : 'Confirm booking';

    return (
      <Button
        disabled={loading || isDisabled}
        iconLeft="zicon-tick"
        isLoading={loading}
        onClick={handleConfirm}
        size="compact"
        variant="secondary"
      >
        {buttonLabel}
      </Button>
    );
  };

  const renderButtonWithTooltip = (): ReactElement => {
    return <Tooltip tooltipContent={tooltip}>{renderButton()}</Tooltip>;
  };

  return isDisabled ? renderButtonWithTooltip() : renderButton();
};

export default ConfirmScheduleButton;
