import { useEffect, useState } from 'react';

import { ParserEngineDocumentType } from '@zen/graphql/types.generated';
import { getSignedAssetUrl } from '@zen/utils/downloadS3File';
import { useNotification } from '@zen/utils/hooks/useNotification';
import { performMutation } from '@zen/utils/performMutation';

import { useCommercialInvoiceResultQuery, useParseDocumentMutation } from '../graphql';

const useDocumentProcessor = () => {
  const [parse, { loading, called, error }] = useParseDocumentMutation();
  const { addError, addSuccess } = useNotification();
  const [documentId, setDocumentId] = useState<string>('');
  const { data, startPolling, stopPolling } = useCommercialInvoiceResultQuery({
    variables: {
      documentId
    }
  });

  const isResultReady: boolean = !!data?.getParsedCommercialInvoice;
  const isProcessing: boolean = (called && !error) || loading;

  useEffect(() => {
    if (isResultReady) {
      addSuccess('Document parse result ready!');
      stopPolling();
    }
  }, [isResultReady, stopPolling, addSuccess]);

  const parseDocument = async (id: string, assetUrl: string, fileName: string) => {
    try {
      const fileUrl = await getSignedAssetUrl(assetUrl);

      setDocumentId(id);

      addSuccess('We are working on it!');

      await performMutation({
        mutationFn: () =>
          parse({
            variables: {
              input: {
                fileUrl,
                documentType: ParserEngineDocumentType.COMMERCIAL_INVOICE,
                fileId: id,
                fileName
              }
            }
          }),
        onError: () => addError()
      });

      startPolling(3000);
    } catch {
      addError();
    }
  };

  return { parseDocument, isResultReady, isProcessing };
};

export default useDocumentProcessor;
