import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ReviewCommercialInvoiceResultMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ReviewParsedCommercialInvoiceInput;
}>;

export type ReviewCommercialInvoiceResultMutationResult = { __typename?: 'Mutation' } & Pick<
  GraphQLTypes.Mutation,
  'reviewParsedCommercialInvoice'
>;

export const ReviewCommercialInvoiceResultDocument = /* #__PURE__ */ gql`
  mutation reviewCommercialInvoiceResult($input: ReviewParsedCommercialInvoiceInput!) {
    reviewParsedCommercialInvoice(input: $input)
  }
`;

/**
 * __useReviewCommercialInvoiceResultMutation__
 *
 * To run a mutation, you first call `useReviewCommercialInvoiceResultMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReviewCommercialInvoiceResultMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reviewCommercialInvoiceResultMutation, { data, loading, error }] = useReviewCommercialInvoiceResultMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReviewCommercialInvoiceResultMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReviewCommercialInvoiceResultMutationResult,
    ReviewCommercialInvoiceResultMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ReviewCommercialInvoiceResultMutationResult, ReviewCommercialInvoiceResultMutationVariables>(
    ReviewCommercialInvoiceResultDocument,
    options
  );
}
export type ReviewCommercialInvoiceResultMutationHookResult = ReturnType<typeof useReviewCommercialInvoiceResultMutation>;
