import type { ObjectSchema } from 'yup';
import { object, string } from 'yup';

import { getChargeItemsValidator } from '@zen/RateCards/FreightCharges/FreightChargeForm/freightCharge.validation';

export const validationSchema: ObjectSchema<{}> = object().shape({
  chargeType: object().nullable().required('Please select a charge type.'),
  chargeItems: getChargeItemsValidator(false),
  toLocationId: string().nullable().required('Please select a port.')
});
