import type { ReactNode } from 'react';

import type { FilterItemType, FilterSection } from '@zen/Components/Filters/types';
import type { DateRange, Group, Option } from '@zen/DesignSystem';
import { Icon } from '@zen/DesignSystem';
import type { NetworksAssignable } from '@zen/Networks';
import type { IconName } from '@zen/Styleguide';
import { getDateRange, getToday, getTomorrow, getYesterday } from '@zen/utils/dateTime';
import type { Modify } from '@zen/utils/typescript';

import { airShipmentTypeConfig, shipmentTableMilestoneConfig } from '../helpers';
import type { ShipmentsTableFiltersValues } from '../types';
import {
  IncotermsValue,
  RelativeDateRange,
  ShipmentsTableAirShipmentType,
  ShipmentsTableCustomsOnlyValue,
  ShipmentsTableMilestone,
  ShipmentsTableModeOfTransport
} from '../types';
import type { ShipmentsFilterReturnType, ShipmentsTableFilterValue } from './hooks/types';
import type { ShipmentsTableFiltersCarrierScac } from './types';

export const getMilestoneOptions = (): Group<ShipmentsTableMilestone>[] => [
  {
    label: 'Booking stages',
    options: [
      ShipmentsTableMilestone.BOOKING_RECEIVED,
      ShipmentsTableMilestone.BOOKING_REQUESTED,
      ShipmentsTableMilestone.BOOKING_CONFIRMED
    ].map((value) => ({ value, label: shipmentTableMilestoneConfig[value] }))
  },
  {
    label: 'In transit stages',
    options: [
      ShipmentsTableMilestone.EN_ROUTE_TO_PORT_OF_LOADING,
      ShipmentsTableMilestone.CARGO_ARRIVED_AT_PORT_OF_LOADING,
      ShipmentsTableMilestone.CARGO_LOADED,
      ShipmentsTableMilestone.IN_TRANSIT,
      ShipmentsTableMilestone.ARRIVED_AT_PORT_OF_DESTINATION,
      ShipmentsTableMilestone.EN_ROUTE_TO_FINAL_DESTINATION
    ].map((value) => ({ value, label: shipmentTableMilestoneConfig[value] }))
  },
  { options: [{ value: ShipmentsTableMilestone.DELIVERED, label: 'Delivered' }] }
];

export const getIncotermsOptions = (): Option<IncotermsValue>[] => {
  return [
    { value: IncotermsValue.CFR, label: 'CFR' },
    { value: IncotermsValue.CIF, label: 'CIF' },
    { value: IncotermsValue.CIP, label: 'CIP' },
    { value: IncotermsValue.CPT, label: 'CPT' },
    { value: IncotermsValue.DAP, label: 'DAP' },
    { value: IncotermsValue.DAT, label: 'DAT' },
    { value: IncotermsValue.DDP, label: 'DDP' },
    { value: IncotermsValue.EXWORKS, label: 'EXWORKS' },
    { value: IncotermsValue.FAS, label: 'FAS' },
    { value: IncotermsValue.FCA, label: 'FCA' },
    { value: IncotermsValue.FOB, label: 'FOB' }
  ];
};

const getAirShipmentTypeOptions = (): Option<ShipmentsTableAirShipmentType>[] => [
  { value: ShipmentsTableAirShipmentType.AIRLINE, label: airShipmentTypeConfig[ShipmentsTableAirShipmentType.AIRLINE] },
  { value: ShipmentsTableAirShipmentType.COURIER, label: airShipmentTypeConfig[ShipmentsTableAirShipmentType.COURIER] }
];

export const getModeOfTransportOptions = (): Option<ShipmentsTableModeOfTransport>[] => {
  return [
    { value: ShipmentsTableModeOfTransport.OCEAN, label: 'Ocean' },
    { value: ShipmentsTableModeOfTransport.ROAD, label: 'Road' },
    { value: ShipmentsTableModeOfTransport.AIR, label: 'Air' },
    { value: ShipmentsTableModeOfTransport.RAIL, label: 'Rail' }
  ];
};

export const getDateRangeOptions = (): Option<DateRange<RelativeDateRange>>[] => [
  { value: getDateRange(-14, getToday(), RelativeDateRange.LAST_14_DAYS), label: 'Last 14 days' },
  { value: getDateRange(-7, getToday(), RelativeDateRange.LAST_7_DAYS), label: 'Last 7 days' },
  { value: getDateRange(-3, getToday(), RelativeDateRange.LAST_3_DAYS), label: 'Last 3 days' },
  { value: { startDate: getYesterday(), endDate: getYesterday(), value: RelativeDateRange.YESTERDAY }, label: 'Yesterday' },
  { value: { startDate: getToday(), endDate: getToday(), value: RelativeDateRange.TODAY }, label: 'Today' },
  { value: { startDate: getTomorrow(), endDate: getTomorrow(), value: RelativeDateRange.TOMORROW }, label: 'Tomorrow' },
  { value: getDateRange(3, getToday(), RelativeDateRange.NEXT_3_DAYS), label: 'Next 3 days' },
  { value: getDateRange(7, getToday(), RelativeDateRange.NEXT_7_DAYS), label: 'Next 7 days' },
  { value: getDateRange(14, getToday(), RelativeDateRange.NEXT_14_DAYS), label: 'Next 14 days' }
];

export const getCustomsOptions = (): Option<ShipmentsTableCustomsOnlyValue>[] => {
  return [
    { value: ShipmentsTableCustomsOnlyValue.CUSTOMS_ONLY, label: 'Customs only' },
    { value: ShipmentsTableCustomsOnlyValue.NOT_CUSTOMS_ONLY, label: 'Not customs only' }
  ];
};

export const prepareLocationOptions = (locations: NetworksAssignable[]): Option<string>[] => {
  return locations.map((location) => ({
    value: location.id,
    label: location.label?.long || ''
  }));
};

export const getAdditionalShipmentsTableFilters = (): FilterSection<
  FilterItemType<Pick<ShipmentsTableFiltersValues, 'currentMilestone' | 'customsOnly'>>
> => ({
  items: [
    {
      key: 'currentMilestone',
      title: 'Milestone',
      componentType: 'multi-select',
      props: { options: getMilestoneOptions(), selectAllOption: true }
    },
    getCustomsOnlyFilterItem()
  ]
});

export const renderTitle = (title: string, icon: IconName): ReactNode => (
  <div className="flex items-center space-x-2">
    <Icon icon={icon} /> <div>{title}</div>
  </div>
);

export const getCustomsOnlyFilterItem = (): FilterItemType<Pick<ShipmentsTableFiltersValues, 'customsOnly'>> => ({
  key: 'customsOnly',
  title: 'Customs',
  componentType: 'select',
  props: { options: getCustomsOptions() }
});

type ShipmentsTableDateFilterKey = 'cargoReadyDate' | 'collectionDate' | 'departureDate' | 'arrivalDate' | 'deliveryDate';

export const getShipmentsTableDateFilters = (): FilterSection<
  FilterItemType<Pick<ShipmentsTableFiltersValues, ShipmentsTableDateFilterKey>>
> => ({
  title: renderTitle('Dates and times', 'zicon-calendar'),
  items: [
    {
      key: 'cargoReadyDate',
      title: 'Cargo ready date',
      componentType: 'date-range-picker',
      props: { options: getDateRangeOptions() }
    },
    {
      key: 'collectionDate',
      title: 'Collection date',
      componentType: 'date-range-picker',
      props: { options: getDateRangeOptions() }
    },
    {
      key: 'departureDate',
      title: 'Departure date',
      componentType: 'date-range-picker',
      props: { options: getDateRangeOptions() }
    },
    {
      key: 'arrivalDate',
      title: 'Arrival date',
      componentType: 'date-range-picker',
      props: { options: getDateRangeOptions() }
    },
    {
      key: 'deliveryDate',
      title: 'Delivery date',
      componentType: 'date-range-picker',
      props: { options: getDateRangeOptions() }
    }
  ]
});

export const getShipmentsTableDetailsFilters = (
  carrierScac: ShipmentsTableFiltersCarrierScac[]
): FilterSection<
  FilterItemType<Pick<ShipmentsTableFiltersValues, 'modeOfTransport' | 'incoterms' | 'carrierScac' | 'airShipmentType'>>
> => {
  return {
    title: getShipmentsTableDetailsTitle(),
    items: [
      getModeOfTransportFilterItem(),
      getIncotermsFilterItem(),
      getCarrierScacFilterItem(carrierScac),
      getAirShipmentTypeFilterItem()
    ]
  };
};

export const getShipmentsTableDetailsTitle = () => renderTitle('Shipment details', 'zicon-shipment');

export const getModeOfTransportFilterItem = (): FilterItemType<Pick<ShipmentsTableFiltersValues, 'modeOfTransport'>> => ({
  key: 'modeOfTransport',
  title: 'Mode',
  componentType: 'multi-select',
  props: { options: getModeOfTransportOptions(), selectAllOption: true }
});

export const getCustomersFilterItem = (
  values: ShipmentsTableFilterValue[]
): FilterItemType<Pick<ShipmentsTableFiltersValues, 'customerAccountId'>> => ({
  key: 'customerAccountId',
  title: 'Customer',
  componentType: 'multi-select',
  props: { options: getShipmentFilterValueOptions(values), selectAllOption: true }
});

export const getIncotermsFilterItem = (): FilterItemType<Pick<ShipmentsTableFiltersValues, 'incoterms'>> => ({
  key: 'incoterms',
  title: 'Incoterms',
  componentType: 'multi-select',
  props: { options: getIncotermsOptions(), selectAllOption: true }
});

export const getCarrierScacFilterItem = (
  carrierScac: ShipmentsTableFiltersCarrierScac[]
): FilterItemType<Pick<ShipmentsTableFiltersValues, 'carrierScac'>> => {
  const carrierScacOptions: Option<string>[] =
    carrierScac?.map((scac) => ({
      label: scac,
      value: scac
    })) || [];

  return {
    key: 'carrierScac',
    title: 'Carrier (SCAC)',
    componentType: 'multi-select',
    props: { options: carrierScacOptions }
  };
};

export const getAirShipmentTypeFilterItem = (): FilterItemType<Pick<ShipmentsTableFiltersValues, 'airShipmentType'>> => {
  return {
    key: 'airShipmentType',
    title: 'Air shipment type',
    componentType: 'multi-select',
    props: {
      options: getAirShipmentTypeOptions()
    }
  };
};

type ShipmentsTableLocationFilterConfig = {
  collectionCountries: ShipmentsTableFilterValue[];
  deliveryCountries: ShipmentsTableFilterValue[];
  deliveryLocationProps: Modify<ShipmentsFilterReturnType, { options: Option<string>[] }>;
  destinations: NetworksAssignable[];
  originLocationProps: Modify<ShipmentsFilterReturnType, { options: Option<string>[] }>;
  origins: NetworksAssignable[];
  portOfDestinationProps: ShipmentsFilterReturnType;
  portOfLoadingProps: ShipmentsFilterReturnType;
};

export const getShipmentsTableLocationFilters = (
  config: ShipmentsTableLocationFilterConfig
): FilterSection<
  FilterItemType<
    Pick<
      ShipmentsTableFiltersValues,
      'collectionLocation' | 'portOfLoading' | 'portOfDestination' | 'deliveryLocation' | 'deliveryCountry' | 'collectionCountry'
    >
  >
> => {
  const {
    portOfDestinationProps,
    portOfLoadingProps,
    collectionCountries,
    deliveryCountries,
    deliveryLocationProps,
    originLocationProps
  } = config;

  return {
    title: renderTitle('Locations', 'zicon-pin'),
    items: [
      {
        key: 'collectionLocation',
        title: 'Collection location',
        componentType: 'multi-select',
        props: originLocationProps
      },
      {
        key: 'collectionCountry' as const,
        title: 'Country of collection',
        componentType: 'multi-select' as const,
        props: { options: getShipmentFilterValueOptions(collectionCountries) }
      },
      {
        key: 'portOfLoading',
        title: 'Port of loading',
        componentType: 'multi-select',
        props: portOfLoadingProps
      },
      {
        key: 'portOfDestination',
        title: 'Port of destination',
        componentType: 'multi-select',
        props: portOfDestinationProps
      },
      {
        key: 'deliveryLocation',
        title: 'Delivery location',
        componentType: 'multi-select',
        props: deliveryLocationProps
      },
      {
        key: 'deliveryCountry' as const,
        title: 'Country of delivery',
        componentType: 'multi-select' as const,
        props: { options: getShipmentFilterValueOptions(deliveryCountries) }
      }
    ]
  };
};

export const getShipmentsTableProductFilters = (
  productCategoryProps: ShipmentsFilterReturnType,
  productProps: ShipmentsFilterReturnType
): FilterSection<FilterItemType<Pick<ShipmentsTableFiltersValues, 'productCategory' | 'product'>>> => ({
  title: renderTitle('Products', 'zicon-garments'),
  items: [
    {
      key: 'product',
      title: 'Product',
      componentType: 'multi-select',
      props: { ...productProps, searchPlaceholder: 'Search for product name or SKU code' }
    },
    {
      key: 'productCategory',
      title: 'Product category',
      componentType: 'multi-select',
      props: productCategoryProps
    }
  ]
});

export const getShipmentFilterValueOptions = (filterValues: ShipmentsTableFilterValue[]): Option<string>[] => {
  return filterValues.map((filterValue) => ({
    label: filterValue.value || '',
    value: filterValue.key
  }));
};
