import type { FC, MutableRefObject, ReactNode } from 'react';
import { useRef, useState } from 'react';

import { Button, CollapsibleElement } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

interface Props {
  children?: ReactNode;
  className?: string;
  collapseLabel?: string;
  controlClassNames?: string;
  expandLabel?: string;
  initialDisplayedItems?: number;
  isExpandedInitially?: boolean;
  minDisplayedHeight?: number;
  onToggle?: () => void;
  renderItems?: ReactNode[];
}

const CollapsibleSection: FC<Props> = (props) => {
  const {
    children,
    collapseLabel = 'Collapse',
    className = '',
    expandLabel = 'Expand',
    minDisplayedHeight = 0,
    renderItems,
    initialDisplayedItems = 2,
    isExpandedInitially = false,
    controlClassNames = 'bg-white mt-4 -ml-3',
    onToggle
  } = props;

  const [isExpanded, setIsExpanded] = useState<boolean>(isExpandedInitially);

  const contentRef: MutableRefObject<HTMLDivElement | null> = useRef<HTMLDivElement>(null);
  const shadowClassName: string | undefined = isExpanded
    ? undefined
    : 'absolute bottom-0 right-0 left-0 bg-gradient-to-t from-white to-transparent h-12';
  const label: string = isExpanded ? collapseLabel : expandLabel;

  const handleClick = (): void => {
    setIsExpanded(!isExpanded);
    onToggle?.();
  };

  const renderControls = (): ReactNode => {
    const icon: IconName = isExpanded ? 'zicon-chevron-up-small' : 'zicon-chevron-down-small';

    return (
      <div className={controlClassNames} data-testid="collapse-controls">
        <Button iconLeft={icon} onClick={handleClick} size="compact" variant="ghost">
          {label}
        </Button>
      </div>
    );
  };

  const renderCollapsibleItems = (items: ReactNode[]): ReactNode => {
    const itemsShouldCollapse: boolean = items.length > initialDisplayedItems;
    const collapsedItems: ReactNode = items.slice(initialDisplayedItems);

    if (itemsShouldCollapse) {
      return (
        <div className={className}>
          <div>{items.slice(0, initialDisplayedItems)}</div>
          <CollapsibleElement duration={500} isOpened={isExpanded}>
            <div>{collapsedItems}</div>
          </CollapsibleElement>
          {renderControls()}
        </div>
      );
    }

    return <div className={className}>{renderItems}</div>;
  };

  return (
    <>
      {children && (
        <div className={`relative ${className}`}>
          <div ref={contentRef} data-testid="collapse-content">
            <CollapsibleElement className="relative" collapsedHeight={minDisplayedHeight} duration={500} isOpened={isExpanded}>
              {!isExpanded && <div className={shadowClassName} />}
              {children}
            </CollapsibleElement>
          </div>

          {renderControls()}
        </div>
      )}
      {renderItems && renderCollapsibleItems(renderItems)}
    </>
  );
};

export type { Props as CollapsibleSectionProps };

export default CollapsibleSection;
