import type { FC, ReactNode } from 'react';

import { checkPermission } from '@zen/Auth/authHelper';

import type { Subscriber, SubscriptionStakeholder } from '../types';
import SubscriberListItem from './SubscriberListItem';

interface Props {
  onUnsubscribe: (stakeholder: SubscriptionStakeholder) => void;
  subscribers: Subscriber[];
}

const SubscriberList: FC<Props> = ({ onUnsubscribe, subscribers }) => {
  return (
    <div className="mt-4 h-64 overflow-y-auto" data-testid="subscriber-list">
      {subscribers.map((subscriber: Subscriber): ReactNode => {
        const canUnsubscribe: boolean = subscriber.canUnsubscribe ? checkPermission(subscriber, 'canUnsubscribe') : true;

        return (
          <SubscriberListItem
            key={subscriber.accountMember?.id || subscriber.contactDetails?.id}
            isDisabled={!canUnsubscribe}
            onUnsubscribe={onUnsubscribe}
            stakeholder={subscriber}
          />
        );
      })}
    </div>
  );
};

export default SubscriberList;
