import type { FC } from 'react';

import { ModeOfTransport } from '@zen/Booking';
import { FormInput, FormRadioGroup, useForm } from '@zen/Components';
import type { FormFieldTooltipConfig } from '@zen/Components/Form/FormField';
import type { RadioGroupOption } from '@zen/Components/RadioGroup';

import FormAirlineCarrierSelect from '../FormAirlineCarrierSelect';
import FormCourierSelect from '../FormCourierSelect';
import { getCarrierLabel } from '../PlannedScheduleForm/helpers';
import type { PlannedScheduleValues } from '../PlannedScheduleForm/types';
import { AirScheduleCarrier } from '../PlannedScheduleForm/types';

const airScheduleCarrierOptions: RadioGroupOption[] = [
  { value: AirScheduleCarrier.AIRLINE, label: 'Airline' },
  { value: AirScheduleCarrier.COURIER, label: 'Courier' }
];

interface Props {
  isAirScheduleDisabled: boolean;
}

const AirScheduleFormHeader: FC<Props> = ({ isAirScheduleDisabled }) => {
  const { setFieldValue, values } = useForm<PlannedScheduleValues>();

  const isAirlineCarrier: boolean = values.airScheduleCarrierType === AirScheduleCarrier.AIRLINE;
  const isCourierCarrier: boolean = values.airScheduleCarrierType === AirScheduleCarrier.COURIER;
  const tooltipConfig: FormFieldTooltipConfig | undefined = isAirScheduleDisabled
    ? { content: 'Please cancel the booking and enter a new schedule to change this value.' }
    : undefined;

  const handleChange = (): void => {
    setFieldValue('carrier', null, false);
  };

  return (
    <>
      <FormRadioGroup
        className="inline-block mb-4"
        disabled={isAirScheduleDisabled}
        hideLabel={true}
        name="airScheduleCarrierType"
        onChange={handleChange}
        options={airScheduleCarrierOptions}
        radioAlignment="row"
        tooltipConfig={tooltipConfig}
      />
      {isAirlineCarrier && <FormAirlineCarrierSelect label={getCarrierLabel(ModeOfTransport.AIR)} name="carrier" />}
      {isCourierCarrier && (
        <div className="grid grid-cols-2 gap-4">
          <FormCourierSelect className="flex-1" label="Courier name" name="carrier" />
          <FormInput className="flex-1" label="Tracking number" name="trackingNumber" />
        </div>
      )}
    </>
  );
};

export default AirScheduleFormHeader;
