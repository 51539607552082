import type { NetworksAssignable } from '@zen/Networks';
import { useAccountIds } from '@zen/utils/hooks/useAccountIds';

import { useUsedAssignablesQuery } from '../graphql';
import type { AssignmentTargetTypeEnum, AssignmentTypeValue } from '../types';

interface useUsedAssignablesResult {
  data: NetworksAssignable[];
  loading?: boolean;
}

const useUsedAssignables = (
  domainNames: AssignmentTargetTypeEnum[],
  assignmentTypes: AssignmentTypeValue[]
): useUsedAssignablesResult => {
  const accountIds = useAccountIds();
  const hasAccountId = accountIds.filter(Boolean).length > 0;
  const { data, loading } = useUsedAssignablesQuery({
    variables: {
      networkIds: hasAccountId ? accountIds : undefined,
      targetTypes: domainNames,
      assignmentNames: assignmentTypes
    },
    fetchPolicy: 'no-cache'
  });

  const assignables: NetworksAssignable[] = data?.networksUsedAssignables as NetworksAssignable[];

  return {
    data: assignables,
    loading
  };
};

export default useUsedAssignables;
