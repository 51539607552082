import type { FC } from 'react';

import { Icon } from '@zen/DesignSystem';
import type { IconName } from '@zen/Styleguide';

import type { TransportMode } from './types';

interface Props {
  className?: string;
  mode: TransportMode;
}

const TransportModeIcon: FC<Props> = ({ mode, className }) => {
  const modeOfTransportIconMapping: Record<TransportMode, IconName> = {
    AIR: 'zicon-air',
    OCEAN: 'zicon-ship',
    RAIL: 'zicon-rail',
    ROAD: 'zicon-road',
    TRUCK: 'zicon-road'
  };

  return <Icon className={className} icon={modeOfTransportIconMapping[mode]} />;
};

export type { Props as TransportModeIconProps };

export default TransportModeIcon;
