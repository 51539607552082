import type { FC, ReactNode } from 'react';

import { Label } from '@zen/DesignSystem';

interface Props {
  children?: ReactNode;
  className?: string;
  hasError?: boolean;
  isOptional?: boolean;
  isRequired?: boolean;
  label: ReactNode;
  labelDescription?: ReactNode;
  name?: string;
}

const FormLabel: FC<Props> = (props) => {
  const { label, labelDescription, ...rest } = props;

  return <Label additionalInformation={labelDescription} content={label} {...rest} />;
};

export default FormLabel;
