import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ViewMode } from '@zen/Components/ViewSwitcher';
import type { MenuItemType } from '@zen/DesignSystem';
import { ContextMenu, Dialog } from '@zen/DesignSystem';
import { usePurchaseOrdersCloseOrderMutation, usePurchaseOrdersDeleteOrderMutation } from '@zen/Orders/graphql';
import orderRoutes from '@zen/Routes/orders';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';
import type { Optional } from '@zen/utils/typescript';

interface ModalDataType {
  header: string;
  message: ReactNode;
  onConfirm: () => void;
}

interface Props {
  additionalItems?: MenuItemType[];
  canClose: boolean;
  canRemove: boolean;
  canUpdate: boolean;
  id: string;
  mode: ViewMode;
  orderReferenceNumber: string;
}

export const getCloseText = (orderReferenceNumber: string): ReactNode => (
  <>
    Are you sure you want to close purchase order {orderReferenceNumber}? Closing it means no more lots are expected to be
    fulfilled. This will stop you from being able to book lots, and required actions will be removed from all dashboards.
    <br />
    Once closed, this PO will still be viewable on the platform.
  </>
);

const OrdersContextMenu: FC<Props> = (props) => {
  const { additionalItems = [], id: purchaseOrderId, canClose, canRemove, canUpdate, mode, orderReferenceNumber } = props;

  const [deleteOrder] = usePurchaseOrdersDeleteOrderMutation();
  const [closeOrder] = usePurchaseOrdersCloseOrderMutation();
  const { addSuccess, addError } = useNotification();
  const navigate = useNavigate();

  const [modalData, setModalData] = useState<Optional<ModalDataType>>(undefined);
  const refetchQuery: string = mode === ViewMode.TABLE ? 'orderTableQuery' : 'orderListQuery';

  const handleDeleteAction = async (): Promise<IOkOrErrorResult> =>
    performMutation({
      mutationFn: () => deleteOrder({ variables: { input: { purchaseOrderId } }, refetchQueries: [refetchQuery] }),
      onError: () => addError(),
      onSuccess: () => {
        setModalData(undefined);
        navigate(orderRoutes.orderList.getUrl());

        addSuccess('Purchase order has been deleted.');
      }
    });

  const handleCloseAction = (): Promise<IOkOrErrorResult> =>
    performMutation({
      mutationFn: () => closeOrder({ variables: { input: { purchaseOrderId } }, refetchQueries: [refetchQuery] }),
      onError: () => addError(),
      onSuccess: () => {
        setModalData(undefined);
        navigate(orderRoutes.orderList.getUrl());

        addSuccess('Purchase order has been closed.');
      }
    });

  const handleEditAction = (): void => navigate(orderRoutes.orderEdit.getUrl(purchaseOrderId));

  const closeModal: ModalDataType = {
    header: 'Close a purchase order',
    message: getCloseText(orderReferenceNumber),
    onConfirm: handleCloseAction
  };

  const deleteModal: ModalDataType = {
    header: 'Delete a purchase order',
    message: 'Do you really want to delete this PO? This process cannot be undone.',
    onConfirm: handleDeleteAction
  };

  const menuItems: MenuItemType[] = [
    ...additionalItems,
    ...(canUpdate
      ? [
          {
            icon: 'zicon-edit' as const,
            label: 'Edit',
            onClick: handleEditAction
          }
        ]
      : []),
    ...(canClose
      ? [
          {
            icon: 'zicon-archive' as const,
            label: 'Close',
            onClick: () => {
              setModalData(closeModal);
            },
            addDivider: true
          }
        ]
      : []),
    ...(canRemove
      ? [
          {
            icon: 'zicon-trash' as const,
            label: 'Delete',
            onClick: () => {
              setModalData(deleteModal);
            }
          }
        ]
      : [])
  ];

  const handleConfirm = (): void => {
    if (modalData?.onConfirm) {
      return modalData?.onConfirm();
    }
  };

  if (menuItems.length === 0) {
    return null;
  }

  return (
    <>
      <ContextMenu inline={false} items={menuItems} togglerSize="medium" />
      <Dialog
        header={modalData?.header}
        isOpen={!!modalData}
        message={modalData?.message}
        onClose={() => setModalData(undefined)}
        onConfirm={handleConfirm}
      />
    </>
  );
};

export default OrdersContextMenu;
