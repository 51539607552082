import cx from 'classnames';
import { kebabCase } from 'lodash';
import type { FC, ReactNode, SyntheticEvent } from 'react';
import { useState } from 'react';

import type { BusinessHours } from '@zen/Networks';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { Matcher, TooltipConfig } from '../../DesignSystem';
import { Button, Icon, Modal, TextInput } from '../../DesignSystem';
import type { DateWithTimeFields, TimeOption } from '../DateWithTimeForm';
import DateWithTimeForm from '../DateWithTimeForm';
import { formatDateWithTime } from './helpers';

interface Props {
  businessHours?: Optional<BusinessHours>;
  dateTooltip?: TooltipConfig | TooltipConfig[] | undefined;
  disabled?: boolean;
  disabledDates?: Matcher;
  hasError?: boolean;
  isClearable?: boolean;
  label?: string;
  name?: string;
  onChange: (date: DateWithTimeFields) => void;
  placeholder?: string;
  timeConfig?: TimeOption[];
  timeZone?: Nullable<string>;
  value?: DateWithTimeFields;
}

const DateWithTimePickerInput: FC<Props> = (props) => {
  const {
    businessHours,
    dateTooltip,
    disabled,
    disabledDates,
    hasError,
    isClearable = true,
    label = '',
    name,
    onChange,
    placeholder = 'Jul 5, 2023',
    value,
    timeConfig,
    timeZone
  } = props;

  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const inputClassNames: string = cx({ 'cursor-pointer': !disabled, 'cursor-not-allowed': disabled }, 'min-w-40');
  const showClearIcon: boolean = isClearable && !!value?.date;

  const handleClose = (): void => setIsModalVisible(false);

  const handleClearDate = (event: SyntheticEvent): void => {
    event.stopPropagation();

    onChange({
      date: '',
      time: '',
      startTime: '',
      endTime: ''
    });
  };

  const renderDateWithTimePickerField = (): ReactNode => {
    const actionButton: ReactNode = (
      <Button onClick={handleClose} variant="ghost">
        Cancel
      </Button>
    );
    const handleFormSubmit = (values: DateWithTimeFields): Promise<IOkOrErrorResult> => {
      handleClose();
      onChange(values);

      return Promise.resolve({ ok: {}, error: null });
    };

    return (
      <DateWithTimeForm
        additionalActionButton={actionButton}
        businessHours={businessHours}
        dateTooltip={dateTooltip}
        disabledDates={disabledDates}
        initialValues={value}
        onSubmit={handleFormSubmit}
        timeConfig={timeConfig}
        timeZone={timeZone}
        variant="compact"
      />
    );
  };

  return (
    <div data-testid={`${kebabCase(name)}-date-with-time-picker-input`}>
      <TextInput
        className={inputClassNames}
        data-component="date-with-time-picker-input"
        data-testid="date-with-time-picker-input"
        disabled={disabled}
        error={hasError}
        iconLeft={<Icon className="cursor-pointer text-grey-light" icon="zicon-calendar" />}
        iconRight={showClearIcon && <Icon icon="zicon-close" interactive={true} onClick={handleClearDate} />}
        id={name}
        name={name}
        onClick={() => setIsModalVisible(true)}
        placeholder={placeholder}
        readOnly={true}
        value={formatDateWithTime(value)}
      />
      <Modal isOpen={isModalVisible} onClose={handleClose} title={label} variant="compact">
        {renderDateWithTimePickerField()}
      </Modal>
    </div>
  );
};

export type { Props as DateWithTimePickerInputProps };
export default DateWithTimePickerInput;
