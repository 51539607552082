import type { FC } from 'react';

import { SkeletonLoading } from '@zen/DesignSystem';

const CargoOverviewLoadingSkeleton: FC = () => {
  return (
    <div className="border border-solid rounded border-grey-lighter" data-testid="cargo-overview-loading-skeleton">
      <div className="flex px-4 py-3 border-b border-solid border-grey-lighter">
        <SkeletonLoading height={24} width="w-40" />
      </div>
      <div className="flex">
        <div className="flex-1 px-3 py-8">
          <div className="flex mb-3 ml-16">
            <SkeletonLoading height={16} width="w-20" />
            <SkeletonLoading height={16} width="w-40" />
          </div>
          <div className="mb-4 ml-40">
            <SkeletonLoading className="mb-1" height={16} width="w-64" />
          </div>
          <div>
            {[...Array(3)].map((_, i) => (
              <div key={i} className="flex mb-3 ml-16">
                <SkeletonLoading className="mb-3" height={16} width="w-20" />
                <SkeletonLoading height={16} width="w-40" />
              </div>
            ))}
          </div>
          <div className="mb-4 ml-40">
            <SkeletonLoading className="mb-1" height={16} width="w-64" />
          </div>
          <div className="flex mb-3 ml-16">
            <SkeletonLoading height={16} width="w-20" />
            <SkeletonLoading height={16} width="w-40" />
          </div>
          <div className="mb-4 ml-40">
            <SkeletonLoading className="mb-1" height={16} width="w-40" />
            <SkeletonLoading height={16} width="w-40" />
          </div>
          <div className="flex mb-3 ml-16">
            <SkeletonLoading height={16} width="w-20" />
            <SkeletonLoading height={16} width="w-40" />
          </div>
        </div>
        <div className="w-1/3 p-4 border-l border-solid border-grey-lighter">
          <div className="flex justify-end mb-4">
            <div className="flex">
              <SkeletonLoading height={24} width="w-6" />
              <SkeletonLoading height={24} width="w-6" />
            </div>
          </div>
          {[...Array(6)].map((_, i) => (
            <div key={i} className="mb-4">
              <SkeletonLoading className="mb-1" height={16} width="w-32" />
              <SkeletonLoading height={16} width="w-24" />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CargoOverviewLoadingSkeleton;
