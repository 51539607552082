import { orderBy } from 'lodash';
import pluralize from 'pluralize';
import type { FC, ReactNode } from 'react';

import { bookingDetailsPageCollapsibleConfigKeyName } from '@zen/Booking/BookingDetails/helpers';
import CollapsibleSection from '@zen/Components/CollapsibleSection';
import LoadingMessage from '@zen/Components/LoadingMessage';
import type { Option } from '@zen/DesignSystem';
import { InlineEmptyState } from '@zen/DesignSystem';
import { useCollapsibleElement } from '@zen/utils/hooks/useCollapsibleElement';

import type { Document } from '../types';
import DocumentItem from './DocumentItem';

interface Props {
  canManageDocuments: boolean;
  documentTypes: Option<string>[];
  documents: Document[];
  isGeneratingDocument?: boolean;
  onDelete: (id: string) => void;
  onEdit: (document: Document) => void;
  showDocumentDate?: boolean;
}

const DocumentList: FC<Props> = (props) => {
  const { canManageDocuments, documents, documentTypes, onEdit, onDelete, showDocumentDate = true, isGeneratingDocument } = props;

  const [isExpanded, setIsExpanded] = useCollapsibleElement('documentList', false, bookingDetailsPageCollapsibleConfigKeyName);

  const showEmptyState: boolean = !documents.length && !isGeneratingDocument;

  const sortedDocuments: Document[] = orderBy(documents, ['createdAt'], ['desc']);

  const renderDocumentsList = (): ReactNode[] => {
    return sortedDocuments.map((document: Document, index: number) => (
      <DocumentItem
        key={index}
        canManageDocuments={canManageDocuments}
        document={document}
        documentTypes={documentTypes}
        onDelete={onDelete}
        onEdit={onEdit}
        showDocumentDate={showDocumentDate}
      />
    ));
  };

  return (
    <>
      {showEmptyState && (
        <InlineEmptyState title="There are no documents uploaded for this booking.">
          Documents are uploaded files that provide required further information to shipments.
        </InlineEmptyState>
      )}
      {isGeneratingDocument && (
        <LoadingMessage
          className="mb-5 bg-grey-lightest p-7"
          message="Your document is being generated and will be available shortly."
        />
      )}
      <CollapsibleSection
        collapseLabel="Hide documents"
        expandLabel={`Show all ${documents.length} ${pluralize('document', documents.length)}`}
        isExpandedInitially={isExpanded}
        onToggle={() => setIsExpanded(!isExpanded)}
        renderItems={renderDocumentsList()}
      />
    </>
  );
};

export type { Props as DocumentListProps };
export default DocumentList;
