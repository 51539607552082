import { truncate, upperFirst } from 'lodash';
import type { FC, ReactNode } from 'react';
import React, { useState } from 'react';

import { Icon, Tooltip } from '@zen/DesignSystem';
import type { Nullable, Optional } from '@zen/utils/typescript';

import type { ActivityFeedUser } from '../../types';
import ActivityFeedVisibleParties from '../ActivityFeedVisibleParties';
import Timestamp from '../Timestamp';
import UserDetails from '../UserDetails';

export interface Props {
  additionalText?: Optional<string>;
  createdAt: string;
  icon: ReactNode;
  resolvedAt?: Optional<string>;
  subtitle?: ReactNode;
  title: ReactNode;
  user?: Nullable<ActivityFeedUser>;
}

const ActivityFeedItemDetails: FC<Props> = (props) => {
  const { createdAt, icon, resolvedAt = undefined, subtitle = undefined, title, user = undefined, additionalText = null } = props;

  const [showAdditonalText, setShowAdditionalText] = useState<boolean>(false);
  const additionalTextTurncateLength: number = 80;
  const showViewMore: boolean = additionalText ? additionalText.length > additionalTextTurncateLength : false;

  const tooltipContent: ReactNode = (
    <div data-testid="tooltip-content">
      <div className="pt-4 pl-4">
        <Timestamp date={createdAt} resolvedAt={resolvedAt} />
      </div>
      <ActivityFeedVisibleParties />
    </div>
  );

  const getTitle = (): ReactNode => {
    const isTitleReactNode: boolean = React.isValidElement(title);

    return isTitleReactNode ? title : <span className="font-bold">{title && upperFirst(title.toString())}</span>;
  };

  return (
    <div className="flex" data-testid="activity-feed-details">
      <div className="z-50 mr-4 text-2xl" style={{ marginLeft: '-0.82rem' }}>
        {icon}
      </div>
      <div>
        <div className="flex items-baseline leading-normal">
          {getTitle()}
          {user && <UserDetails showByWhom={true} user={user} />}
          <Tooltip tooltipContent={tooltipContent}>
            <div className="relative ml-2 cursor-pointer">
              <Icon className="text-navy-base" icon="zicon-information" />
            </div>
          </Tooltip>
        </div>

        {subtitle && (
          <div className="pt-2 text-sm text-grey-base" data-testid="details-subtitle">
            {subtitle}
          </div>
        )}

        {additionalText && (
          <div className="pt-2 text-sm leading-normal text-grey-base">
            {showAdditonalText && additionalText}
            {!showAdditonalText && truncate(additionalText, { length: additionalTextTurncateLength })}
            {showViewMore && (
              <div className="underline cursor-pointer text-navy-base" onClick={() => setShowAdditionalText(!showAdditonalText)}>
                {!showAdditonalText && '+ View more'}
                {showAdditonalText && '- View less'}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivityFeedItemDetails;
