import type { FC, ReactNode } from 'react';

import type { ModeOfTransport } from '@zen/Booking';
import { isOceanBooking } from '@zen/Booking';
import type { CoreCargo } from '@zen/Cargo';
import { CargoModeEnum } from '@zen/Cargo';
import JourneyDetails from '@zen/Journey/components/JourneyDetails';
import JourneyDetailsProvider from '@zen/Journey/components/JourneyDetails/JourneyDetailsProvider';
import NoJourney from '@zen/Journey/components/NoJourney';
import type { JourneyMilestoneWithMetadata, JourneyShippingMilestone } from '@zen/Journey/types';
import { JourneyStopActionEnum } from '@zen/Journey/types';
import type { Optional } from '@zen/utils/typescript';

import type { CargoOverviewCargoReadyDate, CargoSummary } from '../../types';
import CargoDetails from '../CargoDetails';
import OceanTrackingStatus from '../OceanTrackingStatus';
import RoadTrackingStatus from '../RoadTrackingStatus';

interface Props {
  canManageCargo: boolean;
  canViewShipmentTracking: boolean;
  cargo: CoreCargo;
  cargoCount: number;
  cargoMode?: Optional<CargoModeEnum>;
  cargoReadyDate: Optional<CargoOverviewCargoReadyDate>;
  cargoSummary: Optional<CargoSummary>;
  customsOnly: boolean;
  hasJourneyInformation: boolean;
  modeOfTransport: Optional<ModeOfTransport>;
  zencargoReference: string;
}

const CargoAndRouteDetails: FC<Props> = (props) => {
  const {
    canManageCargo,
    canViewShipmentTracking,
    cargo,
    cargoCount,
    cargoReadyDate,
    cargoMode,
    cargoSummary,
    customsOnly,
    hasJourneyInformation,
    modeOfTransport,
    zencargoReference
  } = props;

  const prepareShippingMilestones = (
    milestones: JourneyShippingMilestone[],
    stopAction: JourneyStopActionEnum
  ): JourneyMilestoneWithMetadata[] => {
    return milestones.map((milestone) => ({
      ...milestone,
      stopAction
    }));
  };

  const preLegsShippingMilestones: JourneyMilestoneWithMetadata[] = prepareShippingMilestones(
    cargo.journey?.preLegsShippingMilestones || [],
    JourneyStopActionEnum.COLLECTION
  );

  const postLegsShippingMilestones: JourneyMilestoneWithMetadata[] = prepareShippingMilestones(
    cargo.journey?.postLegsShippingMilestones || [],
    JourneyStopActionEnum.DELIVERY
  );

  const renderJourney = (): ReactNode => {
    if (customsOnly) {
      return;
    }

    if (!hasJourneyInformation) {
      return <NoJourney isLegacyCargo={!!cargo?.legacyCargo} modeOfTransport={modeOfTransport} />;
    }

    return (
      <div className="flex flex-col p-6 2xl:w-2/3" data-journey-mode-of-transport={modeOfTransport} data-testid="journey-details">
        {canViewShipmentTracking && (
          <>
            {cargoMode === CargoModeEnum.FTL && <RoadTrackingStatus zencargoReference={zencargoReference} />}
            {isOceanBooking(modeOfTransport) && <OceanTrackingStatus zencargoReference={zencargoReference} />}
          </>
        )}
        <JourneyDetails
          cargoReadyDate={cargoReadyDate}
          lastUpdated={cargo.journey?.lastUpdated}
          legs={cargo.journey?.legs || []}
          modeOfTransport={modeOfTransport}
          postLegsShippingMilestones={postLegsShippingMilestones}
          preLegsShippingMilestones={preLegsShippingMilestones}
          zencargoReference={zencargoReference}
        />
      </div>
    );
  };

  return (
    <JourneyDetailsProvider
      cargoCount={cargoCount}
      cargoId={cargo.id}
      cargoMode={cargoMode}
      cargoReadyDate={cargoReadyDate?.latestEstimate?.startDateTime.date}
      legs={cargo.journey?.legs || []}
      modeOfTransport={modeOfTransport}
      postLegsShippingMilestones={postLegsShippingMilestones}
      preLegsShippingMilestones={preLegsShippingMilestones}
      zencargoReference={zencargoReference}
    >
      <div className="relative flex flex-col-reverse 2xl:flex-row">
        {renderJourney()}
        <CargoDetails
          canManageCargo={canManageCargo}
          cargo={cargo}
          cargoMode={cargoMode}
          cargoSummary={cargoSummary}
          isCompactView={!customsOnly}
          modeOfTransport={modeOfTransport}
          zencargoReference={zencargoReference}
        />
      </div>
    </JourneyDetailsProvider>
  );
};

export default CargoAndRouteDetails;
