import type { FC } from 'react';

import BookingCoordinatorSelect from '@zen/Components/BookingCoordinatorSelect';
import { ManagerTeam } from '@zen/Components/BookingCoordinatorSelect/types';
import QueryHandler from '@zen/Components/QueryHandler';
import { Card, SkeletonLoading } from '@zen/DesignSystem';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import {
  useAssignCustomerCoordinatorMutation,
  useAssignOperationsCoordinatorMutation,
  useGetCurrentBookingCoordinatorsQuery
} from './graphql';
import type { CurrentCoordinators } from './types';

export interface BookingCoordinatorsPolicies {
  canSetCustomerCoordinator: boolean;
  canSetOperationsCoordinator: boolean;
  canViewCustomerCoordinator: boolean;
  canViewOperationsCoordinator: boolean;
}

interface Props {
  permissions: BookingCoordinatorsPolicies;
  zencargoReference: string;
}

const BookingCoordinators: FC<Props> = (props) => {
  const {
    zencargoReference,
    permissions: {
      canSetCustomerCoordinator,
      canSetOperationsCoordinator,
      canViewCustomerCoordinator,
      canViewOperationsCoordinator
    }
  } = props;

  const { addSuccess, addError } = useNotification();

  const { data, loading, error, refetch } = useGetCurrentBookingCoordinatorsQuery({
    variables: {
      zencargoReferences: [zencargoReference]
    }
  });

  const [assignOperationsCoordinator] = useAssignOperationsCoordinatorMutation({
    refetchQueries: ['getBookingTradeParties'],
    awaitRefetchQueries: true
  });

  const [assignCustomerCoordinator] = useAssignCustomerCoordinatorMutation({
    refetchQueries: ['getBookingTradeParties'],
    awaitRefetchQueries: true
  });

  const handleSuccess = (message: string): void => {
    addSuccess(message);
    refetch();
  };

  const handleUpdateOperationsCoordinator = async (coordinatorId: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        assignOperationsCoordinator({
          variables: { input: { zencargoReference, managerId: coordinatorId } }
        }),
      onError: () => addError('Unable to update operations coordinator.'),
      onSuccess: () => handleSuccess('Operations coordinator updated.')
    });
  };

  const handleUpdateCustomerCoordinator = async (coordinatorId: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        assignCustomerCoordinator({
          variables: { input: { zencargoReference, managerId: coordinatorId } }
        }),
      onSuccess: () => handleSuccess('Customer coordinator updated.'),
      onError: () => addError('Unable to update customer coordinator.')
    });
  };

  if (!canViewOperationsCoordinator && !canViewCustomerCoordinator) {
    return null;
  }

  return (
    <QueryHandler
      data={data?.bookings?.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={<SkeletonLoading className="my-5" height={50} />}
      noResults={null}
    >
      {(currentCoordinators: CurrentCoordinators) => {
        return (
          <Card title="Zencargo coordinators">
            <div className="grid gap-4">
              {canViewOperationsCoordinator && (
                <BookingCoordinatorSelect
                  currentCoordinator={currentCoordinators?.operationsManager?.id}
                  disabled={!canSetOperationsCoordinator}
                  label="Operations coordinator"
                  name="operationsCoordinator"
                  onCoordinatorUpdate={handleUpdateOperationsCoordinator}
                  type={ManagerTeam.OPERATIONS}
                />
              )}
              {canViewCustomerCoordinator && (
                <BookingCoordinatorSelect
                  currentCoordinator={currentCoordinators?.commercialManager?.id}
                  disabled={!canSetCustomerCoordinator}
                  label="Customer coordinator"
                  name="customerCoordinator"
                  onCoordinatorUpdate={handleUpdateCustomerCoordinator}
                  type={ManagerTeam.COMMERCIAL}
                />
              )}
            </div>
          </Card>
        );
      }}
    </QueryHandler>
  );
};

export default BookingCoordinators;
