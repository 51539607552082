import type { ChangeEvent, FC, ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { Container, TextArea } from '@zen/DesignSystem';

interface Props {
  editable: boolean;
  onChange?: (value: string) => void;
  value?: string;
}

const RateCardNotes: FC<Props> = ({ onChange, value = '', editable }) => {
  const [note, setNote] = useState<string>('');

  useEffect(() => {
    setNote(value);
  }, [value]);

  const handleChange = ({ currentTarget }: ChangeEvent<HTMLTextAreaElement>): void => {
    setNote(currentTarget.value);
  };

  const submitValue = () => {
    onChange?.(note);
  };

  const renderContent = (): ReactNode => {
    if (editable) {
      return (
        <TextArea
          className="border-0 p-0"
          data-testid="text-area"
          onBlur={submitValue}
          onChange={handleChange}
          rows={4}
          value={note}
        />
      );
    }

    const paragraphs: Array<string> = value.split('\n');

    return (
      <div className="p-4">
        {paragraphs.map((paragraph: string, index: number) => (
          <p key={index}>{paragraph}</p>
        ))}
      </div>
    );
  };

  const subtitle: string | undefined = editable
    ? 'Please note any conditions that apply to bookings made against this rate card.'
    : undefined;

  return (
    <Container compactView={true} subtitle={subtitle} title="Note">
      <div className="leading-6 text-sm p-2">{renderContent()}</div>
    </Container>
  );
};

export default RateCardNotes;
