import type { FC } from 'react';

import { SkeletonLoading } from '@zen/DesignSystem';

import BookingSummaryLoadingSkeleton from '../BookingSummaryLoadingSkeleton';

const BookingOverviewLoadingSkeleton: FC = () => {
  return (
    <div className="border border-solid rounded border-grey-lighter" data-testid="booking-overview-loading-skeleton">
      <div className="flex px-4 pt-6 border-b border-solid border-grey-lighter">
        {[...Array(5)].map((_, i) => (
          <SkeletonLoading key={i} height={24} width="w-20" />
        ))}
      </div>
      <BookingSummaryLoadingSkeleton />
    </div>
  );
};

export default BookingOverviewLoadingSkeleton;
