import type { FC } from 'react';

import FilterComponent from '@zen/Components/Filters';
import type { FilterItemType } from '@zen/Components/Filters/types';
import type { RateCard } from '@zen/RateCards/LegacyRateCards/types';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';

import { buildFilters, prepareSelectableValues } from './helpers';
import type { LegacyRateCardFilters } from './types';

interface Props {
  rateCard: RateCard;
}

const Filters: FC<Props> = ({ rateCard }) => {
  const { filters, setFilters } = useUrlFilters<LegacyRateCardFilters>();
  const filterItems: FilterItemType<LegacyRateCardFilters>[] = buildFilters(prepareSelectableValues(rateCard));

  const submitFilters = (values: Partial<LegacyRateCardFilters>) => {
    setFilters(values);
  };

  return <FilterComponent items={filterItems} onChange={submitFilters} value={filters} />;
};

export default Filters;
