import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { scroller } from 'react-scroll';

import { checkPermission } from '@zen/Auth/authHelper';
import { isRoadBooking } from '@zen/Booking';
import type { CargoTransition, CoreCargo, ModeOfTransport } from '@zen/Cargo';
import { CargoModeEnum } from '@zen/Cargo';
import transitionCargo from '@zen/Cargo/cargoTransition.helper';
import QueryHandler from '@zen/Components/QueryHandler';
import type { TabViewConfig } from '@zen/DesignSystem';
import { Container, ContainerWithTabs } from '@zen/DesignSystem';
import JourneyActions from '@zen/Journey/components/JourneyActions';
import { useConsumeLocationState } from '@zen/utils/hooks/useConsumeLocationState';
import type { Optional } from '@zen/utils/typescript';

import CargoOverviewLoadingSkeleton from '../components/skeletons/CargoOverviewLoadingSkeleton';
import type { BookingStageEnum } from '../types';
import { CargoOverviewContext } from './CargoOverviewContext';
import CargoActions from './components/CargoActions';
import CargoAndRouteDetails from './components/CargoAndRouteDetails';
import NoResults from './components/NoResults';
import { useCargoOverviewQuery } from './graphql';
import { getCargoOverviewContext, getTabsConfig, hasJourneyData } from './helpers';
import type { CargoJourney, CargoOverviewData } from './types';

interface Props {
  bookingStage: Optional<BookingStageEnum>;
  customsOnly: boolean;
  modeOfTransport: Optional<ModeOfTransport>;
  zencargoReference: string;
}

const CargoOverview: FC<Props> = ({ bookingStage, customsOnly, modeOfTransport, zencargoReference }) => {
  const [highlightedCargo, setHighlightedCargo] = useState<string>('');

  const { data, loading, error, refetch } = useCargoOverviewQuery({
    fetchPolicy: 'no-cache',
    variables: { zencargoReference }
  });

  const cargoOverviewContainerId: string = 'cargo-journey-details';

  const handleTabChange = () => refetch();

  useConsumeLocationState((highlightedCargoId: string) => {
    if (highlightedCargoId) {
      setHighlightedCargo(highlightedCargoId);
      scroller.scrollTo(cargoOverviewContainerId, { smooth: true, offset: -140 });
    }
  }, 'highlightedCargoId');

  return (
    <QueryHandler
      data={data?.bookings?.nodes?.[0]}
      error={!!error}
      isLoading={loading}
      loadingComponent={<CargoOverviewLoadingSkeleton />}
    >
      {(booking: CargoOverviewData) => {
        const {
          accountUuid,
          calculatedInfo,
          canAddCargo: canAddCargoCurrently,
          canToggleCollectionCurrently,
          cargo,
          cargoJourneys,
          coreCargos,
          customer,
          isCollectionEnabled,
          masterBillOfLading
        } = booking;
        const canManageCargo: boolean = checkPermission<CargoOverviewData>(booking, 'canManageCargo');
        const canViewShipmentTracking: boolean = checkPermission<CargoOverviewData>(booking, 'canViewShipmentTracking');
        const cargos: Optional<CargoTransition> = transitionCargo(cargo, coreCargos);
        const cargoMode: Optional<CargoModeEnum> = cargos?.mode;
        const cargoList: CoreCargo[] = (cargos?.cargoList || []).map((coreCargo) => {
          return {
            ...coreCargo,
            journey: cargoJourneys?.journeys?.find((journey: CargoJourney) => journey.cargo?.id === coreCargo.id)?.journey
          };
        }) as CoreCargo[];
        const cargoCount: number = cargoList.length || 0;
        const cargoReadyDate = cargoJourneys?.cargoReadyDate;
        const title: string = 'Route and cargo details';
        const accountId: string = customer?.uuid || accountUuid || '';
        const hasCargo = cargoCount > 0;
        const hasJourneyInformation: boolean = hasJourneyData(booking);
        const isFTL = cargoMode === CargoModeEnum.FTL;
        const canAddCargo: boolean = canManageCargo && hasCargo && !isFTL;
        const canToggleCollection: boolean = !!booking.canToggleCollection && !isRoadBooking(modeOfTransport) && hasCargo;

        const renderCargoActions = (): ReactNode => {
          if (!cargoMode || !modeOfTransport) return;

          return (
            <>
              <CargoActions
                canAddCargo={canAddCargo}
                canAddCargoCurrently={canAddCargoCurrently}
                canToggleCollection={canToggleCollection}
                canToggleCollectionCurrently={canToggleCollectionCurrently}
                cargoMode={cargoMode}
                isCollectionEnabled={!!isCollectionEnabled}
                modeOfTransport={modeOfTransport}
                onCargoAdded={setHighlightedCargo}
                zencargoReference={zencargoReference}
              />
              <JourneyActions />
            </>
          );
        };

        const tabsConfig: TabViewConfig[] = getTabsConfig(
          {
            canManageCargo,
            canViewShipmentTracking,
            cargoList,
            cargoMode,
            cargoReadyDate,
            cargoSummary: calculatedInfo.cargoSummary,
            customsOnly,
            hasJourneyInformation,
            masterBillOfLading,
            modeOfTransport,
            zencargoReference
          },
          highlightedCargo
        );

        const renderCargoContent = (): ReactNode => {
          if (!hasCargo && cargoMode && modeOfTransport) {
            return (
              <NoResults
                canManageCargo={canManageCargo}
                cargoMode={cargoMode}
                modeOfTransport={modeOfTransport}
                zencargoReference={zencargoReference}
              />
            );
          }

          return (
            <CargoAndRouteDetails
              canManageCargo={canManageCargo}
              canViewShipmentTracking={canViewShipmentTracking}
              cargo={cargoList[0]}
              cargoCount={cargoCount}
              cargoMode={cargoMode}
              cargoReadyDate={cargoReadyDate}
              cargoSummary={calculatedInfo.cargoSummary}
              customsOnly={customsOnly}
              hasJourneyInformation={hasJourneyInformation}
              modeOfTransport={modeOfTransport}
              zencargoReference={zencargoReference}
            />
          );
        };

        const renderContainer = (): ReactNode => {
          return (
            <Container actions={renderCargoActions()} compactView={true} id={cargoOverviewContainerId} title={title}>
              {renderCargoContent()}
            </Container>
          );
        };

        const renderContainerWithTabs = (): ReactNode => {
          return (
            <ContainerWithTabs
              actions={renderCargoActions()}
              compactView={true}
              id={cargoOverviewContainerId}
              onTabChange={handleTabChange}
              tabViewConfig={tabsConfig}
              title={title}
            />
          );
        };

        return (
          <CargoOverviewContext.Provider
            value={getCargoOverviewContext({
              accountId,
              bookingStage,
              cargoReadyDate: cargoReadyDate?.latestEstimate?.startDateTime?.date,
              cargoList,
              isCollectionEnabled,
              modeOfTransport,
              zencargoReference
            })}
          >
            {cargoCount > 1 ? renderContainerWithTabs() : renderContainer()}
          </CargoOverviewContext.Provider>
        );
      }}
    </QueryHandler>
  );
};

export default CargoOverview;
