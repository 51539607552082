import type { FC } from 'react';

import { SkeletonLoading } from '@zen/DesignSystem';

const NotesLoadingSkeleton: FC = () => {
  return (
    <div className="border border-solid rounded border-grey-lighter" data-testid="notes-loading-skeleton">
      <div className="p-3 border-b border-solid border-grey-lighter">
        <SkeletonLoading className="mb-1" height={24} width="w-20" />
        <SkeletonLoading height={16} width="w-32" />
      </div>
      <div className="flex flex-col h-64 p-3" />
      <div className="h-10 border-t border-b border-solid bg-grey-lightest border-grey-lighter" />
      <div className="p-4">
        <SkeletonLoading height={24} width="w-32" />
      </div>
    </div>
  );
};

export default NotesLoadingSkeleton;
