import type { FC } from 'react';

import { SkeletonLoading } from '@zen/DesignSystem';

import CargoJourneysSummaryLoadingSkeleton from '../CargoJourneysSummaryLoadingSkeleton';

const BookingSummaryLoadingSkeleton: FC = () => {
  return (
    <div className="flex" data-testid="booking-summary-loading-skeleton">
      <div className="flex-1 px-3 py-8">
        <div>
          {[...Array(3)].map((_, i) => (
            <div key={i} className="flex mb-1 ml-28">
              <div>
                <SkeletonLoading height={16} width="w-20" />
              </div>
              <div>
                <SkeletonLoading height={16} width="w-40" />
              </div>
            </div>
          ))}
        </div>
        <CargoJourneysSummaryLoadingSkeleton />
      </div>
      <div className="w-1/3 p-4 border-l border-solid border-grey-lighter">
        {[...Array(7)].map((_, i) => (
          <div key={i} className="mb-4">
            <SkeletonLoading className="mb-1" height={16} width="w-32" />
            <SkeletonLoading height={16} width="w-24" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default BookingSummaryLoadingSkeleton;
