import type { FC, ReactNode } from 'react';

import type { PageInfo } from '@zen/types';

import Pagination from '../../Pagination';

interface Props {
  paginationInfo?: PageInfo;
  totalCount: ReactNode;
}

const TableFooter: FC<Props> = ({ paginationInfo, totalCount }) => {
  return (
    <div className="px-6 py-4 flex justify-between items-center" data-testid="table-footer">
      <div className="text-xs leading-normal text-grey-dark" data-testid="total-count">
        {totalCount}
      </div>
      <div>{paginationInfo && <Pagination pageInfo={paginationInfo} />}</div>
    </div>
  );
};

export default TableFooter;
