import cx from 'classnames';
import type { FC, ReactNode } from 'react';

import { Headline } from '../../Typography';

interface Props {
  actions?: ReactNode;
  classNames?: string;
  title?: ReactNode;
}

const TableHeader: FC<Props> = ({ actions, classNames, title }) => {
  const renderHeaderInformation: boolean = !!title;

  const wrapperClassNames: string = cx(
    {
      'justify-between': renderHeaderInformation,
      'justify-end': !renderHeaderInformation
    },
    'flex items-center px-6 py-4 min-h-[3.5rem]'
  );

  return (
    <div className={wrapperClassNames}>
      {renderHeaderInformation && (
        <div className="flex flex-col whitespace-nowrap justify-between">
          {title && (
            <Headline className={classNames} level={4}>
              {title}
            </Headline>
          )}
        </div>
      )}
      {actions && (
        <div className="w-full flex justify-end" data-testid="table-header-actions">
          {actions}
        </div>
      )}
    </div>
  );
};

export default TableHeader;
