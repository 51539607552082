import type { FC } from 'react';

import { SkeletonLoading } from '@zen/DesignSystem';

interface Props {
  count?: number;
}
const EventLogSkeleton: FC<Props> = ({ count = 8 }) => (
  <div className="mx-4 my-6">
    {[...Array(count)].map((_, i) => (
      <div key={i} className="flex my-3 space-x-2">
        <div data-testid="icon-skeleton">
          <SkeletonLoading circle={true} height={24} width="w-6" />
        </div>
        <div data-testid="event-details-skeleton">
          <SkeletonLoading height={16} width="w-96" />
          <SkeletonLoading className="mt-1" height={8} width="w-48" />
        </div>
      </div>
    ))}
  </div>
);

export default EventLogSkeleton;
