import { renderApplicabilities } from '../components/helpers';
import type { RateCardCharge } from '../reducer';
import type { RateCardChargeItemTypes, RateCardChargeItemTypesKeys } from '../reducer/types';
import type { RateCardDetails } from '../types';
import type { ChargeBasis, FormattedRateCardDetails } from './types';
import { ChargeClassification } from './types';

export const renderChargeBasis = (chargeBasis: ChargeBasis[]) => {
  return chargeBasis.map((chargeBasisItem: ChargeBasis) => chargeBasisItem.name).join(', ');
};

const sortCharge = (firstCharge: RateCardCharge, secondCharge: RateCardCharge, isEditable: boolean): number => {
  const firstChargeFromLocation: string = firstCharge.fromLocation?.label?.long || '';
  const secondChargeFromLocation: string = secondCharge.fromLocation?.label?.long || '';
  const firstChargeToLocation: string = firstCharge.toLocation?.label?.long || '';
  const secondChargeToLocation: string = secondCharge.toLocation?.label?.long || '';

  const firstChargeApplicabilities: string = isEditable ? renderApplicabilities(firstCharge.applicability) : '';
  const secondChargeApplicabilities: string = isEditable ? renderApplicabilities(secondCharge.applicability) : '';
  const firsChargeBasis: string = renderChargeBasis(firstCharge.chargeBasis);
  const secondChargeBasis: string = renderChargeBasis(secondCharge.chargeBasis);

  return (
    firstChargeFromLocation.localeCompare(secondChargeFromLocation) ||
    firstChargeToLocation.localeCompare(secondChargeToLocation) ||
    firstChargeApplicabilities.localeCompare(secondChargeApplicabilities) ||
    firstCharge.chargeType?.name.localeCompare(secondCharge.chargeType?.name) ||
    firstCharge.itemName.localeCompare(secondCharge.itemName) ||
    firsChargeBasis.localeCompare(secondChargeBasis)
  );
};

export const formatCharges = (charges: RateCardCharge[], isEditable: boolean = false): RateCardCharge[] => {
  return charges.sort((firstCharge: RateCardCharge, secondCharge: RateCardCharge) =>
    sortCharge(firstCharge, secondCharge, isEditable)
  );
};

const mapRateCardToIncludeChargesGroups = (charges: RateCardCharge[], isEditable: boolean): RateCardChargeItemTypes => {
  const chargeGroupsMapping: Record<RateCardChargeItemTypesKeys, RateCardCharge[]> = {
    freightCharges: [],
    originHaulageCharges: [],
    originCharges: [],
    destinationCharges: [],
    destinationHaulageCharges: [],
    otherCharges: []
  };

  const classificationToChargeListMapping: Record<ChargeClassification, RateCardCharge[]> = {
    [ChargeClassification.FREIGHT]: chargeGroupsMapping.freightCharges,
    [ChargeClassification.ORIGIN_HAULAGE]: chargeGroupsMapping.originHaulageCharges,
    [ChargeClassification.ORIGIN_PORT]: chargeGroupsMapping.originCharges,
    [ChargeClassification.ORIGIN]: chargeGroupsMapping.originCharges,
    [ChargeClassification.DESTINATION]: chargeGroupsMapping.destinationCharges,
    [ChargeClassification.DESTINATION_PORT]: chargeGroupsMapping.destinationCharges,
    [ChargeClassification.DESTINATION_HAULAGE]: chargeGroupsMapping.destinationHaulageCharges,
    [ChargeClassification.OTHER]: chargeGroupsMapping.otherCharges
  };

  charges.forEach((charge: RateCardCharge) => {
    const { chargeClassification } = charge;

    if (chargeClassification) {
      classificationToChargeListMapping[chargeClassification as ChargeClassification].push(charge);
    }
  });

  return {
    freightCharges: formatCharges(chargeGroupsMapping.freightCharges, isEditable),
    originHaulageCharges: formatCharges(chargeGroupsMapping.originHaulageCharges, isEditable),
    originCharges: formatCharges(chargeGroupsMapping.originCharges, isEditable),
    destinationCharges: formatCharges(chargeGroupsMapping.destinationCharges, isEditable),
    destinationHaulageCharges: formatCharges(chargeGroupsMapping.destinationHaulageCharges, isEditable),
    otherCharges: formatCharges(chargeGroupsMapping.otherCharges, isEditable)
  };
};

export const updateRateCardsChargePricing = (charge: RateCardCharge, isEditable: boolean): RateCardCharge => {
  const { currency: customCurrency, defaultCharge, unitPrice: customUnitPrice } = charge;
  const defaultUnitPrice: number | null = !isEditable && defaultCharge?.unitPrice ? defaultCharge.unitPrice : null;

  return {
    ...charge,
    currency: customCurrency !== defaultCharge?.currency ? customCurrency : defaultCharge?.currency,
    unitPrice: customUnitPrice !== defaultCharge?.unitPrice ? customUnitPrice : defaultUnitPrice
  };
};

const updateRateCardsChargesPricing = (charges: RateCardCharge[], isEditable: boolean): RateCardCharge[] => {
  return charges.map((charge: RateCardCharge) => updateRateCardsChargePricing(charge, isEditable));
};

export const formatRateCardData = (rateCard: RateCardDetails, isEditable: boolean = true): FormattedRateCardDetails => {
  const { charges, ...rest } = rateCard;

  const formattedRateCardCharges: RateCardCharge[] = updateRateCardsChargesPricing(charges, isEditable);
  const rateCardWithChargesGroups: RateCardChargeItemTypes = mapRateCardToIncludeChargesGroups(
    formattedRateCardCharges,
    isEditable
  );

  return {
    ...rest,
    ...rateCardWithChargesGroups
  };
};
