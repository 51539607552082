import type { FC, ReactNode } from 'react';

import { Role } from '@zen/Auth';
import useRole from '@zen/Auth/hooks/useRole';
import Page from '@zen/Components/Page';
import QueryHandler from '@zen/Components/QueryHandler';
import { Button, IconButton } from '@zen/DesignSystem';
import RateCardNotes from '@zen/RateCards/RateCardNotes';
import TermsAndConditionsLink from '@zen/RateCards/TermsAndConditionsLink';
import { rateCardRoutes } from '@zen/Routes';
import useUrlFilters from '@zen/utils/hooks/useUrlFilters';
import { useNavigationHistory } from '@zen/utils/NavigationHistory';

import { exportRateCardData } from '../ExportRateCard';
import FreightChargeTable from '../FreightCharge/FreightChargeTable';
import { useRateCardDetails } from '../graphql/hooks';
import HaulageTable from '../HaulageCharge/HaulageTable';
import { mapHaulageCharges } from '../helpers';
import PortChargeTable from '../PortCharge/PortChargeTable';
import RateCardActions from '../RateCardActions/RateCardActions';
import type { PortChargePayload } from '../RateCardForm/reducer/types';
import type { RateCard } from '../types';
import Filters from './Filters';
import type { LegacyRateCardFilters } from './Filters/types';
import RateCardOverviewDetails from './RateCardOverviewDetails';

const RateCardDetails: FC = () => {
  const { filters } = useUrlFilters<LegacyRateCardFilters>();
  const { data, loading, error } = useRateCardDetails({ ...filters });
  const { navigateBack } = useNavigationHistory();
  const role = useRole();
  const allowEdit: boolean = role === Role.ADMIN;

  const handleDeleteCompleted = (): void => {
    navigateBack(rateCardRoutes.index.getUrl());
  };

  return (
    <QueryHandler data={data?.legacyGetRateCard} error={!!error} isLoading={loading}>
      {(rateCard: RateCard) => {
        const { originHaulageCharges, destinationHaulageCharges, rateCardId, freightCharges, name, note } = rateCard;
        const shouldDisplayOriginCharges: boolean = !!rateCard.originCharges?.length;
        const shouldDisplayDestinationCharges: boolean = !!rateCard.destinationCharges?.length;
        const shouldDisplayOriginHaulageCharges: boolean = !!originHaulageCharges?.length;
        const shouldDisplayDestinationHaulageCharges: boolean = !!destinationHaulageCharges?.length;

        const handleExport = (): void => {
          exportRateCardData(rateCard);
        };

        const preparePortCharges = (portCharges: RateCard['originCharges' | 'destinationCharges']): PortChargePayload[] =>
          portCharges?.filter((portCharge) => !portCharge.excluded) || [];

        const originCharges: PortChargePayload[] = preparePortCharges(rateCard.originCharges);
        const destinationCharges: PortChargePayload[] = preparePortCharges(rateCard.destinationCharges);

        const renderActionButtons = (): ReactNode => {
          if (!allowEdit) {
            return (
              <Button iconLeft="zicon-download" onClick={handleExport}>
                Export rate card
              </Button>
            );
          }

          return (
            <RateCardActions onDeleteCompleted={handleDeleteCompleted}>
              {({ handleClone, handleDelete, handleEdit }) => {
                return (
                  <div className="flex flex-col items-end space-y-2">
                    <div className="flex space-x-2 text-navy-base">
                      <IconButton
                        icon="zicon-copy"
                        onClick={() => handleClone(rateCardId)}
                        popoverPlacement="top-end"
                        size="medium"
                        title="Clone rate card"
                        variant="ghost"
                      />
                      <IconButton
                        icon="zicon-download"
                        onClick={handleExport}
                        popoverPlacement="top-end"
                        size="medium"
                        title="Export rate card"
                        variant="ghost"
                      />
                      <IconButton
                        icon="zicon-trash"
                        onClick={() => handleDelete(rateCardId)}
                        popoverPlacement="top-end"
                        size="medium"
                        title="Delete rate card"
                        variant="ghost"
                      />
                    </div>
                    <Button iconLeft="zicon-edit" onClick={() => handleEdit(rateCardId)}>
                      Edit rate card
                    </Button>
                  </div>
                );
              }}
            </RateCardActions>
          );
        };

        return (
          <Page
            actionButtons={renderActionButtons()}
            defaultBackUrl={rateCardRoutes.index.getUrl()}
            title={name || 'Rate card details'}
          >
            <div className="flex flex-col space-y-6">
              <RateCardOverviewDetails rateCard={rateCard} />
              <div className="flex justify-start">
                <Filters rateCard={rateCard} />
              </div>
              <FreightChargeTable editable={false} freightCharges={freightCharges} />
              {shouldDisplayOriginHaulageCharges && (
                <HaulageTable
                  editable={false}
                  haulageCharges={mapHaulageCharges(originHaulageCharges)}
                  title="Origin haulage"
                  type="origin"
                />
              )}
              {shouldDisplayOriginCharges && (
                <PortChargeTable editable={false} portCharges={originCharges} title="Origin charges" type="origin" />
              )}
              {shouldDisplayDestinationCharges && (
                <PortChargeTable
                  editable={false}
                  portCharges={destinationCharges}
                  title="Destination charges"
                  type="destination"
                />
              )}
              {shouldDisplayDestinationHaulageCharges && (
                <HaulageTable
                  editable={false}
                  haulageCharges={mapHaulageCharges(destinationHaulageCharges)}
                  title="Destination haulage"
                  type="destination"
                />
              )}
              {note && <RateCardNotes editable={false} value={note} />}
              <TermsAndConditionsLink />
            </div>
          </Page>
        );
      }}
    </QueryHandler>
  );
};

export default RateCardDetails;
