import type { ReactNode } from 'react';

import type { TableColumn } from '@zen/DesignSystem';
import { IconButton } from '@zen/DesignSystem';

import type { ShipmentActionType } from './types';

export const getShipmentActionTypesColumnsConfiguration = (
  onEdit: (shipmentActionType: ShipmentActionType) => void
): TableColumn<ShipmentActionType>[] => {
  return [
    {
      key: 'name',
      title: 'Name'
    },
    {
      key: 'title',
      title: 'Title'
    },
    {
      key: 'description',
      title: 'Description'
    },
    {
      key: 'actions',
      fixed: 'right',
      render: (_, shipmentActionType: ShipmentActionType): ReactNode => (
        <IconButton icon="zicon-edit" onClick={() => onEdit(shipmentActionType)} size="medium" title="Edit" variant="ghost" />
      ),
      sortable: false
    }
  ];
};
