import type { FC } from 'react';

import { SkeletonLoading } from '@zen/DesignSystem';

interface Props {
  count?: number;
}
const RepeatedSkeletons: FC<Props> = ({ count }) => (
  <div data-testid="skeleton-activity">
    {[...Array(count)].map((_, i) => (
      <div key={i} className="skeleton-activity-element">
        <div>
          <SkeletonLoading height={20} width="w-60" />
          <SkeletonLoading className="my-2" height={14} width="w-20" />
        </div>
        <div className="flex justify-end">
          <SkeletonLoading height={20} width="w-60" />
        </div>
        <div className="flex justify-end">
          <SkeletonLoading className="my-2" height={14} width="w-20" />
        </div>
      </div>
    ))}
  </div>
);
const SkeletonActivityFeedLoading: FC<Props> = ({ count = 3 }) => {
  return <RepeatedSkeletons count={count} />;
};

export type { Props as SkeletonActivityFeedLoadingProps };

export default SkeletonActivityFeedLoading;
