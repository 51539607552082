import type { FC } from 'react';

import { SkeletonLoading } from '@zen/DesignSystem';

const CargoJourneysSummaryLoadingSkeleton: FC = () => {
  return (
    <div className="py-8" data-testid="cargo-journeys-summary-loading-skeleton">
      {[...Array(2)].map((_, i) => (
        <div key={i} className="mb-4 ml-52">
          <SkeletonLoading className="mb-1" height={16} width="w-64" />
          <SkeletonLoading height={16} width="w-64" />
        </div>
      ))}
    </div>
  );
};

export default CargoJourneysSummaryLoadingSkeleton;
