import type { FC } from 'react';

import type { FormInstance } from '@zen/Components';
import { Form, FormButtons, FormSelect } from '@zen/Components';
import type { ChargeType } from '@zen/CostTracking';
import { ChargeGroupItemType, FormChargeTypeSelect } from '@zen/CostTracking';
import { Button } from '@zen/DesignSystem';
import { preparePortOptions } from '@zen/RateCards/PortCharges/PortChargeForm';
import RateCardChargeFormItems from '@zen/RateCards/RateCardForm/components/RateCardChargeFormItems';
import type { RateCardCharge } from '@zen/RateCards/reducer';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import type { Nullable } from '@zen/utils/typescript';

import { initialValues, preparePayload } from './helpers';
import { validationSchema } from './otherCharge.validation';
import type { CostTrackingLocation, OtherChargeFormInitialValues, OtherChargeFormValues } from './types';
import { Applicability } from './types';

interface Props {
  onCancel: () => void;
  onSubmit: (values: RateCardCharge[]) => void;
  onSuccess: () => void;
  ports: CostTrackingLocation[];
}

const OtherChargeForm: FC<Props> = (props) => {
  const { onSubmit, onSuccess, onCancel, ports } = props;

  const handleSubmit = (values: OtherChargeFormValues): Promise<IOkOrErrorResult> => {
    const otherChargePayload = preparePayload(values, ports);

    onSubmit(otherChargePayload);

    return Promise.resolve({ ok: {}, error: null });
  };

  const renderFormButtons = ({ isSubmitting }: FormInstance<OtherChargeFormInitialValues>) => {
    return (
      <FormButtons isSubmitting={isSubmitting} layout="fixed">
        <Button onClick={onCancel} variant="ghost">
          Cancel
        </Button>
      </FormButtons>
    );
  };

  return (
    <Form
      formButtons={renderFormButtons}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onSuccess={onSuccess}
      validationSchema={validationSchema}
    >
      {({ values, setFieldValue, errors }: FormInstance<OtherChargeFormValues>) => {
        const { chargeItems } = values;

        const handleChargeTypeChange = (chargeValue: Nullable<ChargeType>) => {
          setFieldValue('chargeType', chargeValue);

          chargeItems.forEach((_, index: number) => {
            setFieldValue(`chargeItems.[${index}].chargeName`, chargeValue?.name);
          });
        };

        return (
          <>
            <div className="grid grid-cols-2 gap-x-4 pr-14">
              <FormChargeTypeSelect
                applicability={Applicability.BOOKING}
                label="Charge type"
                name="chargeType"
                onChange={handleChargeTypeChange}
                searchableChargeTypes={[ChargeGroupItemType.ADDITIONAL_CHARGES, ChargeGroupItemType.BOOKING_MANAGEMENT]}
              />
              <FormSelect
                className="flex-1"
                label="Port of destination"
                name="toLocationId"
                options={preparePortOptions(ports)}
              />
            </div>
            <RateCardChargeFormItems chargeItems={chargeItems} chargeType={values.chargeType} showChargeType={false} />
          </>
        );
      }}
    </Form>
  );
};

export type { Props as OtherChargeFormProps };

export default OtherChargeForm;
