import { isEmpty } from 'lodash';

import { cargoModeLabelMapping } from '@zen/Cargo';
import TableLink from '@zen/Components/TableLink';
import TransportModeIcon from '@zen/Components/TransportModeIcon';
import type { MenuItemType, TableColumn } from '@zen/DesignSystem';
import { ContextMenu } from '@zen/DesignSystem';
import { rateCardRoutes } from '@zen/Routes';
import { cargoModeLabel } from '@zen/types';
import { formatDate } from '@zen/utils/dateTime';

import type { RateCardsTableActions, RateCardSummary } from './types';

export const getRateCardsTableConfiguration = (actions: RateCardsTableActions = {}): TableColumn<RateCardSummary>[] => {
  return [
    {
      key: 'modeOfTransport',
      title: 'Mode',
      render: (_, item: RateCardSummary) => (
        <div className="text-center">
          <TransportModeIcon mode={item.modeOfTransport} />
        </div>
      )
    },
    {
      key: 'name',
      title: 'Rate card ID',
      render: (_, item: RateCardSummary) => (
        <TableLink linkTo={rateCardRoutes.details.getUrl(item.rateCardId)}>{item.name}</TableLink>
      ),
      width: '40%'
    },
    {
      key: 'cargoType',
      title: cargoModeLabel,
      sortable: false,
      render: (_, item: RateCardSummary) => cargoModeLabelMapping[item.cargoType]
    },
    {
      key: 'startDate',
      title: 'Start date',
      render: (_, item: RateCardSummary) => formatDate(item.startDate)
    },
    {
      key: 'endDate',
      title: 'Expiry date',
      render: (_, item: RateCardSummary) => formatDate(item.endDate)
    },
    ...(!isEmpty(actions)
      ? [
          {
            key: 'actions',
            fixed: 'right' as const,
            render: (_: unknown, item: RateCardSummary) => {
              const allActions: MenuItemType[] = [
                { label: 'Edit', icon: 'zicon-edit', onClick: () => actions?.onEdit?.(item.rateCardId) },
                { label: 'Clone', icon: 'zicon-copy', onClick: () => actions?.onClone?.(item.rateCardId), addDivider: true },
                {
                  label: 'Delete',
                  icon: 'zicon-trash',
                  onClick: () => actions?.onDelete?.(item.rateCardId)
                }
              ];

              return <ContextMenu inline={false} items={allActions} />;
            },
            sortable: false
          }
        ]
      : [])
  ];
};
