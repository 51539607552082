import cx from 'classnames';
import { type FC, type ReactNode, useState } from 'react';

import Button from '../Button';
import Icon from '../Icon';
import { Modal } from '../Modal';
import Tooltip from '../Tooltip';
import type { InformationIconProps, InformationIconWithClickProps, InformationIconWithHoverProps } from './types';

const InformationIcon: FC<InformationIconProps> = (props) => {
  const { className, content, size = 'normal', trigger = 'hover' } = props;
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const iconClassName: string = cx(size === 'normal' ? 'text-base' : 'text-sm', className);

  const handleClose = (): void => setIsModalVisible(false);

  const modalFooter: ReactNode = (
    <div className="flex justify-end">
      <Button onClick={handleClose}>Got it</Button>
    </div>
  );

  if (trigger === 'hover') {
    const { placement } = props as InformationIconWithHoverProps;

    return (
      <Tooltip placement={placement} tooltipContent={content}>
        <Icon className={iconClassName} icon="zicon-information" />
      </Tooltip>
    );
  }

  const { title } = props as InformationIconWithClickProps;

  return (
    <>
      <Icon className={iconClassName} icon="zicon-information" onClick={() => setIsModalVisible(true)} />
      <Modal footer={modalFooter} isOpen={isModalVisible} onClose={handleClose} title={title}>
        {content}
      </Modal>
    </>
  );
};

export type { InformationIconProps };

export default InformationIcon;
