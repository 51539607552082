import type { ReactNode } from 'react';

import type { TableColumn } from '@zen/DesignSystem';
import { IconButton } from '@zen/DesignSystem';

import type { NetworkContact, NetworkContactPerson } from '../types';

export const getColumnsConfiguration = (onEdit: (contactId: string) => void): TableColumn<NetworkContact>[] => {
  return [
    {
      key: 'name',
      title: 'Name',
      render: (_, { name }): ReactNode => name
    },
    {
      key: 'customerInfo',
      title: 'Customer info',
      sortable: false,
      render: (_, { customer, supplier, warehouse, carrier, haulier }): ReactNode => (
        <div>
          <div>{customer && 'Customer'}</div>
          <div>{supplier && 'Supplier'}</div>
          <div>{warehouse && 'Warehouse'}</div>
          <div>{carrier && 'Carrier'}</div>
          <div>{haulier && 'Haulier'}</div>
        </div>
      )
    },
    {
      key: 'status',
      title: 'Status'
    },
    {
      title: 'Main contact',
      sortable: false,
      key: 'contactPeople',
      render: (_, { contactPeople }): ReactNode => {
        const mainContactPeopleList: NetworkContactPerson[] =
          contactPeople?.filter((contactPerson: NetworkContactPerson) => contactPerson?.mainContact) || [];

        const renderMainContactPeopleList = (contactList: NetworkContactPerson[]): ReactNode => {
          return contactList.map(
            (contact: NetworkContactPerson): ReactNode => (
              <div key={contact.id} className="flex flex-col">
                <div>
                  {contact.firstName} {contact.lastName}
                </div>
                <div className="text-grey-base">{contact.email}</div>
              </div>
            )
          );
        };

        return <>{renderMainContactPeopleList(mainContactPeopleList)}</>;
      }
    },
    {
      fixed: 'right',
      sortable: false,
      key: 'actions',
      render: (_, { id }): ReactNode => (
        <IconButton icon="zicon-edit" onClick={() => onEdit(id)} size="medium" title="Edit" variant="ghost" />
      )
    }
  ];
};
