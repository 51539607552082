import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';

import type * as GraphQLTypes from '../../../graphql/types.generated';

const defaultOptions = {} as const;

export type ParseDocumentMutationVariables = GraphQLTypes.Exact<{
  input: GraphQLTypes.ParseDocumentInput;
}>;

export type ParseDocumentMutationResult = { __typename?: 'Mutation' } & Pick<GraphQLTypes.Mutation, 'parseDocument'>;

export const ParseDocumentDocument = /* #__PURE__ */ gql`
  mutation parseDocument($input: ParseDocumentInput!) {
    parseDocument(input: $input)
  }
`;

/**
 * __useParseDocumentMutation__
 *
 * To run a mutation, you first call `useParseDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useParseDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [parseDocumentMutation, { data, loading, error }] = useParseDocumentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useParseDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<ParseDocumentMutationResult, ParseDocumentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };

  return Apollo.useMutation<ParseDocumentMutationResult, ParseDocumentMutationVariables>(ParseDocumentDocument, options);
}
export type ParseDocumentMutationHookResult = ReturnType<typeof useParseDocumentMutation>;
