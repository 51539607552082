import type { ChipOption } from '@zen/Components/ChipSelect';
import { QuoteOptionRejectionReason } from '@zen/Quotes';
import { removeSpecialCharactersAndUpperFirst } from '@zen/utils/formatting';
import type { Optional } from '@zen/utils/typescript';

export const reasons = [
  QuoteOptionRejectionReason.PRICE,
  QuoteOptionRejectionReason.SCHEDULE,
  QuoteOptionRejectionReason.RESPONSE_TIME,
  QuoteOptionRejectionReason.OTHER
];

export const isOtherReason = (reason: Optional<QuoteOptionRejectionReason>): boolean => {
  return reason === QuoteOptionRejectionReason.OTHER;
};

export const getOptions = (isDisabled: boolean): ChipOption<QuoteOptionRejectionReason>[] => {
  return reasons.map((reason) => ({
    label: removeSpecialCharactersAndUpperFirst(reason),
    value: reason,
    isDisabled
  }));
};
