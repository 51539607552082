import api from './api';

type Response = { assetUrl: string };

export const downloadS3File = async (url: string = '') => {
  const assetUrl = await getSignedAssetUrl(url);

  window.open(assetUrl, '_blank');
};

export const getSignedAssetUrl = async (url: string): Promise<string> => {
  const response = await api.get<Response>(`${url}?disposition=attachment`);

  return response?.data?.assetUrl;
};
