import type { FC } from 'react';

import { useCargoOverviewContext } from '@zen/Booking/BookingDetails';
import InlineEditableField from '@zen/Components/InlineEditableField';
import LabelledValue from '@zen/Components/LabelledValue';
import ValueWithDefault from '@zen/Components/ValueWithDefault/ValueWithDefault';
import { useNotification } from '@zen/utils/hooks/useNotification';
import type { IOkOrErrorResult } from '@zen/utils/OkOrErrorResult';
import { performMutation } from '@zen/utils/performMutation';

import { useUpdateMasterAirwayBillMutation } from './graphql';

interface Props {
  canUpdateMasterAirwayBill: boolean;
  masterAirwayBill: string;
}

const MasterAirwayBillInlineField: FC<Props> = ({ masterAirwayBill, canUpdateMasterAirwayBill }) => {
  const { addSuccess, addError } = useNotification();

  const { zencargoReference } = useCargoOverviewContext();

  const [updateMasterAirwayBill] = useUpdateMasterAirwayBillMutation();

  const label: string = 'Master airway bill';

  const handleChange = (value: string): Promise<IOkOrErrorResult> => {
    return performMutation({
      mutationFn: () =>
        updateMasterAirwayBill({
          variables: {
            input: {
              masterAirwayBill: value,
              zencargoReference
            }
          }
        }),
      onError: () => addError(),
      onSuccess: () => {
        addSuccess(`${label} updated.`);
      }
    });
  };

  if (!canUpdateMasterAirwayBill) {
    return (
      <LabelledValue label={label} labelPosition="left" variant="default">
        <ValueWithDefault value={masterAirwayBill} />
      </LabelledValue>
    );
  }

  return (
    <InlineEditableField
      label={label}
      labelPlacement="left"
      name="master-airway-bill"
      onUpdate={handleChange}
      placeholder="Enter master airway bill"
      value={masterAirwayBill}
    />
  );
};

export default MasterAirwayBillInlineField;
