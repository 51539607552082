import { uniqueId } from 'lodash';

import type { OceanCharge } from '@zen/OperationsSettings';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import type { ChargeLocationType, LocationType } from '../../components/types';
import { getChargeItemApplicability } from '../PortChargeForm/helpers';
import type { FormattedPortCharge } from './types';

export const preparePortChargePayload = (portCharges: OceanCharge[], type: LocationType): RateCardCharge[] => {
  const locationKey: ChargeLocationType = type === 'origin' ? 'fromLocation' : 'toLocation';

  return portCharges.map((portCharge: OceanCharge) => {
    const { cargoOptions, chargeBasis, chargeType, currency, itemName, location } = portCharge;

    return {
      applicability: [getChargeItemApplicability(type)],
      chargeBasis,
      cargoOptions,
      chargeType,
      currency,
      defaultCharge: portCharge,
      defaultChargeHidden: false,
      id: uniqueId(),
      itemName,
      unitPrice: null,
      [locationKey]: location
    };
  });
};

export const formatPortCharges = (portCharges: RateCardCharge[]): FormattedPortCharge[] => {
  return portCharges.map((portCharge: RateCardCharge) => ({
    ...portCharge,
    disabled: !!portCharge.defaultChargeHidden
  }));
};
